import {
	DeleteDialogProps,
	EditDialogProps,
	CreateDialogProps,
} from "@recodin/fe-components";
import ReceptionPointEditForm from "./ReceptionPointEditForm";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";

export const editDialogProps = (
	handleCreateUpdate: (receptionPoint: ReceptionPoint) => void,
	row?: ReceptionPoint
): EditDialogProps => {
	return {
		headerText: row
			? `"${row.name}" redagavimas`
			: "Naujo priėmimo taškas sukūrimas",
		contentTextFor: "priėmimo tašką",
		EditForm: (
			<ReceptionPointEditForm
				receptionPoint={row}
				handleEdit={handleCreateUpdate}
			/>
		),
	};
};

export const createProps = (
	handleCreateUpdate: (receptionPoint: ReceptionPoint) => void,
	row?: ReceptionPoint
): CreateDialogProps => ({
	...editDialogProps(handleCreateUpdate, row),
	createButtonLabel: "Naujas priėmimo taškas",
});

export const deleteDialogProps = (row: ReceptionPoint): DeleteDialogProps => ({
	labels: {
		title: "Priėmimo taško ištrynimas",
		question: `Ar tikrai norite ištrinti "${row.name}" priėmimo tašką?`,
		dialogBoldedNameToDelete: `"${row.name}" priėmimo taškas`,
		dialogText: " bus visam laikui ištrintas. Jį atkurti galimybių nebus!",
	},
});
