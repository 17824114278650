import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { setUserInfo } from "./UserSlice";
import MeResponse from "_model/account/MeResponse";
import { AccountAPIAdmin } from "_api/AccountAPI";

const getAndSetUser =
	(): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch) => {
		AccountAPIAdmin.getMe().then((me: MeResponse) => {
			dispatch(setUserInfo({ info: me }));
		});
	};

export { getAndSetUser };
