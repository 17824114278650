enum ReportOptions {
	PAST_YEAR = "PAST_YEAR",
	THIS_YEAR = "THIS_YEAR",
	PAST_MONTH = "PAST_MONTH",
	THIS_MONTH = "THIS_MONTH",
	RANGE = "RANGE",
}

const allReportOptions = Object.keys(ReportOptions).filter((k) =>
	isNaN(Number(k))
);
function translateOption(option: string) {
	switch (option) {
		case ReportOptions.PAST_YEAR:
			return "Pastarieji metai";
		case ReportOptions.THIS_YEAR:
			return "Šie metai";
		case ReportOptions.PAST_MONTH:
			return "Pastarasis mėnuo";
		case ReportOptions.THIS_MONTH:
			return "Šitas mėnuo";
		case ReportOptions.RANGE:
			return "Pasirinkti";
		default:
			return "N/A";
	}
}

export default ReportOptions;
export { allReportOptions, translateOption };
