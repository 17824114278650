import { useEffect, useState } from "react";
import { DepartmentAPIAdmin } from "_api/DepartmentAPI";
import { CustomTable } from "@recodin/fe-components";
import {
	DepartmentTableHeadRow,
	DepartmentTableRow,
} from "./DepartmentTableRow";
import { createProps } from "./DepartmentDialogProps";
import Department from "_model/department/Department";

export default function DepartmentPage() {
	const [departments, setDepartments] = useState<Department[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		handleDataFetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleDataFetch() {
		setLoading(true);
		setDepartments(await DepartmentAPIAdmin.getAll());
		setLoading(false);
	}
	async function handleCreateUpdate(data: Department) {
		if (data?.id) {
			await DepartmentAPIAdmin.update(data);
		} else {
			await DepartmentAPIAdmin.create(data);
		}
		await handleDataFetch();
	}
	async function handleDelete(data: Department) {
		if (data?.id) {
			await DepartmentAPIAdmin.delete(data?.id);
			await handleDataFetch();
		}
	}
	return (
		<div>
			<CustomTable
				data={departments}
				isLoading={loading}
				headerRow={DepartmentTableHeadRow}
				contentRow={(props: { row: Department }) => (
					<DepartmentTableRow
						row={props.row}
						handleDelete={handleDelete}
						handleCreateUpdate={handleCreateUpdate}
					/>
				)}
				createProps={createProps(handleCreateUpdate)}
				disableSearching
				disableFiltering
			/>
		</div>
	);
}
