import { useEffect, useState } from "react";
import { TopicTableHeadRow, TopicTableRow } from "./TopicTableRow";
import { createProps } from "./TopicDialogProps";
import Topic from "_model/topic/Topic";
import { TopicAPIAdmin } from "_api/TopicAPI";
import { CustomTable } from "@recodin/fe-components";

export default function TopicPage() {
	const [topics, setTopics] = useState<Topic[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		handleDataFetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleDataFetch() {
		setLoading(true);
		setTopics(await TopicAPIAdmin.getAll());
		setLoading(false);
	}
	async function handleCreateUpdate(data: Topic) {
		if (data?.id) {
			await TopicAPIAdmin.update(data);
		} else {
			await TopicAPIAdmin.create(data);
		}
		await handleDataFetch();
	}
	async function handleDelete(data: Topic) {
		if (data?.id) {
			await TopicAPIAdmin.delete(data?.id);
			await handleDataFetch();
		}
	}
	return (
		<div>
			<CustomTable
				data={topics}
				isLoading={loading}
				headerRow={TopicTableHeadRow}
				contentRow={(props: { row: Topic }) => (
					<TopicTableRow
						row={props.row}
						handleDelete={handleDelete}
						handleCreateUpdate={handleCreateUpdate}
					/>
				)}
				createProps={createProps(handleCreateUpdate)}
				disableSearching
				disableFiltering
			/>
		</div>
	);
}
