import HomeIcon from "@mui/icons-material/Home";
import PathGroup from "_model/route/PathGroup";
import Registration from "_page/registration/Registration";
import EmailTemplatePage from "_page/emailtemplate/EmailTemplatePage";
import EmailPage from "_page/email/EmailPage";
import TopicPage from "_page/topic/TopicPage";
import DepartmentPage from "_page/department/DepartmentPage";
import AccountListPage from "_page/account/AccountListPage";
import AccountPage from "_page/account/AccountPage";
import ReceptionPointPage from "_page/receptionpoint/ReceptionPointPage";
import AdministrationLogin from "_page/login/AdministrationLogin";
import RegistrationsPage from "_page/registrations/RegistrationsPage";
import { commonConstants } from "./commonConstants";
import RegistrationEditPage from "_page/registrations/RegistrationEditPage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import EmailIcon from "@mui/icons-material/Email";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import AdminLayout from "_page/main/AdminLayout";
import PublicLayout from "_page/main/PublicLayout";
import PrivacyAndDataHandlingPage from "_page/legal/PrivacyAndDataHandlingPage";
import RegistrationSuccessful from "_page/registration/RegistrationSuccessful";
import SettingsPage from "_page/settings/SettingsPage";
import ReportPage from "_page/report/ReportPage";

const routes: PathGroup[] = [
	{
		label: "Kiti",
		layout: <PublicLayout />,
		paths: [
			{
				path: "/",
				page: <Registration />,
				menuLabel: "Registracija",
				menuIcon: <HomeIcon />,
				forPublic: true,
			},
			{
				path: "/:maybePhase",
				page: <Registration />,
				forPublic: true,
			},
			{
				path: "/registracija/:registrationUUID",
				page: <RegistrationSuccessful />,
				forPublic: true,
			},
			{
				path: "/login",
				page: <AdministrationLogin />,
				forPublic: true,
			},
			{
				path: "/asmens-duomenu-tvarkymas",
				page: <PrivacyAndDataHandlingPage />,
				forPublic: true,
			},
		],
	},
	{
		label: "Administracija",
		layout: <AdminLayout />,
		paths: [
			{
				path: "/paskyros/" + commonConstants.myProfilePathNameEnd,
				page: <AccountPage />,
				title: "Paskyros redagavimas",
				forAdmin: true,
				forManager: true,
				forSpecialist: true,
				forObserver: true,

				menuLabel: "Mano paskyra",
				menuIcon: <AccountCircleIcon />,
				isLink: true,
			},
			{
				path: "/nustatymai",
				page: <SettingsPage />,
				title: "Nustatymai",
				forAdmin: true,

				menuLabel: "Nustatymai",
				menuIcon: <SettingsIcon />,
			},
			{
				path: "/laisku-sablonai",
				page: <EmailTemplatePage />,
				title: "Laiškų šablonai",
				forAdmin: true,

				menuLabel: "Laiškų šablonai",
				menuIcon: <MailOutlineIcon />,
			},
			{
				path: "/laiskai",
				page: <EmailPage />,
				title: "Laiškai",
				forAdmin: true,

				menuLabel: "Laiškai",
				menuIcon: <EmailIcon />,
			},
			{
				path: "/konsultavimo-temos",
				page: <TopicPage />,
				title: "Konsultavimo temos",
				forAdmin: true,

				menuLabel: "Konsultavimo temos",
				menuIcon: <QuestionAnswerIcon />,
			},
			{
				path: "/skyriai",
				page: <DepartmentPage />,
				title: "Skyriai",
				forAdmin: true,

				menuLabel: "Skyriai",
				menuIcon: <MenuBookIcon />,
			},
			{
				path: "/paskyros",
				page: <AccountListPage />,
				title: "Paskyros",
				forAdmin: true,
				forManager: true,

				menuLabel: "Paskyros",
				menuIcon: <PeopleIcon />,
			},
			{
				path: "/paskyros/:maybeId",
				page: <AccountPage />,
				title: "Paskyros redagavimas",

				forAdmin: true,
				forManager: true,
				forSpecialist: true,
				forObserver: true,
			},
			{
				path: "/priemimo-taskas",
				page: <ReceptionPointPage />,
				title: "Priėmimo taškai",
				forAdmin: true,

				menuLabel: "Priėmimo taškai",
				menuIcon: <LocationOnIcon />,
			},
			{
				path: "/registracijos",
				page: <RegistrationsPage />,
				title: "Registracijos",
				forAdmin: true,
				forManager: true,
				forSpecialist: true,
				forObserver: true,

				menuLabel: "Registracijos",
				menuIcon: <HowToRegIcon />,
			},
			{
				path: "/registracijos/:maybeId",
				page: <RegistrationEditPage />,
				title: "Registracijos redagavimas",

				forAdmin: true,
				forManager: true,
				forSpecialist: true,
				forObserver: true,
			},
			{
				path: "/ataskaitos",
				page: <ReportPage />,
				title: "Ataskaitos",
				forAdmin: true,

				menuLabel: "Ataskaitos",
				menuIcon: <DescriptionIcon />,
			},
		],
	},
];

export default routes;
