import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import RegistrationRequest from "_model/registration/RegistrationRequest";
import { FilterRequest } from "_component/form/templates/FilterForm";
import { getDateTime, getDate } from "_util/DateUtil";
import { RegistrationFreeTimesResponse } from "_model/account/WorkTime";
import CommentRequest from "_model/registration/CommentRequest";
import CommentResponse from "_model/registration/CommentResponse";
import moment from "moment";
import { SearchRequest } from "_component/form/templates/SearchForm";
import RegistrationReport from "../_model/report/RegistrationReport";

const adminRefUrl = baseEndpoints.registrationAdmin;
export const RegistrationAPIAdmin = {
	getAllFiltered: (filter?: FilterRequest): Promise<RegistrationResponse[]> =>
		axios.get(`${adminRefUrl}/filtered`, {
			params: {
				from: filter?.from ? getDateTime(filter?.from) : null,
				to: filter?.to ? getDateTime(filter?.to) : null,
				specialistId: null,
				topicId: filter?.topicId ?? null,
				receptionPointId: null,
				status: filter?.status ?? null,
			},
		}),
	getById: (id: number): Promise<RegistrationResponse> =>
		axios.get(`${adminRefUrl}/${id}`),
	update: (data: RegistrationRequest): Promise<RegistrationResponse> =>
		axios.put(`${adminRefUrl}/${data.id}`, data),
	delete: (id: number) => axios.delete(`${adminRefUrl}/${id}`),

	createComment: (data: CommentRequest): Promise<CommentResponse> =>
		axios.post(`${adminRefUrl}/comment`, data),
	updateComment: (data: CommentRequest): Promise<CommentResponse> =>
		axios.put(`${adminRefUrl}/comment/${data.id}`, data),
	deleteComment: (id: number, registrationId: number) =>
		axios.delete(`${adminRefUrl}/comment/${registrationId}/${id}`),
	generateReport: (
		search?: SearchRequest,
		filter?: FilterRequest
	): Promise<Blob> =>
		axios.get(`${adminRefUrl}/generate/csv`, {
			params: {
				from: filter?.from ? getDateTime(filter?.from) : null,
				to: filter?.to ? getDateTime(filter?.to) : null,
				specialistId: null,
				topicId: filter?.topicId ?? null,
				receptionPointId: null,
				status: filter?.status ?? null,
			},
			responseType: "blob",
		}),
	getReports: (
		from: moment.Moment,
		to: moment.Moment
	): Promise<RegistrationReport[]> =>
		axios.get(`${adminRefUrl}/analysis`, {
			params: {
				from: getDate(from),
				to: getDate(to),
			},
		}),
	generateXlsxReport: (from: moment.Moment, to: moment.Moment): Promise<Blob> =>
		axios.get(`${adminRefUrl}/generate/xlsx`, {
			params: {
				from: getDate(from),
				to: getDate(to),
			},
			responseType: "blob",
		}),
};

const publicUrl = baseEndpoints.registrationPublic;
export const RegistrationAPIPublic = {
	getByUUID: (uuid: string): Promise<RegistrationResponse> =>
		axios.get(`${publicUrl}/${uuid}`),
	getWorkTimes: (
		date: moment.Moment,
		topicId?: number
	): Promise<RegistrationFreeTimesResponse[]> =>
		axios.get(`${publicUrl}/work-time`, {
			params: {
				fromDate: getDateTime(date, true),
				topicId: topicId,
			},
		}),
	create: (data: RegistrationRequest): Promise<RegistrationResponse> =>
		axios.post(publicUrl, data),
	cancel: (uuid: string) => axios.put(`${publicUrl}/cancel/${uuid}`),
};
