import "./FormTemplates.css";
import { Typography, useTheme } from "@mui/material";
import CustomTextField from "../components/CustomTextField";
import CommentResponse from "_model/registration/CommentResponse";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import CustomIconButton from "../../buttons/CustomIconButton";
import SendIcon from "@mui/icons-material/Send";
import { useAppSelector } from "_redux/hooks";
import { RegistrationAPIAdmin } from "_api/RegistrationAPI";
import CommentRequest from "_model/registration/CommentRequest";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import EditMenu from "../../dashboard/templates/EditMenu";
import CancelIcon from "@mui/icons-material/Cancel";
import { getTrimmedText } from "_util/UtilFunctions";
import { translateRoleByName } from "_model/account/RoleResponse";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

interface Props {
	registration?: RegistrationResponse;
	refresh: () => void;
	disabled?: boolean;
}

export default function CommentEditSection(props: Props) {
	const { registration, refresh, disabled } = props;

	const theme = useTheme();

	const me = useAppSelector((state) => state.me);
	const [current, setCurrent] = useState<CommentRequest>();
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref?.current) {
			// Scroll comment section to bottom
			ref.current.scrollTop = ref.current.scrollHeight;
		}
	}, [registration]);

	function getCommentTitle(
		comment: CommentResponse,
		fullName?: boolean
	): string {
		const owner =
			comment?.owner?.id !== me?.info?.id || fullName
				? `${comment?.owner.name} ${comment?.owner.lastName}`
				: "Jūs";
		const date = moment(comment.writtenDate).format("YYYY-MM-DD, HH:mm");
		return (
			owner +
			` (${date}, ${comment?.owner?.roles
				.map((role) => translateRoleByName(role.name))
				?.join(", ")})`
		);
	}

	async function handleCreateUpdate(values: any) {
		const request: CommentRequest = {
			id: current?.id ?? null,
			writtenDate: current?.writtenDate ?? null,
			text: values?.comment,
			ownerId: current?.id ?? me?.info?.id ?? null,
			registrationId: registration?.id ?? -1,
		};

		if (request?.id) {
			await RegistrationAPIAdmin.updateComment(request);
		} else {
			await RegistrationAPIAdmin.createComment(request);
		}
		refresh();
	}

	async function handleDelete(id: number, registrationId: number) {
		await RegistrationAPIAdmin.deleteComment(id, registrationId);
		refresh();
	}

	async function handleEnterOrButtonClick(formik: FormikProps<any>) {
		await formik.submitForm();
		setCurrent(undefined);
		formik.setFieldValue("comment", "");
	}

	return (
		<div className={"commentSection-container"}>
			<div className={"commentSection-display-container"}>
				<div className={"commentSection-display-comments-container"} ref={ref}>
					<div className={"commentSection-display-comments"}>
						{getComments(registration).map((comment) => (
							<div
								key={comment.id}
								className={"commentSection-individualComment-container"}
								style={{
									justifyContent:
										comment?.owner?.id === me?.info?.id
											? "flex-end"
											: "flex-start",
								}}
							>
								<div
									className={"commentSection-individualComment-text"}
									style={{
										backgroundColor:
											current?.id && current.id === comment?.id
												? "#828293"
												: theme.palette.primary.light,
									}}
								>
									<div className={"commentSection-individualComment-text-row"}>
										<Typography variant={"h4"}>
											{getCommentTitle(comment)}
										</Typography>
										<EditMenu
											disabled={disabled || comment.id === -1} // Disable customer comment edit
											titleIcon={<MoreHorizIcon />}
											handleEdit={() => {
												setCurrent(toRequest(comment, registration?.id));
											}}
											handleDelete={() =>
												handleDelete(comment.id, registration?.id ?? -1)
											}
											deleteDialogProps={{
												labels: {
													title: "Komentaro ištrynimas",
													question: `Ar tikrai norite ištrinti komentarą "${getTrimmedText(
														comment.text,
														50
													)}"?`,
													dialogBoldedNameToDelete: `${getCommentTitle(
														comment,
														true
													)} komentaras "${getTrimmedText(comment.text, 50)}"`,
													dialogText:
														" bus visam laikui ištrintas. Jį atkurti galimybių nebus!",
												},
											}}
										/>
									</div>
									<Typography variant={"h4"} fontWeight={200}>
										{comment.text}
									</Typography>
								</div>
							</div>
						))}
						{getComments(registration).length === 0 && (
							<Typography variant={"h4"} fontWeight={200}>
								Komentarų nėra
							</Typography>
						)}
					</div>
				</div>

				<Formik
					initialValues={{ comment: current?.text ?? "" }}
					validationSchema={yup.object({
						comment: yup.string().min(1, "Turi būti bent vienas simbolis"),
					})}
					onSubmit={(values: any) => handleCreateUpdate(values)}
					enableReinitialize
				>
					{(formik: FormikProps<any>) => (
						<>
							<CustomTextField
								name={"comment"}
								placeholder={"- Rašyti naują komentarą -"}
								enableEnterSubmit
								enterSubmitAction={() => handleEnterOrButtonClick(formik)}
								startIcon={
									current ? (
										<CustomIconButton
											onClick={() => setCurrent(undefined)}
											icon={<CancelIcon />}
											disabled={disabled}
										/>
									) : undefined
								}
								endIcon={
									<CustomIconButton
										onClick={() => handleEnterOrButtonClick(formik)}
										icon={<SendIcon />}
										disabled={disabled}
									/>
								}
								disabled={disabled}
							/>
						</>
					)}
				</Formik>
			</div>
		</div>
	);
}

function toRequest(
	comment: CommentResponse,
	registrationId?: number
): CommentRequest {
	return {
		id: comment?.id ?? null,
		writtenDate: comment?.writtenDate,
		text: comment?.text,
		ownerId: comment?.owner?.id ?? null,
		registrationId: registrationId ?? -1,
	};
}

function getComments(registration?: RegistrationResponse): CommentResponse[] {
	const comments = [];
	if (registration?.customerComment) {
		comments.push(getCustomerComment(registration));
	}
	if (registration?.comments) {
		comments.push(...registration.comments);
	}
	return comments;
}
function getCustomerComment(
	registration: RegistrationResponse
): CommentResponse {
	// -1 is used for object, which is created in FE
	return {
		id: -1,
		writtenDate: moment(registration.creationTime).format(
			"yyyy-MM-DD HH:mm:ss"
		),
		text: registration.customerComment,
		owner: {
			id: -1,
			name: registration.customerName,
			lastName: registration.customerLastName,
			roles: [
				{
					id: -1,
					name: "Klientas",
				},
			],
		},
	};
}
