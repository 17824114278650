import moment from "moment";

const getDateTime = (
	maybeDate?: moment.Moment,
	firstDay?: boolean
): string | undefined => {
	if (!maybeDate) return undefined;

	const dateToFormat = !firstDay
		? moment(maybeDate)
		: moment(maybeDate).startOf("month");
	return dateToFormat.format("YYYY-MM-DDTHH:mm:ss");
};

const getDate = (date: moment.Moment): string =>
	moment(date).format("YYYY-MM-DD");

const getTime = (date: moment.Moment, hideSeconds?: boolean): string =>
	moment(date).format(hideSeconds ? "HH:mm" : "HH:mm:ss");

export { getDate, getTime, getDateTime };
