import "./../Dialog.css";
import CloseIcon from "@mui/icons-material/Close";
import {
	Alert,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	PaperProps,
	Typography,
	useTheme,
} from "@mui/material";
import CustomButton from "_component/buttons/CustomButton";

interface CustomDialogProps {
	open: boolean;
	handleDialogClose: () => void;
	handleSubmitClick?: () => void;
	disabled?: boolean;
	disableContentScroll?: boolean;
	hideSubmit?: boolean;
	labels?: {
		header?: string;
		content?: string;
		errorMessage?: string;
		cancel?: string;
		submit?: string;
	};
	backgroundColors?: {
		submit?: string;
	};
	icons?: {
		LeftSubmitIcon?: JSX.Element;
	};
	paperProps?: PaperProps;
	children: any;
}

export default function CustomDialog(props: CustomDialogProps) {
	const {
		open = true,
		handleDialogClose,
		handleSubmitClick,
		labels,
		backgroundColors,
		icons,
		disabled,
		disableContentScroll,
		hideSubmit,
		paperProps,
		children,
	} = props;

	const theme = useTheme();

	const handleSubmit = () => {
		if (handleSubmitClick) {
			handleSubmitClick();
			handleDialogClose();
		}
	};

	if (!open) {
		return null;
	}
	return (
		<Dialog
			open={open}
			onClick={(e) => e.stopPropagation()}
			scroll={paperProps ? "paper" : undefined}
			PaperProps={{
				style: {
					width: "100%",
					height: "fit-content",
					maxWidth: "800px",
				},
				...paperProps,
			}}
		>
			<DialogTitle
				style={{
					display: "flex",
					width: "100%",
					padding: "0px",
					color: "#202020",
					letterSpacing: "0.15px",
					backgroundColor: theme.palette.primary.light,
				}}
				variant="h2"
				fontWeight="bold"
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						padding: "16px 24px",
						width: "100%",
						backgroundColor: theme.palette.primary.light,
					}}
				>
					{labels?.header}
					<IconButton
						style={{ width: "30px", height: "30px" }}
						onClick={handleDialogClose}
					>
						<CloseIcon />
					</IconButton>
				</div>
			</DialogTitle>

			{labels?.content && (
				<DialogContentText
					variant={"h3"}
					fontWeight={400}
					style={{
						padding: "0px 24px 12px 24px",
						backgroundColor: theme.palette.primary.light,
					}}
				>
					{labels?.content}
				</DialogContentText>
			)}
			<Divider
				variant="inset"
				style={{
					margin: "0px",
				}}
			/>
			<DialogContent
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "15px",
					overflowY: !disableContentScroll ? "scroll" : undefined,
				}}
			>
				{children}
			</DialogContent>
			<div className={"dialog-footer"}>
				{labels?.errorMessage && (
					<Alert
						key={labels.errorMessage}
						severity="error"
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{labels.errorMessage}
					</Alert>
				)}
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						gap: "8px",
					}}
				>
					<CustomButton
						className={"cancel-button"}
						variant={"contained"}
						color={"info"}
						onClick={() => handleDialogClose()}
					>
						<Typography variant={"h3"} color={"white"}>
							{labels?.cancel ?? "Atšaukti"}
						</Typography>
					</CustomButton>
					{!hideSubmit && (
						<CustomButton
							variant={"contained"}
							className={"submit-button"}
							onClick={handleSubmit}
							type={"submit"}
							style={{
								backgroundColor: backgroundColors?.submit,
							}}
							disabled={disabled}
						>
							{icons?.LeftSubmitIcon && icons.LeftSubmitIcon}
							<Typography variant={"h3"} color={"white"}>
								{labels?.submit ?? "Patvirtinti"}
							</Typography>
						</CustomButton>
					)}
				</div>
			</div>
		</Dialog>
	);
}
