import EmailTemplate from "_model/emailtemplate/EmailTemplate";

import {
	DeleteDialogProps,
	EditDialogProps,
	CreateDialogProps,
} from "@recodin/fe-components";
import EmailTemplateEditForm from "./EmailTemplateEditForm";

export const editDialogProps = (
	handleCreateUpdate: (emailTemplate: EmailTemplate) => void,
	row?: EmailTemplate
): EditDialogProps => {
	return {
		headerText: row
			? `"${row.subject}" redagavimas`
			: "Naujo laiško šablono sukūrimas",
		contentTextFor: "laiško šabloną",
		EditForm: (
			<EmailTemplateEditForm
				emailTemplate={row}
				handleEdit={handleCreateUpdate}
			/>
		),
	};
};

export const createProps = (
	handleCreateUpdate: (emailTemplate: EmailTemplate) => void,
	row?: EmailTemplate
): CreateDialogProps => ({
	...editDialogProps(handleCreateUpdate, row),
	createButtonLabel: "Naujas laiškų šablonas",
});

export const deleteDialogProps = (row: EmailTemplate): DeleteDialogProps => ({
	labels: {
		title: "Laiškų šablono ištrynimas",
		question: `Ar tikrai norite ištrinti laiškų šabloną temai "${row?.subject}"?`,
		dialogBoldedNameToDelete: `Laiškų šablonas temai "${row?.subject}"`,
		dialogText: " bus visam laikui ištrintas. Jį atkurti galimybių nebus!",
	},
});
