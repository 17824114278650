import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
	ActionsCell,
	CustomTable,
} from "@recodin/fe-components";
import { workTimeDeleteDialogProps } from "../forms/SpecialistTimeDialogProps";
import { getDate, getTime } from "_util/DateUtil";
import moment from "moment";
import WorkDay from "_model/account/WorkDay";
import { WorkTime } from "_model/account/WorkTime";

export function WorkDayTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: " ",
					width: "5%",
				},
				{
					label: "Diena",
					width: "60%",
				},
				{
					label: "Laisvi konsultacijos laikai",
					width: "35%",
				},
			]}
		/>
	);
}

interface WorkDayProps {
	row: WorkDay;
	handleDelete: (id?: number) => void;
}

export function WorkDayTableRow({ row, handleDelete }: WorkDayProps) {
	return (
		<CustomTableRow
			showButtonOnLeftSide
			collapsableItems={
				<>
					<CustomTable
						data={row.workTimes}
						isLoading={false}
						headerRow={WorkTimeTableHeadRow}
						contentRow={({ row }: { row: WorkTime }) => (
							<WorkTimeTableRow row={row} handleDelete={handleDelete} />
						)}
						disableFiltering
						disableSearching
					/>
				</>
			}
		>
			<TextCell width={"60%"} label={`${getDate(moment(row.day))}`} />
			<TextCell
				width={"35%"}
				label={`${row.workTimes.filter((t) => !t.used).length} iš ${
					row.workTimes.length
				}`}
			/>
		</CustomTableRow>
	);
}

export function WorkTimeTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: "Laikas",
					width: "65%",
				},
				{
					label: "Ar užimtas?",
					width: "15%",
				},
				{
					label: "Veiksmai",
					width: "20%",
				},
			]}
		/>
	);
}

interface WorkTimeProps {
	row: WorkTime;
	handleDelete: (id?: number) => void;
}
export function WorkTimeTableRow({ row, handleDelete }: WorkTimeProps) {
	return (
		<CustomTableRow>
			<TextCell
				width={"65%"}
				label={`${getTime(moment(row.start), true)} - ${getTime(
					moment(row.end),
					true
				)}`}
			/>
			<TextCell width={"15%"} label={row.used ? "Užimtas" : "Laisvas"} />
			<ActionsCell
				width={"20%"}
				deleteDialogProps={workTimeDeleteDialogProps(row)}
				handleDelete={() => handleDelete(row?.id)}
				disableDelete={row?.used}
			/>
		</CustomTableRow>
	);
}
