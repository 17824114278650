import "./CustomSideMenu.css";
import { Box, Drawer } from "@mui/material";
import { useAppSelector } from "_redux/hooks";
import { SideMenuItems } from "./SideMenuItems";
import ReduxCommonApi from "_redux/common/ReduxCommonApi";
import routes from "_constant/routes";

export default function CustomSideMenu() {
	const common = useAppSelector((state) => state.common);

	if (common?.sideMenuIsHidden) {
		return null;
	}

	return (
		<Drawer
			sx={{
				width: "250px",
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: "250px",
					zIndex: "0",
					height: common?.sideMenuIsPermanent ? "100%" : undefined,
					boxSizing: common?.sideMenuIsPermanent ? "border-box" : undefined,
					overflowY: "scroll",
					position: "relative",
				},
			}}
			variant={common?.sideMenuIsPermanent ? "permanent" : undefined}
			open={common?.sideMenuIsOpen}
			onClose={() => ReduxCommonApi.closeSideMenu()}
			anchor={"left"}
		>
			<Box
				style={{ width: "auto" }}
				role={"presentation"}
				onClick={() => ReduxCommonApi.closeSideMenu()}
				onKeyDown={() => ReduxCommonApi.closeSideMenu()}
			>
				<SideMenuItems group={routes[1]} />
			</Box>
		</Drawer>
	);
}
