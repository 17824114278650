import "./../Dialog.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import { ReactComponent as ConfirmDeleteIcon } from "_asset/deletion-confirmation-icon.svg";
import theme from "AppTheme";
import CustomDialog from "../components/CustomDialog";
import AppTheme from "AppTheme";

export interface DeleteConfirmationDialogProps {
	open?: boolean;
	handleClose: () => void;
	handleDelete: () => void;
	labels: {
		title: string;
		question: string;
		dialogBoldedNameToDelete: string;
		dialogText: string;
		acceptButtonLabel?: string;
		cancelButtonLabel?: string;
	};
	preferences?: {
		hideDeleteIcon?: boolean;
	};
}

export default function DeleteConfirmationDialog(
	props: DeleteConfirmationDialogProps
) {
	const { open, handleClose, labels, preferences } = props;

	const handleDeleteConfirmation = () => {
		props.handleDelete();
		handleClose();
	};

	return (
		<CustomDialog
			open={open ?? true}
			disableContentScroll
			handleDialogClose={handleClose}
			handleSubmitClick={handleDeleteConfirmation}
			labels={{
				cancel: labels?.cancelButtonLabel,
				submit: labels?.acceptButtonLabel ?? "Ištrinti",
				header: labels?.title,
			}}
			backgroundColors={{
				submit: AppTheme.palette.error.main,
			}}
			icons={{
				LeftSubmitIcon: !preferences?.hideDeleteIcon ? (
					<DeleteIcon
						style={{
							color: "white",
							height: "22px",
							width: "22px",
						}}
					/>
				) : (
					<></>
				),
			}}
		>
			<div className="dialog-body-text">
				<ConfirmDeleteIcon />
				<Typography
					variant="h2"
					align="center"
					className="dialog-title"
					color={theme.palette.text.primary}
					style={{
						paddingBottom: "8px",
						maxWidth: "480px",
					}}
				>
					{labels.question}
				</Typography>
				<Typography
					variant="subtitle"
					color={theme.palette.text.primary}
					align="center"
				>
					<b>{labels.dialogBoldedNameToDelete}</b>
					{` ${labels.dialogText}`}
				</Typography>
			</div>
		</CustomDialog>
	);
}
