import { store } from "../store";
import {
	setRegistration,
	previousState,
	updateCustomerInfo,
	updateTimeInfo,
	updateTopicInfo,
	goToSavedPhase,
} from "./RegistrationActions";
import Topic from "_model/topic/Topic";
import Customer from "_model/registration/Customer";
import { WorkTime } from "_model/account/WorkTime";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import { NavigateFunction } from "react-router-dom";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";

const ReduxRegistrationApi = {
	setRegistration: (registration: RegistrationResponse) =>
		store.dispatch(setRegistration(registration)),
	goToSavedPhase: (navigate: NavigateFunction) =>
		store.dispatch(goToSavedPhase(navigate)),
	previousState: () => store.dispatch(previousState()),
	chooseTopic: (topic?: Topic) => store.dispatch(updateTopicInfo(topic)),
	chooseTime: (workTime: WorkTime, receptionPoint: ReceptionPoint) =>
		store.dispatch(updateTimeInfo(workTime, receptionPoint)),
	inputPersonalInfo: (customer: Customer) =>
		store.dispatch(updateCustomerInfo(customer)),
};

export default ReduxRegistrationApi;
