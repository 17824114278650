import "./../Registration.css";
import { Formik, FormikProps } from "formik";
import CustomTextField from "_component/form/components/CustomTextField";
import { Box, Typography } from "@mui/material";
import * as yup from "yup";
import "yup-phone";
import reduxRegistrationApi from "_redux/registration/ReduxRegistrationApi";
import Customer from "_model/registration/Customer";
import { useAppSelector } from "_redux/hooks";
import { capitaliseFirstLetter, getSettingByName } from "_util/UtilFunctions";
import { getTime } from "_util/DateUtil";
import CustomGoogleMap from "_component/maps/CustomGoogleMap";
import CustomCheckBox from "_component/form/components/CustomCheckBox";
import CustomButton from "_component/buttons/CustomButton";
import ReusableCard from "../components/ReusableCard";
import moment from "moment";
import { RegistrationAPIPublic } from "_api/RegistrationAPI";
import RegistrationRequest from "_model/registration/RegistrationRequest";
import { useState } from "react";
import CustomSpinner from "_component/display/CustomSpinner";
import { useNavigate } from "react-router-dom";
import IconText from "../components/IconText";
import HouseIcon from "@mui/icons-material/House";
import EventIcon from "@mui/icons-material/Event";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { getIconForTopic } from "./TopicChoice";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SettingName } from "_model/setting/Setting";
import CodeValidationForm, {
	ValidationTextType,
} from "_component/form/templates/CodeValidationForm";

interface Validation {
	emailValidationCode?: string;
	emailIsValid?: boolean;
}

export default function ContactInformationForm() {
	const registration = useAppSelector((state) => state.registration);
	const settings = useAppSelector((state) => state.common.settings);
	const [loading, setLoading] = useState<boolean>(false);
	const [validation, setValidation] = useState<Validation>();
	const navigate = useNavigate();

	const shouldValidate = getSettingByName(
		SettingName.EMAIL_VALIDATION,
		settings
	)?.value;

	async function handleSubmit(values: ContactInfoFormValues) {
		setLoading(true);
		reduxRegistrationApi.inputPersonalInfo(values as Customer);
		let dto: RegistrationRequest = {
			customerName: values.name,
			customerLastName: values.lastName,
			customerPhoneNumber: values.phoneNumber,
			customerEmail: values.email,
			topicId: registration.info?.topic?.id ?? 0,
			workTimeId: registration.info?.workTime?.id ?? 0,
			emailValidationCode: shouldValidate
				? validation?.emailValidationCode
				: undefined,
			comment: values?.comment === "" ? undefined : values?.comment,
			remote: values.remote,
		};
		const response: RegistrationResponse | void =
			await RegistrationAPIPublic.create(dto).catch(() => {
				setLoading(false);
			});
		if (response) {
			reduxRegistrationApi.setRegistration(response); // needed for setting uuid
			navigate(`/registracija/${response?.uuid}`);
			setLoading(false);
		}
	}

	if (loading) {
		return (
			<CustomSpinner
				size={"large"}
				label={"Kuriame Jūsų registraciją... Prašome palaukti..."}
			/>
		);
	}
	return (
		<Box sx={{ display: "flex", gap: "24px", flexWrap: "wrap", mb: 3 }}>
			<ReusableCard
				header={"Duomenys"}
				subheader={"Užpildykite savo asmeninę informaciją"}
				icon={<AccountCircleIcon />}
				isExpanded={true}
				headerTextVariant={"h2"}
				containerFlex={"1 1 535px"}
			>
				<Formik
					initialValues={getInitialValues()}
					validationSchema={getCustomerValidationSchema()}
					onSubmit={handleSubmit}
				>
					{(formik: FormikProps<ContactInfoFormValues>) => (
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "1rem",
								background: "white",
								padding: "16px 24px",
							}}
						>
							<CustomTextField title={"Vardas"} name={"name"} />
							<CustomTextField title={"Pavardė"} name={"lastName"} />
							<CustomTextField
								title={"Telefono numeris"}
								name={"phoneNumber"}
								titleHelpText={
									"Telefono numeris bus naudojamis tik susiekimo ir informavimo tikslais."
								}
							/>
							<CustomTextField
								title={"El. pašto adresas"}
								name={"email"}
								titleHelpText={
									"Elektronis paštas bus naudojamis tik susiekimo ir informavimo tikslais."
								}
							/>
							{shouldValidate && (
								<CodeValidationForm
									type={ValidationTextType.EMAIL}
									name={"email"}
									setContactValid={(isValid: boolean) =>
										setValidation({ ...validation, emailIsValid: isValid })
									}
									setCode={(code: string) =>
										setValidation({ ...validation, emailValidationCode: code })
									}
								/>
							)}
							<Typography variant={"subtitle2"} color={"#4F4F4F"}>
								{"Registruojantis bus tvarkomi Jūsų asmens duomenys: vardas, pavardė, telefono numeris, " +
									"elektroninio pašto adresas, prisijungimo prie sistemos data, laikas, IP adresas. Daugiau " +
									"informacijos apie asmens duomenų tvarkymą skaitykite puslapyje Asmens duomenų apsauga."}
							</Typography>

							<CustomTextField
								title={"Jūsų vizito tikslas trumpai"}
								name={"comment"}
								rows={5}
								multiline
							/>

							<Box style={{ marginLeft: "-12px" }}>
								<CustomCheckBox
									label={
										"Konsultaciją noriu gauti nuotoliniu būdu (Su Jumis susisieks atsakingas specialistas)."
									}
									name={"remote"}
								/>
							</Box>

							<Box style={{ marginLeft: "-12px" }}>
								<CustomCheckBox
									label={"Patvirtinu, kad sutinku su "}
									textWithLink={"asmens duomenų tvarkymu"}
									link={"/asmens-duomenu-tvarkymas"}
									name={"consent"}
								/>
							</Box>

							<CustomButton
								type={"submit"}
								variant={"contained"}
								onClick={formik.submitForm}
								disabled={
									!(
										formik.dirty &&
										formik.isValid &&
										formik.values.consent &&
										(!shouldValidate || validation?.emailIsValid)
									)
								}
							>
								Tvirtinu rezervaciją
							</CustomButton>
						</Box>
					)}
				</Formik>
			</ReusableCard>

			<ReusableCard
				header={"Vizito informacija"}
				icon={<AssignmentIcon />}
				onHeaderClick={() => {
					reduxRegistrationApi.previousState();
					reduxRegistrationApi.goToSavedPhase(navigate);
				}}
				headerAction={
					<div
						style={{
							display: "flex",
							marginLeft: "auto",
							alignItems: "center",
						}}
					>
						<ArrowBackIcon
							style={{ height: "20px", width: "20px", paddingRight: "8px" }}
						/>
						Keisti
					</div>
				}
				isExpanded={true}
				headerTextVariant={"h3"}
				containerFlex={"1 1 349px"}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "2rem",
						padding: "16px 24px",
					}}
				>
					<IconText
						icon={getIconForTopic(registration.info?.topic?.name)}
						text={registration?.info?.topic?.name}
					/>
					<IconText
						icon={<EventIcon />}
						text={getRepresentedTime(
							moment(registration.info?.workTime?.start),
							moment(registration.info?.workTime?.end)
						)}
					/>
					<IconText
						icon={<HouseIcon />}
						text={registration?.info?.receptionPoint?.address}
					/>

					<div>
						{registration?.info?.receptionPoint && (
							<CustomGoogleMap
								location={{
									lat: Number.parseFloat(
										registration?.info?.receptionPoint?.latitude
									),
									lng: Number.parseFloat(
										registration?.info?.receptionPoint?.longitude
									),
									address: registration?.info?.receptionPoint?.address,
								}}
								style={{ height: "380px" }}
							/>
						)}
					</div>
				</div>
			</ReusableCard>
		</Box>
	);
}

const getInitialValues = () => ({
	name: "",
	lastName: "",
	phoneNumber: "",
	email: "",
	comment: "",
	consent: false,
	remote: false,
});
type ContactInfoFormValues = ReturnType<typeof getInitialValues>;

export const getCustomerValidationSchema = () =>
	yup.object().shape({
		name: yup.string().required("Vardas yra privalomas."),
		lastName: yup.string().required("Pavardė yra privaloma."),
		phoneNumber: yup
			.string()
			.phone("LT", false, "Neteisingas telefono numeris.")
			.required("Telefono numeris yra privalomas."),
		email: yup
			.string()
			.email("Įveskite tinkamą el. pašto adresą. Pvz: pavyzdys@email.com")
			.required("El. paštas yra privalomas."),
	});

export const getRepresentedTime = (
	start?: moment.Moment,
	end?: moment.Moment
): string => {
	if (!(start && end)) {
		return "N/A";
	}
	return `${capitaliseFirstLetter(
		start.format("MMMM DD, YYYY, HH:mm")
	)} - ${getTime(end, true)}`;
};
