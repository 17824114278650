import * as yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik, FormikProps } from "formik";
import { useContext } from "react";
import CustomTextField from "_component/form/components/CustomTextField";
import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import Topic from "_model/topic/Topic";
import AppTheme from "AppTheme";
import { Typography } from "@mui/material";
import CustomIconButton from "_component/buttons/CustomIconButton";
import CustomButton from "_component/buttons/CustomButton";

interface Props {
	topic?: Topic;
	handleEdit: (emailTemplate: Topic) => void;
}

export default function TopicEditForm(props: Props) {
	const { topic, handleEdit } = props;
	const { formikFormRef } = useContext(FormContext);

	const handleFormikSubmit = (values: any) => {
		handleEdit(values as Topic);
	};

	return (
		<Formik
			initialValues={getInitialValues(topic)}
			validationSchema={validationSchema()}
			onSubmit={handleFormikSubmit}
			innerRef={formikFormRef} // used for submit outside Formik component
		>
			{(formik: FormikProps<any>) => (
				<>
					{/* Updates formContext, which is used in outside dialog */}
					<FormContextRefreshConduit />

					<CustomTextField title={"Tema"} name={"name"} required />
					<Typography color={AppTheme.palette.text.primary} variant={"h3"}>
						{`Potemės (${formik.values.childTopics.length})`}
					</Typography>
					{formik.values.childTopics.map((subtopic: any, index: number) => (
						<div key={index}>
							<CustomTextField
								label={`Potemė nr. ${index + 1}`}
								name={`childTopics[${index}].name`}
								endIcon={
									subtopic?.deletable && (
										<CustomIconButton
											onClick={() => {
												const childTopics = formik.values.childTopics.slice();
												childTopics.splice(index, 1);
												formik.setFieldValue("childTopics", childTopics, true);
											}}
											icon={<CancelIcon />}
										/>
									)
								}
								required
							/>
						</div>
					))}
					<CustomButton
						onClick={() => {
							formik.setFieldValue("childTopics", [
								...formik.values.childTopics,
								getDefaultSubtopic(),
							]);
						}}
					>
						Nauja potemė
					</CustomButton>
				</>
			)}
		</Formik>
	);
}

const getInitialValues = (topic?: Topic): Topic => {
	if (!topic) {
		return {
			id: undefined,
			name: "",
			childTopics: [],
		};
	}
	return {
		id: topic.id,
		name: topic.name,
		childTopics: topic?.childTopics?.slice(),
	};
};

const getDefaultSubtopic = (): Topic => ({
	id: undefined,
	name: "",
	deletable: true,
});

const validationSchema = () =>
	yup.object({
		name: yup.string().required("Tema yra privaloma."),
		childTopics: yup
			.array(
				yup.object({
					name: yup.string().required("Potemės pavadinimas yra privalomas."),
				})
			)
			.max(15, "Tema negali turėti daugiau negu 15 potemių."),
	});
