import { useEffect, useState } from "react";
import { CustomTable } from "@recodin/fe-components";
import { EmailTableHeadRow, EmailTableRow } from "./EmailTableRow";
import EmailResponse from "_model/email/EmailResponse";
import { SearchRequest } from "_component/form/templates/SearchForm";
import { FilterRequest } from "_component/form/templates/FilterForm";
import { EmailAPIAdmin } from "_api/EmailAPI";
import { allEmailStatus, findEmailStatus } from "_model/email/EmailStatus";
import {
	allEmailTypes,
	translateEmailType,
} from "_model/emailtemplate/EmailType";

export default function EmailPage() {
	const [emails, setEmails] = useState<EmailResponse[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		handleDataFetch();
	}, []);

	async function handleDataFetch(
		search?: SearchRequest,
		filter?: FilterRequest
	) {
		setLoading(true);
		setEmails(await EmailAPIAdmin.getAllFiltered(filter));
		setLoading(false);
	}

	return (
		<div>
			<CustomTable
				data={emails}
				isLoading={loading}
				headerRow={EmailTableHeadRow}
				contentRow={EmailTableRow}
				handleSearchAndFilter={handleDataFetch}
				filterFormProps={{
					selectItems: {
						statuses: allEmailStatus,
						types: allEmailTypes,
					},
					selectItemsTranslation: {
						status: (val: string) => findEmailStatus(val)?.label ?? "",
						type: (val: string) => translateEmailType(val, true),
					},
				}}
			/>
		</div>
	);
}
