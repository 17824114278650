import "./index.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import AppTheme from "AppTheme";
import setupAxiosInterceptors from "_services/axiosinterceptors";
import Main from "_page/main/Main";
import { Provider } from "react-redux";
import { store } from "_redux/store";
import moment from "moment";
import "moment/locale/lt";

const root = ReactDOM.createRoot(document.getElementById("root")!);

setupAxiosInterceptors();
moment.locale("lt");

root.render(
	<BrowserRouter>
		<ThemeProvider theme={AppTheme}>
			<Provider store={store}>
				<Main />
			</Provider>
		</ThemeProvider>
	</BrowserRouter>
);
