import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
	ActionsCell,
} from "@recodin/fe-components";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";
import {
	deleteDialogProps,
	editDialogProps,
} from "./ReceptionPointDialogProps";

export function ReceptionPointTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: "Pavadinimas",
					width: "30%",
					align: "left",
				},
				{
					label: "Adresas",
					width: "40%",
					align: "left",
				},
				{
					label: "Kodas",
					width: "20%",
					align: "left",
				},
				{
					label: "Veiksmai",
					width: "10%",
				},
			]}
		/>
	);
}

interface Props {
	row: ReceptionPoint;
	handleDelete: (row: ReceptionPoint) => void;
	handleCreateUpdate: (row: ReceptionPoint) => void;
}
export function ReceptionPointTableRow(props: Props) {
	const { row, handleDelete, handleCreateUpdate } = props;
	return (
		<CustomTableRow>
			<TextCell width={"30%"} align={"left"} label={row.name} />
			<TextCell width={"40%"} align={"left"} label={row.address} />
			<TextCell width={"20%"} align={"left"} label={row.code} />
			<ActionsCell
				width={"10%"}
				editDialogProps={editDialogProps(handleCreateUpdate, row)}
				deleteDialogProps={deleteDialogProps(row)}
				handleDelete={() => handleDelete(row)}
			/>
		</CustomTableRow>
	);
}
