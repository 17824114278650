import "./FormTemplates.css";
import { Alert, CircularProgress, Typography, useTheme } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { Form, Formik, FormikProps, useField } from "formik";
import * as yup from "yup";
import CustomTextField from "_component/form/components/CustomTextField";
import React, { useEffect, useState } from "react";
import AutoSubmitToken from "../components/AutoSubmitToken";
import { ValidationAPIPublic } from "_api/ValidationAPI";
import appTheme from "AppTheme";
import CustomIconButton from "../../buttons/CustomIconButton";

export enum ValidationTextType {
	EMAIL = "EMAIL",
	PHONE = "PHONE",
}

interface CodeValidationFormProps {
	type: ValidationTextType;
	name: string;
	setContactValid: (valid: boolean) => void;
	setCode: (code: string) => void;
}

export default function CodeValidationForm({
	type,
	name,
	setContactValid,
	setCode,
}: CodeValidationFormProps) {
	const [field, meta] = useField(name);
	const theme = useTheme();

	const [hide, setHide] = useState<boolean>();
	const [loading, setLoading] = useState<boolean>(false);
	const [valid, setValid] = useState<boolean>();
	const [completed, setCompleted] = useState<boolean>();
	const [letterSent, setLetterSent] = useState<boolean>(false);
	const [disable, setDisable] = useState<boolean>(false);

	useEffect(() => {
		setHide(meta.error !== undefined || field.value === "");
	}, [meta.error, field.value]);

	useEffect(() => {
		setCompleted(false);
		setValid(undefined);
		setContactValid(false);
		setLetterSent(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.value]);

	function handleFunctionCall(emailFunc: Function) {
		setLoading(true);
		if (type === ValidationTextType.EMAIL) {
			emailFunc();
		} else if (type === ValidationTextType.PHONE) {
			throw new Error("Phone validation is not implemented");
		}
		setLoading(false);
	}

	function handleFormikSubmit(values: any) {
		handleFunctionCall(async () => {
			const result = await ValidationAPIPublic.isEmailValid(
				field.value,
				values.code
			);
			setValid(result);
			if (result) {
				setContactValid(true);
				setCode(values.code);
				setTimeout(() => setCompleted(true), 500);
			}
		});
	}

	function resendValidationMessage() {
		if (disable) return;

		setDisable(true);
		handleFunctionCall(async () => {
			await ValidationAPIPublic.sendValidationCodeToEmail(field.value);
			setLetterSent(true);
		});
		setTimeout(() => setDisable(false), 300);
	}

	if (hide) {
		return null;
	}
	return (
		<Formik
			initialValues={getInitialValues()}
			validationSchema={validationSchema()}
			onSubmit={handleFormikSubmit}
		>
			{(formik: FormikProps<any>) => (
				<Form
					onSubmit={(e: any) => {
						e.preventDefault();
						formik.handleSubmit();
					}}
				>
					<Alert
						severity={
							valid === undefined ? "info" : valid ? "success" : "error"
						}
						icon={loading ? <CircularProgress size={20} /> : null}
						className={"codeValidationForm-alert"}
					>
						<div className={"codeValidationForm-form"}>
							{!completed && (
								<>
									<Typography variant={"h4"}>
										{!letterSent
											? "Paspauskite ant žemiau esančio laiško siuntimo mygtuko, kad išsiųstume laišką su kodu."
											: `Laiškas su kodu išsiųstas. Patikrinkite savo paštą (${field.value}) ir įrašykite gautą kodą. Jeigu per kelias minutes laiško negavote, paspauskite ant žemiau esančio laiško siuntimo mygtuko, kad išsiųstume naują laišką su nauju kodu.`}
									</Typography>
									{valid === false && (
										<Typography
											variant={"h4"}
											color={theme.palette.error?.main}
										>
											Įvestas kodas yra neteisingas
										</Typography>
									)}
									{valid === true && (
										<Typography
											variant={"h4"}
											color={theme.palette.success?.main}
										>
											Įvestas kodas yra teisingas
										</Typography>
									)}
									<CustomTextField
										value={formik.values.code}
										name={"code"}
										label={"Gautas kodas"}
										startIcon={
											<CustomIconButton
												icon={<SendIcon />}
												title={"Siųsti laišką su patvirtinimo kodu"}
												onClick={resendValidationMessage}
												disabled={disable}
											/>
										}
										fullWidth
									/>
								</>
							)}
							{completed && (
								<Typography
									variant={"h5"}
									style={{ color: appTheme.palette.text.secondary }}
									fontWeight={100}
								>
									{"Sėkmingai patvirtinote paštą."}
								</Typography>
							)}
						</div>
					</Alert>
					<AutoSubmitToken />
				</Form>
			)}
		</Formik>
	);
}

const getInitialValues = () => ({
	code: "",
});

const validationSchema = () => {
	return yup.object({
		code: yup
			.string()
			.length(4, "Privalo būti keturi simboliai.")
			.required("Privaloma įvesti gautą kodą."),
	});
};
