import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
	goToPhase,
	previous,
	updateCustomer,
	updateRegistration,
	updateTime,
	updateTopicSubtopic,
} from "./RegistrationSlice";
import Topic from "_model/topic/Topic";
import Customer from "_model/registration/Customer";
import { WorkTime } from "_model/account/WorkTime";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import { NavigateFunction } from "react-router-dom";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";

export const setRegistration =
	(
		registration: RegistrationResponse
	): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(updateRegistration({ registration }));
	};

export const goToSavedPhase =
	(
		navigate: NavigateFunction
	): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(goToPhase({ navigate }));
	};

export const previousState =
	(): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
		dispatch(previous());
	};

export const updateTopicInfo =
	(topic?: Topic): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(updateTopicSubtopic({ topic }));
	};

export const updateTimeInfo =
	(
		workTime: WorkTime,
		receptionPoint: ReceptionPoint
	): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(updateTime({ workTime, receptionPoint }));
	};

export const updateCustomerInfo =
	(customer: Customer): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(updateCustomer({ customer }));
	};
