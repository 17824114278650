import CollapsableSectionDisplay from "_component/display/CollapsableSectionDisplay";
import CustomTextField from "_component/form/components/CustomTextField";
import RoleResponse, {
	RoleNames,
	translateRoleById,
} from "_model/account/RoleResponse";
import MultipleAutoComplete from "_component/form/components/MultipleAutoComplete";
import { Formik } from "formik";
import Department from "_model/department/Department";
import Topic from "_model/topic/Topic";
import CustomFormActions from "_component/form/templates/CustomFormActions";
import { AccountAPIAdmin } from "_api/AccountAPI";
import AccountResponse from "_model/account/AccountResponse";
import * as yup from "yup";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";
import { useAppSelector } from "_redux/hooks";
import { customParseInt, hasRole } from "_util/UtilFunctions";
import { useState } from "react";
import { Box } from "@mui/material";
import AccountEditRequest from "_model/account/AccountEditRequest";

interface Props {
	maybeId?: string;
	account?: AccountResponse;
	departments: Department[];
	topics: Topic[];
	receptionPoints: ReceptionPoint[];
	refresh: () => void;
}

export default function GeneralInfoSection(props: Props) {
	const { account, topics, departments, receptionPoints, refresh } = props;

	const roles = useAppSelector((state) => state.common?.allAccountRoles) ?? [];
	const specialistId = roles?.find(
		(role) => role.name === RoleNames.SPECIALIST
	)?.id;
	const [assignedRoles, setAssignedRoles] = useState<RoleResponse[]>(
		account?.roles ?? []
	);

	const allTopics = flatMapTopics(topics);
	const allTopicValues = flatMapTopicValues(topics, 0);
	const allDepartmentValues = departments.map((item): string => `${item.id}`);
	const allRoleValues = roles.map((item): string => `${item?.id}`);
	const allReceptionPoints = receptionPoints.map(
		(item): string => `${item.id}`
	);
	const me = useAppSelector((state) => state.me);

	async function handleFormikSubmit(data: GeneralInfoSectionFormValues) {
		const request: AccountEditRequest = {
			...data,
			topicIds:
				data?.topicIds?.map((topicValue) => Number(topicValue.split(";")[0])) ??
				[],
			receptionPointId: customParseInt(data.receptionPointId),
		};
		if (request?.id && props.maybeId !== "naujas") {
			await AccountAPIAdmin.update(request);
		} else {
			await AccountAPIAdmin.create(request);
		}
		refresh();
	}

	function toRoleResponses(ids?: string[]): RoleResponse[] {
		if (!ids || ids.length === 0) {
			return [];
		}
		return roles.filter((role) =>
			ids.some((id) => id === role?.id?.toString())
		);
	}

	return (
		<CollapsableSectionDisplay title={"Bendra informacija"} initiallyOpen>
			<Formik
				initialValues={getInitialValues(
					account,
					hasRole(RoleNames.ADMIN, me.info?.roles),
					specialistId,
					allTopicValues
				)}
				validationSchema={getValidationSchema(
					allRoleValues,
					allDepartmentValues,
					allTopicValues,
					allReceptionPoints,
					assignedRoles
				)}
				onSubmit={handleFormikSubmit}
			>
				<>
					<CustomTextField
						title={"Vardas"}
						titleVariant={"row"}
						name={"name"}
						required
					/>
					<CustomTextField
						title={"Pavardė"}
						titleVariant={"row"}
						name={"lastName"}
						required
					/>
					<CustomTextField
						title={"El. paštas"}
						titleVariant={"row"}
						name={"email"}
						required
					/>
					<MultipleAutoComplete
						name={"roleIds"}
						title={"Rolės"}
						options={allRoleValues}
						translateOptionLabel={(id: string) => translateRoleById(id, roles)}
						show={hasRole(RoleNames.ADMIN, me.info?.roles)}
						onValueChange={(roleIds: any) => {
							setAssignedRoles(toRoleResponses(roleIds));
						}}
					/>
					<MultipleAutoComplete
						name={"managedDepartments"}
						title={"Vadovaujami skyriai"}
						options={allDepartmentValues}
						translateOptionLabel={(value: string) =>
							defaultTranslation(departments, value)
						}
						show={hasRole(RoleNames.MANAGER, assignedRoles)}
					/>
					<MultipleAutoComplete
						name={"topicIds"}
						title={"Konsultavimo temos"}
						options={allTopicValues}
						translateOptionLabel={(value: string) => {
							const info: string[] = value.split(";");
							// currentId;parrentId;isParent 1 or 0;level 0, 1, 2, ...
							return info.length === 0
								? "N/A"
								: defaultTranslation(allTopics, info[0]);
						}}
						renderOptionStyle={(value: string) => {
							const info: string[] = value.split(";");
							// currentId;parrentId;isParent 1 or 0;level 0, 1, 2, ...
							return info.length !== 4
								? {}
								: {
										fontWeight:
											Boolean(Number(info[2])) || Number(info[3]) === 0
												? 700
												: 300,
										paddingLeft: `calc(5px * ${Number(info[3])})`,
								  };
						}}
						getNewValue={(
							newValue?: string | string[],
							oldValue?: string | string[]
						) => {
							if (
								typeof newValue === "string" ||
								!newValue ||
								typeof oldValue === "string" ||
								!oldValue
							) {
								// In this field only array values matter
								return newValue;
							}
							return getNewTopicValues(oldValue, newValue, allTopicValues);
						}}
						show={hasRole(RoleNames.SPECIALIST, assignedRoles)}
					/>
					<MultipleAutoComplete
						name={"receptionPointId"}
						title={"Priėmimo taškas"}
						options={allReceptionPoints}
						translateOptionLabel={(value: string) =>
							defaultTranslation(receptionPoints, value)
						}
						multiple={false}
						show={
							hasRole(RoleNames.SPECIALIST, assignedRoles) ||
							hasRole(RoleNames.OBSERVER, assignedRoles)
						}
					/>
					<MultipleAutoComplete
						name={"workDepartments"}
						title={"Skyriai"}
						options={allDepartmentValues}
						translateOptionLabel={(value: string) =>
							defaultTranslation(departments, value)
						}
						show={hasRole(RoleNames.SPECIALIST, assignedRoles)}
					/>

					{hasRole(RoleNames.SPECIALIST, assignedRoles) && (
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Box>
								Jūsų duomenys ir gyventojų registracijos bus matomos Jūsų
								vadovams:{" "}
							</Box>
							{account?.workDepartments.map((department) => (
								<Box key={department?.id}>
									{department.managers?.map(
										(manager) => `${manager.name} (${manager.email})`
									)}
								</Box>
							))}
						</Box>
					)}
					<CustomFormActions onCancel={refresh} />
				</>
			</Formik>
		</CollapsableSectionDisplay>
	);
}

const defaultTranslation = (items: any[], id: string): string => {
	return items.find((item) => `${item.id}` === id)?.name ?? "";
};

const getInitialValues = (
	account?: AccountResponse,
	isCurrentUserAdmin?: boolean,
	specialistId?: number,
	allTopicValues?: string[]
) => {
	const newUserRoles = isCurrentUserAdmin ? [] : [specialistId ?? -1];
	const toIdArray = (items: any): number[] =>
		items?.filter((r: any) => r?.id).map((r: any) => `${r.id}`) ?? [];

	const topicIds = toIdArray(account?.topics).map((id) => id + "");
	return {
		id: account?.id,
		name: account?.name ?? "",
		lastName: account?.lastName ?? "",
		email: account?.email ?? "",
		roleIds: account?.roles ? toIdArray(account?.roles) : newUserRoles,
		managedDepartments: toIdArray(account?.managedDepartments),
		topicIds: allTopicValues?.filter((value) =>
			topicIds.includes(value.split(";")[0])
		),
		receptionPointId: account?.receptionPoint
			? `${account?.receptionPoint?.id}`
			: "",
		workDepartments: toIdArray(account?.workDepartments),
	};
};
type GeneralInfoSectionFormValues = ReturnType<typeof getInitialValues>;

const getValidationSchema = (
	allRoleNames: string[],
	allDepartmentNames: string[],
	allTopicNames: string[],
	allReceptionPointNames: string[],
	assignedRoles?: RoleResponse[]
) => {
	function getReceptionPointIdValidation() {
		const schema = yup
			.string()
			.oneOf(
				allReceptionPointNames,
				"Pasirinkite vieną iš nurodytų priėmimo taškų."
			);
		return hasRole(RoleNames.SPECIALIST, assignedRoles) ||
			hasRole(RoleNames.OBSERVER, assignedRoles)
			? schema.required("Būtina nustatyti priėmimo tašką.")
			: schema.nullable();
	}

	return yup.object({
		name: yup.string().required("Vardas yra privaloma."),
		lastName: yup.string().required("Pavardė yra privaloma."),
		email: yup
			.string()
			.email("El. paštas")
			.required("El. paštas yra privaloma."),
		roleIds: yup
			.array(
				yup
					.string()
					.oneOf(
						[null, ...allRoleNames],
						"Pasirinkite vieną iš nurodytų rolių."
					)
			)
			.min(1, "Parinkti bent vieną rolę yra privaloma.")
			.nullable(),
		managedDepartments: yup
			.array(
				yup
					.string()
					.oneOf(
						[null, ...allDepartmentNames],
						"Pasirinkite vieną iš nurodytų skyrių."
					)
			)
			.min(
				hasRole(RoleNames.MANAGER, assignedRoles) ? 1 : 0,
				"Privaloma parinkti bent vieną skyrių."
			)
			.nullable(),
		topicIds: yup
			.array(
				yup
					.string()
					.oneOf(
						[null, ...allTopicNames],
						"Pasirinkite vieną iš nurodytų konsultavimo temų."
					)
			)
			.min(
				hasRole(RoleNames.SPECIALIST, assignedRoles) ? 1 : 0,
				"Privaloma parinkti bent vieną konsultavimo temą."
			)
			.nullable(),
		workDepartments: yup
			.array(
				yup
					.string()
					.oneOf(
						[null, ...allDepartmentNames],
						"Pasirinkite vieną iš nurodytų skyrių."
					)
			)
			.nullable(),
		receptionPointId: getReceptionPointIdValidation(),
	});
};

function flatMapTopics(topics: Topic[]): Topic[] {
	return topics.reduce(
		(acc: Topic[], cur: Topic) =>
			acc.concat(
				cur?.childTopics && cur.childTopics.length !== 0
					? [cur, ...flatMapTopics(cur.childTopics)]
					: cur
			),
		[]
	);
}

function getValue(
	current: Topic,
	isParent: boolean,
	level: number,
	parentId?: number
): string {
	// currentId;parrentId;isParent 1 or 0;level 0, 1, 2, ...
	return `${current.id};${parentId ?? -1};${Number(isParent)};${level}`;
}

function flatMapTopicValues(
	topics: Topic[],
	level: number,
	parentId?: number
): string[] {
	return topics.reduce(
		(acc: string[], cur: Topic) =>
			acc.concat(
				cur?.childTopics && cur.childTopics.length !== 0
					? [
							getValue(cur, true, level, parentId),
							...flatMapTopicValues(cur.childTopics, level + 1, cur.id),
					  ]
					: getValue(cur, false, level, parentId)
			),
		[]
	);
}

function getNewTopicValues(
	oldValues: string[],
	newValues: string[],
	allValues: string[]
): string[] {
	// All values are in this format:
	// currentId;parrentId;isParent 1 or 0;level 0, 1, 2, ...
	let result = [];

	const added = newValues.filter((v) => !oldValues.includes(v));
	for (const value of added) {
		result.push(value);

		const info = value.split(";");
		if (Boolean(Number(info[2]))) {
			// If it is parent, then add all his children values
			result.push(...getChildTopicValues(value, allValues));
		}
	}

	const same = newValues.filter((v) => oldValues.includes(v));
	for (const value of same) {
		if (!result.includes(value)) {
			// Add all not duplicate old values to the front
			result.unshift(value);
		}
	}

	// Deleted values must be filtered at the end
	const deleted = oldValues.filter((v) => !newValues.includes(v));
	for (const value of deleted) {
		// Parent can only exist if all children values are selected.
		// So if child is deleted, then delete parent value.
		const info = value.split(";");
		result = result.filter(
			(resultValue) => resultValue.split(";")[0] !== info[1]
		);
	}

	return result;
}

function getChildTopicValues(value: string, allValues: string[]): string[] {
	const info = value.split(";");
	if (Boolean(Number(info[2]))) {
		return (
			allValues
				// Get children of current value
				.filter((v) => v.split(";")[1] === info[0])
				// Get grandchildren if they exist
				.flatMap((v) => getChildTopicValues(v, allValues))
		);
	} else {
		return [value];
	}
}
