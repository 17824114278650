import { Box, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Topic from "_model/topic/Topic";
import ReusableCard from "../components/ReusableCard";
import reduxRegistrationApi from "_redux/registration/ReduxRegistrationApi";
import { TopicAPIPublic } from "_api/TopicAPI";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomSpinner from "_component/display/CustomSpinner";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GavelIcon from "@mui/icons-material/Gavel";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import EventIcon from "@mui/icons-material/Event";
import LocalPlayIcon from "@mui/icons-material/LocalPlay";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import BusinessIcon from "@mui/icons-material/Business";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import HomeIcon from "@mui/icons-material/Home";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";

export default function TopicChoice() {
	const [topics, setTopics] = useState<Topic[]>();
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		setLoading(true);
		setTopics(await TopicAPIPublic.getAll());
		setLoading(false);
	};

	const toggleTopic = (id: number) => {
		let topic = topics?.find((topic) => topic.id === id);
		if (topic && topic?.childTopics?.length !== 0) {
			setTopics(
				topics?.map((topic) =>
					topic.id === id ? { ...topic, expanded: !topic.expanded } : topic
				)
			);
		} else if (topic) {
			chooseSubtopic(topic);
		}
	};

	const showSubtopics = (topic: Topic) =>
		topic.expanded && topic?.childTopics?.length !== 0;

	const chooseSubtopic = (topic?: Topic) => {
		reduxRegistrationApi.chooseTopic(topic);
		reduxRegistrationApi.goToSavedPhase(navigate);
	};

	return (
		<>
			{loading && <CustomSpinner size={"large"} />}
			{!loading && topics?.length === 0 && (
				<Typography variant={"h4"} fontWeight={600}>
					Nepavyko gauti temų, prašome susisiekti su administratoriais arba
					vėliau pabandyti dar kartą.
				</Typography>
			)}
			{!loading && topics?.length !== 0 && (
				<TopicAndTextContainer>
					<TextContainer>
						<Typography sx={{ mb: 2 }} variant="h2">
							Pasirinkite dominančią paslaugą
						</Typography>
					</TextContainer>
					<TopicListContainer>
						{topics?.map((topic) => (
							<ReusableCard
								key={topic.id}
								header={topic.name}
								headerTextVariant={"h4"}
								onHeaderClick={() => (topic.id ? toggleTopic(topic.id) : "")}
								headerAction={
									topic?.childTopics?.length !== 0 && (
										<Box
											sx={{
												display: "flex",
												marginLeft: "auto",
												alignItems: "center",
											}}
										>
											({topic?.childTopics?.length}) <KeyboardArrowDownIcon />
										</Box>
									)
								}
								icon={getIconForTopic(topic.name)}
							>
								<Box
									sx={{
										m: 1.5,
										gap: 1.5,
										display: "flex",
										flexDirection: "column",
									}}
								>
									{showSubtopics(topic) &&
										topic?.childTopics?.map((childTopic: Topic) => (
											<SubTopic
												onClick={() => chooseSubtopic(childTopic)}
												key={childTopic.id}
											>
												{childTopic.name}
											</SubTopic>
										))}
								</Box>
							</ReusableCard>
						))}
					</TopicListContainer>
				</TopicAndTextContainer>
			)}
		</>
	);
}

const TopicAndTextContainer = styled("div")({
	display: "flex",
	flexDirection: "column",
});

const TopicListContainer = styled("div")({
	display: "flex",
	flexWrap: "wrap",
	gap: 16,
});

const TextContainer = styled("div")({
	display: "flex",
	flexDirection: "column",
	gap: 8,
});

const SubTopic = styled("div")({
	textDecoration: "underline",
	paddingLeft: "32px",
	cursor: "pointer",
});

export const getIconForTopic = (topicName?: string) => {
	const name = topicName?.trim();
	switch (name) {
		case "Gyvenamos vietos deklaravimas":
			return <AccountBalanceIcon />;
		case "Pirminė teisinė pagalba":
			return <GavelIcon />;
		case "Civilinės būklės įrašai, vardo ir (ar) pavardės keitimas":
			return <AssignmentIndIcon />;
		case "Santuokos":
			return <FavoriteIcon />;
		case "Vaikas + (visos paslaugos susijusios su vaiko gimimu, augimu, ugdymu bei Išmokomis)":
			return <ChildCareIcon />;
		case "Socialinė parama":
		case "Socialinė pašalpa ir/ar būsto šildymo išlaidų kompensacija":
		case "Nemokamas mokinių maitinimas ir parama mokinio reikmenims":
		case "Slaugos ar priežiūros (pagalbos) išlaidų tikslinė kompensacija":
		case "Vienkartinė, tikslinė, periodinė, sąlyginė pašalpa":
		case "Parama mirties atveju":
		case "Išlaidų už komunalinių atliekų surinkimo ir tvarkymo paslaugas kompensacija.":
		case "Parama maisto produktais":
		case "Pažyma apie gaunamas išmokas":
		case "Pažyma dėl kredito ir palūkanų namui modernizuoti apmokėjimo":
		case "Socialinės paslaugos (pagalba į namus, socialinė globa ir kt.)":
		case "Asmeninė pagalba neįgaliesiems":
		case "Būsto pritaikymas neįgaliesiems":
		case "Kita":
			return <AccessibilityIcon />;
		case "Leidimai organizuoti renginius":
			return <EventIcon />;
		case "Leidimai Išorinei reklamai įrengti":
			return <LocalPlayIcon />;
		case "Architektūra ir statyba":
		case "Teritorijų planavimo bei statybą leidžiantys dokumentai":
		case "Žemės paskirties keitimas":
		case "Adresų suteikimas":
		case "Žemėtvarka":
		case "Dėl triukšmo šaltinių naudojimo planuojant remonto ar statybos darbus":
			return <ArchitectureIcon />;
		case "Aplinkosauga ir želdiniai":
		case "Leidimai kirsti / genėti medžius":
		case "Želdinių sodinimas":
			return <NaturePeopleIcon />;
		case "Savivaldybės ūkis":
		case "Šaligatviai, dviračių takai, keliai, daugiabučių namų aikštelės":
		case "Eismo saugumas":
		case "Daugiabučių namų renovacija":
		case "Daugiabučių namų administravimas / bendrijos":
		case "Atliekų tvarkymas":
		case "Techninių projektų / schemų derinimas":
			return <BusinessIcon />;
		case "Rinkliavos":
		case "Kurorto rinkliava":
		case "Vietinė rinkliava už komunalinių atliekų surinkimą":
		case "Kasinėjimo leidimai":
		case "Infrastruktūros mokestis":
			return <LocalAtmIcon />;
		case "Žemės ūkis, ūkio technika, melioracija":
		case "Paslaugos ūkininkams":
		case "Žemės ūkio technika":
		case "Melioracija":
			return <AgricultureIcon />;
		case "Socialinis būstas, parama būstui įsigyti":
			return <HomeIcon />;
		case "Archyvo pažymos / likviduojamų įmonių tvarkymas":
			return <FolderOpenIcon />;
		default:
			return <QuestionMarkIcon />;
	}
};
