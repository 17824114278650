import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	RegistrationPhase,
	translateRegistrationPhase,
} from "_page/registration/RegistrationPhase";
import Registration from "_model/registration/Registration";
import Topic from "_model/topic/Topic";
import Customer from "_model/registration/Customer";
import { WorkTime } from "_model/account/WorkTime";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import { NavigateFunction } from "react-router-dom";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";

export interface RegistrationState {
	phase: RegistrationPhase;
	info: Registration;
}

const getInitialState = (): RegistrationState => ({
	phase: RegistrationPhase.TOPIC_CHOICE,
	info: {},
});

const registrationSlice = createSlice({
	name: "registration",
	initialState: getInitialState(),
	reducers: {
		updateRegistration: (
			state: RegistrationState,
			payload: PayloadAction<{ registration: RegistrationResponse }>
		) => {
			state.info = {
				...payload.payload.registration,
				customer: {
					name: payload.payload.registration.customerName,
					lastName: payload.payload.registration.customerLastName,
					email: payload.payload.registration.customerEmail,
					phoneNumber: payload.payload.registration.customerPhoneNumber,
				},
			};
			state.phase = RegistrationPhase.FINISHED;
		},
		goToPhase: (
			state: RegistrationState,
			payload: PayloadAction<{ navigate: NavigateFunction }>
		) => {
			const maybePhase = window.location.pathname.split("/").at(-1);
			const current = translateRegistrationPhase(state.phase);
			if (current !== maybePhase) {
				payload.payload.navigate("/" + current);
			}
		},
		previous: (state: RegistrationState) => {
			if (state.phase !== RegistrationPhase.TOPIC_CHOICE) {
				state.phase = state.phase - 1;
			}
		},
		updateTopicSubtopic: (
			state: RegistrationState,
			payload: PayloadAction<{ topic?: Topic }>
		) => {
			state.info = {
				...getInitialState(),
				topic: payload.payload.topic,
			};
			state.phase = RegistrationPhase.TIME_CHOICE;
		},
		updateTime: (
			state: RegistrationState,
			payload: PayloadAction<{
				workTime: WorkTime;
				receptionPoint: ReceptionPoint;
			}>
		) => {
			state.info = {
				...state.info,
				workTime: payload?.payload?.workTime,
				receptionPoint: payload?.payload?.receptionPoint,
			};
			state.phase = RegistrationPhase.CONTACT_INFORMATION_INPUT;
		},
		updateCustomer: (
			state: RegistrationState,
			payload: PayloadAction<{ customer: Customer }>
		) => {
			state.info = {
				...state.info,
				customer: payload.payload.customer,
			};
		},
	},
});

export const {
	updateRegistration,
	goToPhase,
	previous,
	updateTopicSubtopic,
	updateTime,
	updateCustomer,
} = registrationSlice.actions;
export default registrationSlice.reducer;
