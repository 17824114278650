import { Typography } from "@mui/material";

export interface TextCellProps {
	label: any;
	title?: any;
}

export default function DangerousHTMLViewTypography(props: TextCellProps) {
	return (
		<>
			<Typography variant={"h4"}>{props.title}</Typography>
			<Typography
				dangerouslySetInnerHTML={{
					__html: props.label,
				}}
				style={{ padding: "10px 0px" }}
			/>
		</>
	);
}
