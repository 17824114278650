import { Box, styled, Typography } from "@mui/material";
import { cloneElement, useState } from "react";

type TextVariants =
	| "subtitle"
	| "subtitle2"
	| "h1"
	| "h2"
	| "h3"
	| "h4"
	| "h5"
	| "h6";

interface Props {
	icon?: any;
	header?: string;
	headerTextVariant?: TextVariants;
	headerAction?: any;
	onHeaderClick?: Function;
	subheader?: string;
	subheaderTextVariant?: TextVariants;
	subSubheader?: string;
	children?: any;
	containerFlex?: string;
	noContainerBackground?: boolean;
	isExpanded?: boolean;
	simpleContainerVariant?: boolean;
}

export default function ReusableCard({
	icon,
	header,
	headerTextVariant = "h3",
	onHeaderClick,
	headerAction,
	subheader,
	subheaderTextVariant = "subtitle",
	subSubheader,
	children,
	containerFlex,
	isExpanded = false,
}: Props) {
	const [expanded, setExpanded] = useState<boolean>(isExpanded);

	return (
		<CardContainer containerFlex={containerFlex}>
			{header && (
				<CardHeader
					clickable={Boolean(onHeaderClick)}
					onClick={(data: any) => {
						onHeaderClick && onHeaderClick(data);
						onHeaderClick && setExpanded(!expanded);
					}}
				>
					{icon &&
						cloneElement(icon, {
							height: 32,
							width: 32,
						})}
					<CardText>
						<Typography variant={headerTextVariant}>{header}</Typography>
						<Typography variant={subheaderTextVariant}>{subheader}</Typography>
						<Typography variant={subheaderTextVariant}>
							{subSubheader}
						</Typography>
					</CardText>
					{headerAction && (
						<Box style={{ marginLeft: "auto", display: "flex" }}>
							{headerAction}
						</Box>
					)}
				</CardHeader>
			)}

			{expanded && children}
		</CardContainer>
	);
}

interface CardProps {
	simple?: boolean;
}
interface ChildrenProps {
	clickable?: boolean;
}

interface CardContainerProps extends CardProps {
	containerFlex?: string;
	noContainerBackground?: boolean;
}

const CardContainer = styled("div")(
	({ containerFlex }: CardContainerProps) => ({
		flex: containerFlex ?? "1 1 400px",
		display: "flex",
		flexDirection: "column",
		background: "#F7F8FC",
		border: "1px solid #EEEEEE",
		borderRadius: "5px",
		alignSelf: "flex-start",
	})
);

const CardHeader = styled("div")(({ clickable }: ChildrenProps) => ({
	display: "flex",
	gap: 16,
	alignItems: "center",
	padding: "0 1.5rem",
	minHeight: 76,
	cursor: clickable ? "pointer" : undefined,
}));

const CardText = styled("div")({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	flex: "1 1 200px",
	gap: 0,
});
