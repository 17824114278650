import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthAPI from "_api/AuthAPI";
import CustomSpinner from "_component/display/CustomSpinner";
import AppTheme from "AppTheme";

const LoginWrapper = styled("div")({
	height: "100%",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: AppTheme.palette.primary.light,
});

const Header = styled("div")({
	marginBottom: 24,
	width: "60%",
	backgroundColor: "rgba(255, 255, 255, 0.1)",
	padding: "16px 24px 0 24px",
	borderRadius: 12,
});

const Title = styled(Typography)({
	textAlign: "center",
	fontWeight: "bold",
	marginBottom: "1.5rem",
});

const Introduction = styled(Typography)({
	marginBottom: 12,
	textAlign: "justify",
});

const MicrosoftButton = styled(Button)({
	background: "white",
	padding: "12px 24px",
	fontSize: "1.25rem",
	textTransform: "none",
	borderRadius: 24,
	"&:hover": {
		background: "#f5f5f5",
		cursor: "pointer",
	},
});

export default function AdministrationLogin() {
	const location = useLocation();
	const navigate = useNavigate();

	const queryParams = new URLSearchParams(location.search);
	const code = queryParams.get("code");

	useEffect(() => {
		login();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const login = async () => {
		if (code) {
			await AuthAPI.login(code);
			navigate("/registracijos");
		}
	};

	const onLogin = () => {
		let redirectUri =
			process.env.NODE_ENV === "production"
				? "https%3A%2F%2Fregistracija.druskininkai.lt%2Flogin"
				: "http%3A%2F%2Flocalhost%3A3000%2Flogin";
		let clientId = "9532cfd5-9724-49c0-b793-c1f834af0556";
		let scopes = "offline_access%20user.read%20mail.read%20calendars.readwrite";
		let responseType = "code";
		let responseMode = "query";
		// must use href to access external url
		window.location.href = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&response_mode=${responseMode}&scope=${scopes}`;
	};

	return (
		<LoginWrapper>
			{code && <CustomSpinner size={"large"} withoutOuterDiv />}
			{!code && (
				<>
					<Header>
						<Title variant="h1">
							Sveiki atvykę į Druskininkų savivaldybės registracijos platformą
							darbuotojams!
						</Title>
						<Introduction>
							Šis inovatyvus debesijos pagrindu (SaaS) sukurtas sprendimas
							skirtas efektyviam savivaldybės paslaugų valdymui bei gyventojų
							aptarnavimo kokybės gerinimui. Čia galėsite naudotis išankstinės
							registracijos į konsultacijas pas specialistus sistema, kuri
							padeda optimaliai panaudoti žmogiškuosius resursus ir suteikia
							galimybę lengvai bei efektyviai iš anksto rezervuoti vizito laiką
							atvykimui į savivaldybės gyventojų aptarnavimo padalinį.
						</Introduction>
						<Introduction>
							Prašome prisijungti naudojantis savo Microsoft 365 prisijungimu,
							kad galėtumėte naudotis platforma ir valdyti savo darbo užduotis.
						</Introduction>
					</Header>
					<MicrosoftButton variant="outlined" onClick={() => onLogin()}>
						<img
							src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/320px-Microsoft_logo.svg.png"
							alt="Microsoft"
							width="24"
							height="24"
							style={{ marginRight: "8px" }}
						/>
						Prisijunkite su Microsoft 365
					</MicrosoftButton>
				</>
			)}
		</LoginWrapper>
	);
}
