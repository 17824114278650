import CustomAutoComplete from "_component/form/components/CustomAutoComplete";
import React from "react";

interface MultipleAutoCompleteProps {
	name: string;
	title: string;
	options: string[];
	value?: string[];
	translateOptionLabel?: (val: string) => string;
	show?: boolean;
	disabled?: boolean;
	multiple?: boolean;
	onValueChange?: (value: string | string[]) => void;
	getNewValue?: (
		newValue?: string | string[],
		oldValue?: string | string[]
	) => string | string[] | undefined;
	renderOptionStyle?: (value: string) => React.CSSProperties;
}
function MultipleAutoComplete(props: MultipleAutoCompleteProps) {
	const { show = true, multiple = true } = props;
	if (!show) {
		return null;
	}
	return (
		<CustomAutoComplete
			name={props.name}
			title={props.title}
			options={props.options}
			value={props.value}
			translateOptionLabel={props.translateOptionLabel}
			renderOptionStyle={props.renderOptionStyle}
			disabled={props.disabled}
			onValueChange={props.onValueChange}
			getNewValue={props.getNewValue}
			multiple={multiple}
			disableCloseOnSelect={multiple}
			textFieldProps={{
				placeholder: "",
			}}
			style={{
				maxWidth: "none",
			}}
			titleVariant={"row"}
			required
		/>
	);
}

export default React.memo(MultipleAutoComplete);
