import "./CustomHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as NaturalPersonAvatar } from "_asset/natural-person-avatar.svg";
import { AppBar, Box, Toolbar, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "_redux/hooks";
import ReduxCommonApi from "_redux/common/ReduxCommonApi";
import CustomButton from "../buttons/CustomButton";
import HeaderMenu from "./HeaderMenu";
import { translateRoleByName } from "_model/account/RoleResponse";

const CustomHeader = () => {
	const common = useAppSelector((state) => state.common);
	const me = useAppSelector((state) => state.me);
	const theme = useTheme();

	return (
		<AppBar position="static" style={{ position: "relative", zIndex: "1" }}>
			<Toolbar
				disableGutters
				className={"customHeader-main"}
				style={{
					justifyContent:
						common?.sideMenuIsPermanent || common?.sideMenuIsHidden
							? undefined
							: "space-between",
					backgroundColor: theme.palette.primary.light,
				}}
			>
				{!common?.sideMenuIsPermanent && !common?.sideMenuIsHidden && (
					<CustomButton
						variant={"text"}
						onClick={ReduxCommonApi.openSideMenu}
						disableRipple
					>
						<MenuIcon
							sx={{
								height: "32px",
								color: "black",
							}}
						/>
					</CustomButton>
				)}
				{me?.info && (
					<HeaderMenu>
						<NaturalPersonAvatar style={{ paddingRight: "0.5rem" }} />
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography variant={"h5"}>
								{`${me?.info?.name} ${me?.info?.lastName}`}
							</Typography>
							<Typography variant={"body"}>
								{me.info.roles
									.map((role) => translateRoleByName(role.name))
									.join(", ")}
							</Typography>
						</Box>
					</HeaderMenu>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default CustomHeader;
