import { store } from "../store";
import {
	addWidthListener,
	changeCurrentPathItem,
	changeSideMenuFlags,
	getAndSetRoles,
	getAndSetSettings,
} from "./CommonActions";
import PathItem from "_model/route/PathItem";

const ReduxCommonApi = {
	addWidthListener: () => store.dispatch(addWidthListener()),
	closeSideMenu: () => store.dispatch(changeSideMenuFlags(false)),
	openSideMenu: () => store.dispatch(changeSideMenuFlags(true)),
	changeSideMenuIsHidden: (isHidden: boolean) =>
		store.dispatch(changeSideMenuFlags(undefined, isHidden)),
	changeCurrentPathItem: (current?: PathItem) =>
		store.dispatch(changeCurrentPathItem(current)),
	getAndSetRoles: () => store.dispatch(getAndSetRoles()),
	getAndSetSettings: () => store.dispatch(getAndSetSettings()),
};

export default ReduxCommonApi;
