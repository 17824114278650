import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomIconButton from "../../buttons/CustomIconButton";
import DeleteConfirmationDialog from "../../dialog/templates/DeleteConfirmationDialog";
import { useState } from "react";
import { DeleteDialogProps } from "@recodin/fe-components";

interface Props {
	titleIcon: JSX.Element;
	handleDelete?: () => void;
	handleEdit?: () => void;
	deleteDialogProps?: DeleteDialogProps;
	disabled?: boolean;
}

export default function EditMenu(props: Props) {
	const { titleIcon, handleEdit, handleDelete, deleteDialogProps } = props;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openDelete, setOpenDelete] = useState<boolean>(false);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	if (props.disabled) {
		return null;
	}
	return (
		<div>
			<CustomIconButton
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				icon={titleIcon}
			/>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{handleEdit && (
					<MenuItem
						onClick={() => {
							handleEdit();
							handleClose();
						}}
					>
						Redaguoti
					</MenuItem>
				)}
				{handleDelete && deleteDialogProps && (
					<MenuItem
						onClick={() => {
							setOpenDelete(true);
							handleClose();
						}}
					>
						Ištrinti
					</MenuItem>
				)}
			</Menu>

			{handleDelete && deleteDialogProps && (
				<DeleteConfirmationDialog
					open={openDelete}
					handleClose={() => setOpenDelete(false)}
					handleDelete={handleDelete}
					{...deleteDialogProps}
				/>
			)}
		</div>
	);
}
