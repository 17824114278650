import "./CustomHeader.css";
import { Typography } from "@mui/material";
import { useAppSelector } from "_redux/hooks";

const CustomSubHeader = () => {
	const currentPathItem = useAppSelector((s) => s.common.currentPathItem);
	return (
		<>
			{currentPathItem?.title && (
				<Typography sx={{ mb: 2 }} variant={"h2"}>
					{currentPathItem?.title}
				</Typography>
			)}
		</>
	);
};

export default CustomSubHeader;
