import { Formik, FormikProps } from "formik";
import DownloadIcon from "@mui/icons-material/Download";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CustomDatePicker from "_component/form/components/CustomDatePicker";
import CustomButton from "_component/buttons/CustomButton";
import moment from "moment";
import CustomAutoComplete from "_component/form/components/CustomAutoComplete";
import ReportOptions, {
	allReportOptions,
	translateOption,
} from "./ReportOptions";
import { styled } from "@mui/material";
import * as yup from "yup";

interface ReportActionsProps {
	getReport: (from: moment.Moment, to: moment.Moment) => void;
	generateReport: (from: moment.Moment, to: moment.Moment) => void;
}

export default function ReportActions({
	getReport,
	generateReport,
}: ReportActionsProps) {
	return (
		<Formik
			initialValues={getInitialReportValues()}
			validationSchema={getReportValidationSchema()}
			onSubmit={(values: any) => console.log("Submitting:", values)}
		>
			{(formik: FormikProps<any>) => (
				<ReportActionsContainer>
					<FieldsContainer>
						<CustomAutoComplete
							label={"Laiko Tarpas"}
							name={"type"}
							options={allReportOptions}
							translateOptionLabel={translateOption}
							onValueChange={(value: string | string[]) => {
								if (typeof value === "string") {
									handleTypeChange(formik, value as ReportOptions);
								}
							}}
							renderFieldTitle={false}
							containerStyle={{ width: "fit-content", flex: "1 1 300px" }}
						/>
						{formik.values.type === ReportOptions.RANGE && (
							<DatesContainer>
								<CustomDatePicker
									label={"Nuo"}
									name={"from"}
									maxDate={moment(formik.values.to)}
								/>
								<CustomDatePicker
									label={"Iki"}
									name={"to"}
									minDate={moment(formik.values.from)}
								/>
							</DatesContainer>
						)}
					</FieldsContainer>
					<ButtonsContainer>
						<CustomButton
							variant={"contained"}
							onClick={() => onButtonClick(formik, getReport)}
							disabled={!formik.isValid || formik.isSubmitting}
							style={{
								gap: "5px",
								alignItems: "center",
							}}
						>
							<LeaderboardIcon />
							Atvaizduoti
						</CustomButton>
						<CustomButton
							variant={"outlined"}
							onClick={() => onButtonClick(formik, generateReport)}
							disabled={!formik.isValid || formik.isSubmitting}
							style={{
								gap: "5px",
								alignItems: "center",
							}}
						>
							<DownloadIcon />
							Atsisiųsti
						</CustomButton>
					</ButtonsContainer>
				</ReportActionsContainer>
			)}
		</Formik>
	);
}

const ReportActionsContainer = styled("div")({
	display: "flex",
	gap: "10px",
	flexWrap: "wrap",
});

const FieldsContainer = styled("div")({
	display: "flex",
	gap: "10px",
	flex: "1 0 400px",
	flexWrap: "wrap",
});

const DatesContainer = styled("div")({
	display: "flex",
	gap: "10px",
	flex: "1 0 300px",
});

const ButtonsContainer = styled("div")({
	display: "flex",
	gap: "10px",
	flex: "1 1 200px",
});

function getInitialReportValues() {
	return {
		type: ReportOptions.PAST_YEAR,
		from: moment().subtract(1, "year"),
		to: moment(),
	};
}

function getReportValidationSchema() {
	return yup.object({
		type: yup
			.string()
			.oneOf(allReportOptions, "Pasirinkite vieną iš nurodytų laiko tarpų.")
			.required("Laiko tarpas yra būtinas.")
			.nullable(),
		from: yup
			.date()
			.typeError("Įveskite pradžios datą formatu: YYYY-MM-DD")
			.nullable(),
		to: yup
			.date()
			.typeError("Įveskite pabaigos datą formatu: YYYY-MM-DD")
			.min(yup.ref("from"), 'Data "iki" negali būti ankstesnė negu data "nuo".')
			.nullable(),
	});
}

function onButtonClick(
	formik: FormikProps<any>,
	handler: (from: moment.Moment, to: moment.Moment) => void
) {
	if (formik.isSubmitting) {
		return;
	}
	formik.setSubmitting(true);
	handler(formik.values.from, formik.values.to);
	setTimeout(() => formik.setSubmitting(false), 400);
}

function handleTypeChange(formik: FormikProps<any>, current: ReportOptions) {
	function setFromAndToValues(from: moment.Moment, to: moment.Moment) {
		formik.setFieldValue("from", from);
		formik.setFieldValue("to", to, true);
	}
	switch (current) {
		case ReportOptions.PAST_YEAR:
			setFromAndToValues(moment().subtract(1, "year"), moment());
			break;
		case ReportOptions.THIS_YEAR:
			setFromAndToValues(moment().startOf("year"), moment());
			break;
		case ReportOptions.PAST_MONTH:
			setFromAndToValues(moment().subtract(1, "month"), moment());
			break;
		case ReportOptions.THIS_MONTH:
			setFromAndToValues(moment().startOf("month"), moment());
			break;
		case ReportOptions.RANGE:
			setFromAndToValues(moment(), moment().add(1, "day"));
	}
}
