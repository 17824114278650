import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";
import Topic from "_model/topic/Topic";

const adminUrl = baseEndpoints.topicAdmin;
const TopicAPIAdmin = {
	getAll: (): Promise<Topic[]> => axios.get(adminUrl),
	create: (data: Topic): Promise<Topic> => axios.post(adminUrl, data),
	update: (data: Topic): Promise<Topic> =>
		axios.put(`${adminUrl}/${data.id}`, data),
	delete: (id: number) => axios.delete(`${adminUrl}/${id}`),
};

const publicUrl = baseEndpoints.topicPublic;
const TopicAPIPublic = {
	getAll: (): Promise<Topic[]> => axios.get(publicUrl),
};

export { TopicAPIAdmin, TopicAPIPublic };
