import { SelectItem } from "_component/form/templates/FilterForm";
import { UserState } from "_redux/user/UserSlice";
import RoleResponse, { RoleNames } from "_model/account/RoleResponse";
import SettingResponse from "_model/setting/Setting";

function capitaliseFirstLetter(text: string): string {
	return text.replace(/^\w/, (c) => c.toUpperCase());
}

function getTrimmedText(text: string, numberOfCharsToShow: number): string {
	return (
		capitaliseFirstLetter(text.slice(0, numberOfCharsToShow)) +
		(text.length <= numberOfCharsToShow ? "" : "...")
	);
}

const customParseInt = (
	number: number | string | undefined | null
): number | undefined => {
	if (!number || number === "") {
		return undefined;
	} else if (typeof number === "string") {
		return parseInt(number, 10);
	} else {
		return number;
	}
};

const downloadBlob = (blob: Blob, fileName: string = "default-name.pdf") => {
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = fileName;
	a.click();
};

const isNumeric = (value?: string): boolean => {
	return (
		value !== undefined &&
		!isNaN(parseFloat(value)) &&
		isFinite(parseFloat(value))
	);
};

function translateSelectItem(
	allItems: SelectItem[],
	value: string,
	nullable?: boolean
): string {
	const result = allItems.find((type) => type.value === value)?.label;
	if (result) {
		return result;
	}
	return nullable ? "" : "N/A";
}

const searchInArray = (array: any[], searchPredicate: string) => {
	return array.filter((item: any) =>
		(Object.values(item) as any[])
			.reduce((prev: any, current: any) => {
				if (typeof current == "string") return prev + " " + current;
				if (typeof current == "object" && current) {
					return (
						prev + Object.values(current).reduce((p: any, c: any) => p + c, " ")
					);
				}
				return prev + " " + current;
			}, " ")
			.toLowerCase()
			.includes(searchPredicate.toLowerCase())
	);
};

function DynamicalAPIGetter(
	me?: UserState,
	adminAPI?: any,
	managerAPI?: any,
	specialistAPI?: any,
	publicAPI?: any
) {
	if (hasRole(RoleNames.ADMIN, me?.info?.roles)) {
		return adminAPI;
	} else if (hasRole(RoleNames.MANAGER, me?.info?.roles)) {
		return managerAPI;
	} else if (hasRole(RoleNames.SPECIALIST, me?.info?.roles)) {
		return specialistAPI;
	} else {
		return publicAPI;
	}
}

function hasRole(role: RoleNames, roles?: RoleResponse[]): boolean {
	return (
		roles !== undefined && roles.some((userRole) => userRole.name === role)
	);
}

function getSettingByName(
	name: string,
	settings?: SettingResponse[]
): SettingResponse | undefined {
	return settings?.find((setting) => setting.name === name);
}

export {
	getTrimmedText,
	capitaliseFirstLetter,
	downloadBlob,
	customParseInt,
	isNumeric,
	translateSelectItem,
	searchInArray,
	DynamicalAPIGetter,
	hasRole,
	getSettingByName,
};
