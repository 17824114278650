import { useAppSelector } from "_redux/hooks";
import { Navigate } from "react-router-dom";
import { RoleNames } from "_model/account/RoleResponse";
import { hasRole } from "_util/UtilFunctions";

interface Props {
	forSpecialist?: boolean;
	forManager?: boolean;
	forAdmin?: boolean;
	forObserver?: boolean;
	forPublic?: boolean;
	children: any;
}

export default function RequireAuth(props: Props) {
	const me = useAppSelector((s) => s.me);

	if (!me?.info && !props?.forPublic) {
		// If there is no user data, then don't show anything
		return null;
	}

	if (
		(props.forAdmin && hasRole(RoleNames.ADMIN, me.info?.roles)) ||
		(props.forManager && hasRole(RoleNames.MANAGER, me.info?.roles)) ||
		(props.forSpecialist && hasRole(RoleNames.SPECIALIST, me.info?.roles)) ||
		(props.forObserver && hasRole(RoleNames.OBSERVER, me.info?.roles)) ||
		props?.forPublic
	) {
		// If user has sufficient privileges for this page, then show content
		return <>{props.children}</>;
	}

	return <Navigate to={"/login"} replace />;
}
