import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
	updateWidth,
	updateSideMenuFlags,
	updateCurrentPathItem,
	updateRoles,
	updateSettings,
} from "./CommonSlice";
import PathItem from "_model/route/PathItem";
import RoleResponse from "_model/account/RoleResponse";
import { AccountAPIAdmin } from "_api/AccountAPI";
import { SettingAPIPublic } from "_api/SettingAPI";
import SettingResponse from "_model/setting/Setting";

export const addWidthListener =
	(): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
		window.addEventListener("resize", () => {
			dispatch(
				updateWidth({
					innerWidth: window.innerWidth,
					outerWidth: window.outerWidth,
				})
			);
		});
	};

export const changeSideMenuFlags =
	(
		isOpen?: boolean,
		isHidden?: boolean
	): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(
			updateSideMenuFlags({
				sideMenuIsHidden: isHidden,
				sideMenuIsOpen: isOpen,
			})
		);
	};

export const changeCurrentPathItem =
	(current?: PathItem): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(
			updateCurrentPathItem({
				currentPathItem: current,
			})
		);
	};

export const getAndSetRoles =
	(): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch) => {
		AccountAPIAdmin.getAllRoles().then((roles: RoleResponse[]) => {
			dispatch(updateRoles({ allAccountRoles: roles }));
		});
	};

export const getAndSetSettings =
	(): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch) => {
		SettingAPIPublic.getAll().then((settings: SettingResponse[]) => {
			dispatch(updateSettings({ settings: settings }));
		});
	};
