import "./CustomHeader.css";
import { MenuItem, MenuItemProps, Typography } from "@mui/material";
import React from "react";
import AppTheme from "AppTheme";

interface MenuItemStyleProps extends MenuItemProps {
	LeftIcon?: JSX.Element;
	text: string;
	subText?: string;
	RightIcon?: JSX.Element;
	onClick: () => void;
	style?: React.CSSProperties;
}

export default function HeaderMenuItem(props: MenuItemStyleProps) {
	const { LeftIcon, text, subText, RightIcon, onClick, style, ...other } =
		props;
	return (
		<MenuItem
			{...other}
			onClick={() => onClick()}
			style={{
				display: "flex",
				alignItems: "center",
				gap: "6px",
				...style,
			}}
		>
			{LeftIcon !== undefined && (
				<div className={"account-menu-left-icon"}>
					{LeftIcon !== null ? LeftIcon : " "}
				</div>
			)}
			<div className={"account-menu-text-container"}>
				<Typography variant={"body"} color={AppTheme.palette.text.primary}>
					{text}
				</Typography>
				<Typography
					variant={"subtitle"}
					color={AppTheme.palette.text.secondary}
				>
					{subText}
				</Typography>
			</div>
			{RightIcon !== undefined && (
				<div className={"account-menu-right-icon"}>{RightIcon}</div>
			)}
		</MenuItem>
	);
}
