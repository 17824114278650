import { baseEndpoints } from "_constant/baseEndpoints";
import axios from "axios";
import SettingResponse from "_model/setting/Setting";

const adminUrl = baseEndpoints.settingAdmin;
export const SettingAPIAdmin = {
	update: (data: SettingResponse): Promise<SettingResponse> =>
		axios.put(`${adminUrl}/${data.id}`, data),
};

const publicUrl = baseEndpoints.settingPublic;
export const SettingAPIPublic = {
	getAll: (): Promise<SettingResponse[]> => axios.get(`${publicUrl}`),
};
