import { IconButton, IconButtonProps } from "@mui/material";

interface Props extends IconButtonProps {
	icon: JSX.Element;
}

export default function CustomIconButton(props: Props) {
	const { icon, ...other } = props;

	return <IconButton {...other}>{icon}</IconButton>;
}
