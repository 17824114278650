import { Box } from "@mui/material";
import CustomHeader from "_component/header/CustomHeader";
import CustomSubHeader from "_component/header/CustomSubHeader";
import CustomSideMenu from "_component/sidemenu/CustomSideMenu";
import ReduxCommonApi from "_redux/common/ReduxCommonApi";
import { useAppSelector } from "_redux/hooks";
import ReduxUserApi from "_redux/user/ReduxUserApi";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export default function AdminLayout() {
	const me = useAppSelector((s) => s.me);

	useEffect(() => {
		ReduxCommonApi.getAndSetRoles();
		ReduxUserApi.getAndSetMe();
	}, []);

	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			{me?.info && <CustomHeader />}
			<Box sx={{ display: "flex", overflowY: "hidden", height: "100%" }}>
				<CustomSideMenu />
				<div className={"customRouter-main-container"}>
					<CustomSubHeader />
					<Outlet />
				</div>
			</Box>
		</div>
	);
}
