import errorMessageBase from "_constant/errorBaseConstants";

const ErrorTranslations = {
	// Account errors
	[`${errorMessageBase.account}.find.notFound`]:
		"Įvyko klaida bandant atrasti paskyrą, kurios numeris {{id}}",
	[`${errorMessageBase.account}.create.duplicate`]:
		"Paskyra, kurios el. paštas {{email}}, jau egzistuoja.",
	[`${errorMessageBase.account}.create.departments.empty`]:
		"Kuriama skyriaus vadovo paskyra neturi parinktų vadovaujamų skyrių.",
	[`${errorMessageBase.account}.create.departments.notFound`]:
		"Įvyko klaida bandant rasti vadovaujamąjį skyrių numeriu {{id}}.",
	[`${errorMessageBase.account}.create.topics.empty`]:
		"Kuriama specialisto paskyra neturi parinktų konsultuojamų temų.",
	[`${errorMessageBase.account}.create.topic.notFound`]:
		"Įvyko klaida bandant rasti konsultuojamą temą numeriu {{id}}.",
	[`${errorMessageBase.account}.create.receptionPoint.null`]:
		"Kuriama specialisto paskyra neturi parinkto priėmimo taško.",
	[`${errorMessageBase.account}.create.receptionPoint.notFound`]:
		"Įvyko klaida bandant rasti priėmimo tašką numeriu {{id}}.",

	[`${errorMessageBase.account}.update.notFound`]:
		"Įvyko klaida bandant atrasti paskyrą, kurios el. paštas {{email}}",
	[`${errorMessageBase.account}.update.departments.empty`]:
		"Kuriama skyriaus vadovo paskyra neturi parinktų vadovaujamų skyrių.",
	[`${errorMessageBase.account}.update.departments.notFound`]:
		"Įvyko klaida bandant rasti vadovaujamąjį skyrių numeriu {{id}}.",
	[`${errorMessageBase.account}.update.topics.empty`]:
		"Kuriama specialisto paskyra neturi parinktų konsultuojamų temų.",
	[`${errorMessageBase.account}.update.topic.notFound`]:
		"Įvyko klaida bandant rasti konsultuojamą temą numeriu {{id}}.",
	[`${errorMessageBase.account}.update.receptionPoint.null`]:
		"Kuriama specialisto paskyra neturi parinkto priėmimo taško.",
	[`${errorMessageBase.account}.update.receptionPoint.notFound`]:
		"Įvyko klaida bandant rasti priėmimo tašką numeriu {{id}}.",

	[`${errorMessageBase.account}.delete.notFound`]:
		"Įvyko klaida bandant atrasti paskyrą, kurios numeris {{id}}",
	[`${errorMessageBase.account}.randomSpecialist.workTimes.empty`]:
		"Įvyko klaida bandant paskirti specialistą. Prašome pabandyti dar kartą",

	// Work-time errors
	[`${errorMessageBase.workTime}.find.notFound`]:
		"Įvyko klaida bandant atrasti konsultavimo laiką, kurio numeris {{id}}",
	[`${errorMessageBase.workTime}.create.conflict`]:
		"Įvyko klaida bandant sukurti konsultavimo laiką {{additionalStart}}-{{additionalEnd}}, nes jau egzistuoja konsultavimo laikas {{currentStart}}-{{currentEnd}}",
	[`${errorMessageBase.workTime}.delete.notFound`]:
		"Įvyko klaida bandant atrasti konsultavimo laiką, kurio numeris {{id}}",
	[`${errorMessageBase.workTime}.delete.used`]:
		"Negalima ištrinti konsultavimo laiko, kuriam sukurta registracija.",

	// Auth errors
	[`${errorMessageBase.auth}.invalidCode`]:
		"Įvyko klaida bandant prisijungti, gautas neteisingas kodas.",
	[`${errorMessageBase.auth}.invalidToken`]:
		"Įvyko klaida bandant prisijungti, nepavyko gauti paskyros informaciją",
	[`${errorMessageBase.auth}.accountDoesNotSupportOutlook`]:
		"Įvyko klaida bandant prisijungti, paskyra nepalaiko Outlook prisijungimo.",
	[`${errorMessageBase.auth}.notActive`]:
		"Įvyko klaida bandant prisijungti, paskyra nėra aktyvuota. Prašome prisijungti per MS Outlook.",

	// Department errors
	[`${errorMessageBase.department}.find.notFound`]:
		"Įvyko klaida bandant rasti skyrių numeriu {{id}}.",
	[`${errorMessageBase.department}.create.duplicate`]:
		"Įvyko klaida bandant sukurti skyrių {{name}}, tokio pavadinimo skyrius jau egzistuoja.",
	[`${errorMessageBase.department}.delete.notFound`]:
		"Įvyko klaida bandant rasti skyrių numeriu {{id}}.",

	// Email errors
	[`${errorMessageBase.email}.find.notFound`]:
		"Įvyko klaida bandant rasti el. paštą numeriu {{id}}.",
	[`${errorMessageBase.email}.create.duplicate`]:
		'El.laiškas tema "{{subject}}" jau egzistuoja ir buvo išsiųstas {{dateSent}}.',
	[`${errorMessageBase.email}.sendingError`]:
		"Įvyko klaida bandant išsiųsti el. laišką.",

	// EmailTemplate errors
	[`${errorMessageBase.emailTemplate}.find.notFound`]:
		"Įvyko klaida bandant rasti el. laiško šabloną numeriu {{id}}.",
	[`${errorMessageBase.emailTemplate}.find.notFound`]:
		"Įvyko klaida bandant rasti el. laiško šabloną tipu {{type}}.",
	[`${errorMessageBase.emailTemplate}.create.duplicate`]:
		'El.laiško šablonas pavadinimu "{{name}}" jau egzistuoja.',
	[`${errorMessageBase.emailTemplate}.delete.notFound`]:
		"Įvyko klaida bandant rasti el. laiško šabloną numeriu {{id}}.",

	// Reception point errors
	[`${errorMessageBase.receptionPoint}.find.notFound`]:
		"Įvyko klaida bandant rasti priėmimo tašką numeriu {{id}}.",
	[`${errorMessageBase.receptionPoint}.create.duplicate`]:
		"Įvyko klaida bandant sukurti priėmimo tašką, kurio pavadinimu {{name}} ir adresu {{address}}, tokio pavadinimo ir adreso priėmimo taškas jau egzistuoja.",
	[`${errorMessageBase.receptionPoint}.delete.notFound`]:
		"Įvyko klaida bandant rasti priėmimo tašką numeriu {{id}}.",

	// Registration errors
	[`${errorMessageBase.registration}.find.notFound`]:
		"Įvyko klaida bandant rasti registraciją numeriu {{id}}.",
	[`${errorMessageBase.registration}.unauthorized`]:
		"Registraciją numeriu {{registrationId}} nėra pasiekiama Jūsų paskyrai.",
	[`${errorMessageBase.registration}.find.notFound`]:
		"Įvyko klaida bandant rasti registraciją UUID numeriu {{uuid}}.",
	[`${errorMessageBase.registration}.create.conflict`]:
		"Įvyko klaida bandant sukurti registraciją, nes jos konsultavimo laikas (numeris = {{workTimeId}}) jau rezervuotas.",
	[`${errorMessageBase.registration}.create.worktime.used`]:
		"Registracijos konsultavimo laikas, kurio numeris {{id}}, yra jau naudojamas.",
	[`${errorMessageBase.registration}.create.worktime.isInPast`]:
		"Negalima sukurti registracijos nebegaliojančiui konsultavimo laikui {{start}}.",
	[`${errorMessageBase.registration}.create.worktime.cantCreateForToday`]:
		"Negalima sukurti registracijos šiandienos konsultavimo laikui {{start}}.",
	[`${errorMessageBase.registration}.create.emailIsNotVerified`]:
		"Registracijoje naudojamas {{email}} el. paštas nėra patvirtintas.",
	[`${errorMessageBase.registration}.update.notFound`]:
		"Įvyko klaida bandant rasti registraciją numeriu {{id}}.",
	[`${errorMessageBase.registration}.update.worktime.notFound`]:
		"Registracijos naujas konsultavimo laikas, kurio numeris {{id}}, neegzistuoja.",
	[`${errorMessageBase.registration}.update.worktime.used`]:
		"Registracijos naujas konsultavimo laikas, kurio numeris {{id}}, yra jau naudojamas.",
	[`${errorMessageBase.registration}.delete.notFound`]:
		"Įvyko klaida bandant rasti registraciją numeriu {{id}}.",
	[`${errorMessageBase.registration}.delete.eventIdNotFound`]:
		"Įvyko klaida bandant rasti registracijos numeriu {{id}} Microsoft Outlook įvykį.",

	// Comment errors
	[`${errorMessageBase.comment}.find.notFound`]:
		"Įvyko klaida bandant rasti komentarą numeriu {{id}}.",
	[`${errorMessageBase.comment}.create.invalidOwner`]:
		"Įvyko klaida bandant rasti komentarą numeriu {{id}}.",
	[`${errorMessageBase.comment}.delete.notFound`]:
		"Įvyko klaida bandant rasti komentarą numeriu {{id}}.",

	// Reporting errors
	[`${errorMessageBase.reporting}.generate.invalidFileType`]:
		"Įvyko klaida bandant sukurti ataskaitą plėtiniu {{fileType}}.",
	[`${errorMessageBase.reporting}.generate.failed`]:
		"Įvyko klaida bandant sukurti ataskaitą.",

	// Topic errors
	[`${errorMessageBase.topic}.find.notFound`]:
		"Įvyko klaida bandant rasti temą numeriu {{id}}.",
	[`${errorMessageBase.topic}.find.notFound`]:
		"Įvyko klaida bandant rasti temą tipu {{type}}.",
	[`${errorMessageBase.topic}.create.duplicate`]:
		'Tema pavadinimu "{{name}}" jau egzistuoja.',
} as ErrorTranslationValue;

export interface ErrorTranslationValue {
	[key: string]: string;
}

export default ErrorTranslations;
