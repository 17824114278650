import "./Main.css";
import { useEffect } from "react";
import ReduxCommonApi from "_redux/common/ReduxCommonApi";
import { Route, Routes, useLocation } from "react-router-dom";
import { commonConstants } from "_constant/commonConstants";
import routes from "_constant/routes";
import PathItem from "_model/route/PathItem";
import { useAppSelector } from "_redux/hooks";
import RequireAuth from "_services/RequireAuth";
import CustomAlert from "_component/alert/CustomAlert";

function Main() {
	const location = useLocation();
	const allCurrentErrors = useAppSelector((state) => state.error.alerts);
	const allPathItems = routes.flatMap((group) => group.paths);
	const me = useAppSelector((s) => s.me);

	useEffect(() => {
		ReduxCommonApi.addWidthListener();
		ReduxCommonApi.getAndSetSettings();
	}, []);

	useEffect(() => {
		const maybePath = getCurrentPathItem(location.pathname);
		if (maybePath) {
			ReduxCommonApi.changeCurrentPathItem(maybePath);
		}
		ReduxCommonApi.changeSideMenuIsHidden(hideSideMenu(maybePath));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, me.info]);

	const hideSideMenu = (maybePath?: PathItem): boolean => {
		return (
			!me?.info ||
			!maybePath ||
			commonConstants.doNotShowSideMenuFor.includes(maybePath?.path)
		);
	};

	const getCurrentPathItem = (path: string): PathItem | undefined => {
		const getSplitPath = (pathString: string): string[] =>
			pathString.split("/").filter((s) => s !== "");

		for (const item of allPathItems) {
			const splitItemPath = getSplitPath(item.path);
			const splitGivenPath = getSplitPath(path);

			if (splitItemPath.length !== splitGivenPath.length) {
				// Not this one
				continue;
			}

			let found = true;
			for (let i = 0; i < splitItemPath.length; i++) {
				if (
					!splitItemPath.at(i)?.includes(":") &&
					splitItemPath.at(i) !== splitGivenPath.at(i)
				) {
					// value between dashes / do not match
					found = false;
					break;
				}
			}

			if (found) {
				return item;
			}
		}
	};

	return (
		<div className={"main-container"}>
			{allCurrentErrors.length > 0 && (
				<>
					{allCurrentErrors.map((error) => (
						<CustomAlert key={error.message} alertValue={error} />
					))}
				</>
			)}
			<Routes>
				{routes.map((group) => (
					<Route key={group.label} element={group.layout}>
						{group.paths
							.filter((item) => !item.isLink)
							.map((item) => (
								<Route
									key={item.path}
									path={item.path}
									element={
										item?.forPublic ? (
											item.page
										) : (
											<RequireAuth {...item}>{item.page}</RequireAuth>
										)
									}
								/>
							))}
					</Route>
				))}
			</Routes>
		</div>
	);
}

export default Main;
