import { useEffect, useState } from "react";
import { CustomTable } from "@recodin/fe-components";
import {
	DepartmentTableHeadRow,
	RegistrationsTableRow,
} from "./RegistrationsTableRow";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import { hasRole, translateSelectItem } from "_util/UtilFunctions";
import {
	FilterRequest,
	SelectItem,
} from "_component/form/templates/FilterForm";
import Topic from "_model/topic/Topic";
import { TopicAPIAdmin } from "_api/TopicAPI";
import { SearchRequest } from "_component/form/templates/SearchForm";
import { RegistrationAPIAdmin } from "_api/RegistrationAPI";
import {
	findRegistrationStatus,
	registrationStatusSelectValues,
} from "_model/registration/RegistrationStatus";
import { useAppSelector } from "_redux/hooks";
import { RoleNames } from "_model/account/RoleResponse";

export default function RegistrationsPage() {
	const me = useAppSelector((state) => state.me.info);
	const [registrations, setRegistrations] = useState<RegistrationResponse[]>(
		[]
	);
	const [topics, setTopics] = useState<Topic[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const topicSelectItems = topics.map(
		(item): SelectItem => ({ value: `${item.id}`, label: item.name })
	);

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function init() {
		setLoading(true);
		setRegistrations(await RegistrationAPIAdmin.getAllFiltered());
		setTopics(await TopicAPIAdmin.getAll());
		setLoading(false);
	}
	async function handleDataFetch(
		search?: SearchRequest,
		filter?: FilterRequest
	) {
		setLoading(true);
		setRegistrations(await RegistrationAPIAdmin.getAllFiltered(filter));
		setLoading(false);
	}
	async function handleDelete(data: RegistrationResponse) {
		if (data?.id) {
			await RegistrationAPIAdmin.delete(data?.id);
			await handleDataFetch();
		}
	}
	async function handleDownload(
		search?: SearchRequest,
		filter?: FilterRequest
	): Promise<Blob> {
		return await RegistrationAPIAdmin.generateReport(search, filter);
	}

	return (
		<div>
			<CustomTable
				data={registrations}
				isLoading={loading}
				headerRow={DepartmentTableHeadRow}
				contentRow={(props: { row: RegistrationResponse }) => (
					<RegistrationsTableRow
						row={props.row}
						handleDelete={handleDelete}
						disableDelete={hasRole(RoleNames.OBSERVER, me?.roles)}
					/>
				)}
				handleDownload={handleDownload}
				handleSearchAndFilter={handleDataFetch}
				filterFormProps={{
					display: {
						fromDate: true,
						toDate: true,
					},
					selectItems: {
						topics: topicSelectItems,
						statuses: registrationStatusSelectValues,
					},
					selectItemsTranslation: {
						topic: (val: string) =>
							translateSelectItem(topicSelectItems, val, true),
						status: (val: string) => findRegistrationStatus(val)?.label ?? "",
					},
					preferences: {
						fromDateIsDateTime: true,
						toDateIsDateTime: true,
					},
					labels: {
						statusLabel: "Statusas",
					},
				}}
			/>
		</div>
	);
}
