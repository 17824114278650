import { createSlice } from "@reduxjs/toolkit";
import { createNewError } from "./errorUtil";

export interface ErrorValue {
	isOpen: boolean;
	message: string;
	alertSeverity: "success" | "info" | "warning" | "error";
	alertType: "banner" | "toast";
	actionTitle?: string;
	alertAction?: () => void;
	hasCloseButton?: boolean;
}

export interface ErrorState {
	alerts: ErrorValue[];
}

const initialState: ErrorState = {
	alerts: [],
};

const errorSlice = createSlice({
	name: "error",
	initialState: initialState,
	reducers: {
		addError(state, action) {
			const newError: ErrorValue = createNewError(
				true,
				action.payload.message,
				action.payload.alertSeverity,
				action.payload.alertType,
				action.payload.actionTitle,
				action.payload.alertAction
			);
			const oldErrors = state.alerts as ErrorValue[];

			if (oldErrors.length === 0) {
				state.alerts = [newError];
				return;
			}

			let alreadyExists = 0;
			oldErrors.forEach((alert) => {
				if (
					alert.alertType === newError.alertType &&
					alert.message === newError.message
				) {
					alreadyExists++;
					return;
				}
			});

			if (alreadyExists === 0) {
				state.alerts = [...oldErrors, newError];
			} else {
				state.alerts = oldErrors;
			}
		},
		removeError(state, action) {
			state.alerts = state.alerts.filter(
				(alert) => alert.message !== action.payload.message
			);
		},
		hideError(state, action) {
			state.alerts.forEach((alert) => {
				if (
					alert.message === action.payload.message &&
					alert.alertSeverity === action.payload.alertSeverity
				) {
					alert.isOpen = false;
				}
			});
		},
		clearAllErrors: (state) => {
			state.alerts = [];
		},
	},
});

export const { addError, removeError, hideError, clearAllErrors } =
	errorSlice.actions;
export default errorSlice.reducer;
