import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useContext, useState } from "react";
import EmailTemplate from "_model/emailtemplate/EmailTemplate";
import CustomTextField from "_component/form/components/CustomTextField";
import {
	allEmailTypes,
	translateEmailType,
} from "_model/emailtemplate/EmailType";
import CustomAutoComplete from "_component/form/components/CustomAutoComplete";
import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";

interface Props {
	emailTemplate?: EmailTemplate;
	handleEdit: (emailTemplate: EmailTemplate) => void;
}

export default function EmailTemplateEditForm(props: Props) {
	const { emailTemplate = getDefaultValues(), handleEdit } = props;

	const [preview, setPreview] = useState<boolean>(false);
	const { formikFormRef } = useContext(FormContext);
	const allTypes = allEmailTypes.map((type) => type.value);

	const handleFormikSubmit = (values: any) => {
		handleEdit(values as EmailTemplate);
	};

	return (
		<Formik
			initialValues={emailTemplate}
			validationSchema={validationSchema(allTypes)}
			onSubmit={handleFormikSubmit}
			innerRef={formikFormRef} // used for submit outside Formik component
		>
			{(formik: FormikProps<any>) => (
				<>
					{/* Updates formContext, which is used in outside dialog */}
					<FormContextRefreshConduit />

					<CustomTextField title={"Tema"} name={"subject"} required />
					<CustomAutoComplete
						title={"Tipas"}
						name={"type"}
						options={allTypes}
						translateOptionLabel={translateEmailType}
					/>
					<CustomTextField
						title={"Turinys"}
						name={"body"}
						required
						titleButtonAction={() => setPreview(!preview)}
						titleButtonLabel={preview ? "Kodas" : "Peržiūra"}
						hideInput={preview}
						showInstead={
							<p
								dangerouslySetInnerHTML={{
									__html: preview ? formik.values.body : undefined,
								}}
							/>
						}
						multiline
						rows={10}
					/>
				</>
			)}
		</Formik>
	);
}

const getDefaultValues = () => ({
	id: undefined,
	subject: "",
	body: "",
	type: null,
});

const validationSchema = (allTypes: string[]) =>
	yup.object({
		type: yup
			.string()
			.oneOf(allTypes, "Pasirinkite vieną iš nurodytų tipų")
			.required("Laiško tipas yra privalomas.")
			.nullable(),
		subject: yup.string().required("Tema yra privaloma."),
		body: yup.string().required("Turinys yra privalomas."),
	});
