import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";

interface CustomTooltipProps {
	children: any;
	title?: string;
	hide?: boolean;
}

const CustomTooltip = ({ title, hide, children }: CustomTooltipProps) => {
	return (
		<Tooltip
			title={hide ? undefined : title}
			TransitionComponent={Zoom}
			arrow
			followCursor={true}
		>
			{children}
		</Tooltip>
	);
};

export default CustomTooltip;
