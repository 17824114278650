import { Box, Typography } from "@mui/material";
import { cloneElement } from "react";

interface Props {
	text?: string;
	subtext?: string;
	icon?: any;
	bolded?: boolean;
}

export default function IconText({ text, subtext, icon, bolded }: Props) {
	return (
		<Box style={{ display: "flex", alignItems: "center" }}>
			{cloneElement(icon, {
				height: 32,
				width: 32,
			})}
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Typography
					variant="h4"
					style={{ marginLeft: "0.5rem", fontWeight: bolded ? 800 : undefined }}
				>
					{text}
				</Typography>
				<Typography
					variant="h4"
					style={{ marginLeft: "0.5rem", fontWeight: 300 }}
				>
					{subtext}
				</Typography>
			</Box>
		</Box>
	);
}
