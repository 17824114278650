import ReportActions from "./ReportActions";
import { useState } from "react";
import { CustomTable } from "@recodin/fe-components";
import RegistrationReport from "_model/report/RegistrationReport";
import {
	RegistrationReportTableHeadRow,
	RegistrationsTableRow,
} from "./RegistrationReportTableRow";
import { RegistrationAPIAdmin } from "_api/RegistrationAPI";
import moment from "moment";
import { getDate } from "_util/DateUtil";
import { downloadBlob } from "_util/UtilFunctions";

export default function ReportPage() {
	const [registrationReports, setRegistrationReports] =
		useState<RegistrationReport[]>();
	const [loading, setLoading] = useState<boolean>(false);

	async function generateReport(from: moment.Moment, to: moment.Moment) {
		const file: Blob = await RegistrationAPIAdmin.generateXlsxReport(from, to);
		downloadBlob(file, `ataskaita-(${getDate(from)}-${getDate(to)}).xlsx`);
	}
	async function getReport(from: moment.Moment, to: moment.Moment) {
		setLoading(true);
		setRegistrationReports(await RegistrationAPIAdmin.getReports(from, to));
		setLoading(false);
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<ReportActions getReport={getReport} generateReport={generateReport} />
			{registrationReports && (
				<CustomTable
					data={registrationReports}
					isLoading={loading}
					headerRow={RegistrationReportTableHeadRow}
					contentRow={RegistrationsTableRow}
					disableSearching
					disableFiltering
				/>
			)}
		</div>
	);
}
