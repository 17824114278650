import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface Props extends ButtonProps {
	children: any;
	size?: "small" | "medium" | "large";
	variant?: "contained" | "outlined" | "text";
	color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
}

export default function CustomButton(props: Props) {
	const {
		children,
		size = "large",
		variant = "contained",
		color,
		...other
	} = props;

	return (
		<Button
			color={color ?? "primary"}
			size={size}
			variant={variant}
			fullWidth={false}
			style={{
				textTransform: "none",
				padding: "1rem 0.5rem",
			}}
			{...other}
		>
			{children}
		</Button>
	);
}
