const errorMessageBase = {
	account: "err.account",
	workTime: "err.account.worktime",
	auth: "err.login",
	department: "err.department",
	email: "err.email",
	emailTemplate: "err.emailtemplate",
	receptionPoint: "err.receptionpoint",
	registration: "err.registration",
	comment: "err.registration.comment",
	reporting: "err.reporting",
	topic: "err.topic",
};

export const defaultErrorMessage =
	"Įvyko klaida, prašome susisiekti su svetainės administratoriais.";

export default errorMessageBase;
