import axios, { AxiosError } from "axios";
import { mapErrorToAlert } from "_redux/error/errorUtil";
import ReduxErrorApi from "_redux/error/ReduxErrorApi";
import { mapAxiosErrorToIError } from "./axiosInterceptorsUtil";

export default function setupAxiosInterceptors() {
	const handleError = (error: AxiosError) => {
		if (error.response?.status === 401 || error.response?.status === 403) {
			window.location.assign("/login");
		} else {
			const responseError = mapAxiosErrorToIError(error);
			ReduxErrorApi.add(mapErrorToAlert(responseError));
		}
		return Promise.reject(error);
	};

	axios.interceptors.response.use((response) => response.data, handleError);
}
