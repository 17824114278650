import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";
import { FilterRequest } from "_component/form/templates/FilterForm";
import EmailResponse from "_model/email/EmailResponse";

const adminUrl = baseEndpoints.emailAdmin;
const EmailAPIAdmin = {
	getAllFiltered: (filter?: FilterRequest): Promise<EmailResponse[]> =>
		axios.get(`${adminUrl}/filtered`, {
			params: {
				status: filter?.status ?? null,
				type: filter?.type ?? null,
			},
		}),
};

export { EmailAPIAdmin };
