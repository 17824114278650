import "./Sections.css";
import CollapsableSectionDisplay from "_component/display/CollapsableSectionDisplay";
import { useEffect, useState } from "react";
import {
	WorkDayTableHeadRow,
	WorkDayTableRow,
} from "../table/WorkTimeTableRow";
import { workTimeCreateProps } from "../forms/SpecialistTimeDialogProps";
import AccountResponse from "_model/account/AccountResponse";
import { AccountAPIAdmin } from "_api/AccountAPI";
import WorkTimeCreateRequest from "_model/account/WorkTimeCreateRequest";
import WorkDay from "_model/account/WorkDay";
import { RoleNames } from "_model/account/RoleResponse";
import { hasRole } from "_util/UtilFunctions";
import { CustomTable } from "@recodin/fe-components";

interface Props {
	account?: AccountResponse;
}

export default function WorkTimeInfoSection({ account }: Props) {
	const roles = account?.roles ?? [];

	const [loading, setLoading] = useState<boolean>(false);
	const [workTimes, setWorkTimes] = useState<WorkDay[]>(
		account?.workDays ?? []
	);
	const [weekdays, setWeekdays] = useState<string[]>([]);

	useEffect(() => {
		getWorkTimes();
		// eslint-disable-next-line
	}, []);

	async function getWorkTimes() {
		if (!account?.id) return;
		setLoading(true);
		setWorkTimes(await AccountAPIAdmin.getAllFilteredWorkTimes(account?.id));
		setWeekdays(await AccountAPIAdmin.getAllWeekdays());
		setLoading(false);
	}

	async function handleCreate(request: WorkTimeCreateRequest) {
		await AccountAPIAdmin.createWorkTimes(request, account?.id);
		getWorkTimes();
	}

	async function handleDelete(id?: number) {
		await AccountAPIAdmin.deleteWorkTime(account?.id, id);
		getWorkTimes();
	}

	if (!hasRole(RoleNames.SPECIALIST, roles)) {
		// Don't show this section for admin and manager account
		return null;
	}
	return (
		<CollapsableSectionDisplay
			title={"Konsultacijos laikų informacija"}
			initiallyOpen
		>
			<CustomTable
				data={workTimes}
				isLoading={loading}
				headerRow={WorkDayTableHeadRow}
				contentRow={({ row }: { row: WorkDay }) => (
					<WorkDayTableRow row={row} handleDelete={handleDelete} />
				)}
				createProps={workTimeCreateProps(handleCreate, weekdays)}
				disableFiltering
				disableSearching
			/>
		</CollapsableSectionDisplay>
	);
}
