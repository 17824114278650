import { Typography, TypographyProps } from "@mui/material";

interface FieldErrorMessageProps extends TypographyProps {
	error?: string;
}

const FieldErrorMessage = (props: FieldErrorMessageProps) => {
	return (
		<Typography
			align="right"
			sx={{
				width: "100%",
				color: "#DF1B33",
				fontSize: "16px",
				marginTop: "-2.5px",
				marginBottom: "-5px",
			}}
			{...props}
		>
			{props.error !== undefined ? props.error : " "}
		</Typography>
	);
};

export default FieldErrorMessage;
