import reduxRegistrationApi from "_redux/registration/ReduxRegistrationApi";
import { useAppSelector } from "_redux/hooks";
import moment from "moment";
import CustomDatePicker from "_component/form/components/CustomDatePicker";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Formik } from "formik";
import AutoSubmitToken from "_component/form/components/AutoSubmitToken";
import CustomButton from "_component/buttons/CustomButton";
import "./../Registration.css";
import DaySelect from "../components/DaySelect";
import ReusableCard from "../components/ReusableCard";
import TimeSelect from "../components/TimeSelect";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as yup from "yup";
import {
	RegistrationFreeTimesResponse,
	RegistrationReceptionResponse,
	WorkTime,
} from "_model/account/WorkTime";
import { RegistrationAPIPublic } from "_api/RegistrationAPI";
import CustomSpinner from "_component/display/CustomSpinner";
import { getIconForTopic } from "./TopicChoice";
import { useNavigate } from "react-router-dom";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";

export default function TimeChoice() {
	const registration = useAppSelector((state) => state.registration);
	const [times, setTimes] = useState<RegistrationFreeTimesResponse[]>();
	const [selectedWorkTime, setSelectedWorkTime] = useState<WorkTime>();
	const [receptionPoint, setReceptionPoint] = useState<ReceptionPoint>();
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();

	useEffect(() => {
		if (registration?.info?.topic?.id) {
			handleDateSelect(moment());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDateSelect = async (date: moment.Moment) => {
		setLoading(true);
		if (registration?.info?.topic?.id) {
			const freeSlots = await RegistrationAPIPublic.getWorkTimes(
				date,
				registration?.info?.topic?.id
			);
			setTimes(freeSlots);
		}
		setLoading(false);
	};

	const setSelectedDay = (day: string) => {
		let foundDay = times?.find((time) => time.day === day);
		if (foundDay) {
			setTimes(
				times?.map((time) =>
					time.day === day
						? { ...time, selected: true }
						: { ...time, selected: false }
				)
			);
		}
	};

	const setSelectedTime = (id: number) => {
		if (times) {
			times.forEach((time: RegistrationFreeTimesResponse) => {
				time.receptions.forEach((reception: RegistrationReceptionResponse) => {
					reception.workTimes = reception.workTimes.map((freeDay: WorkTime) => {
						if (freeDay.id === id) {
							setSelectedWorkTime(freeDay);
							setReceptionPoint(reception.receptionPoint);
							return { ...freeDay, selected: true };
						} else {
							return { ...freeDay, selected: false };
						}
					});
				});
				setTimes([...times]);
			});
		}
	};

	const handleNextButton = () => {
		if (selectedWorkTime && receptionPoint) {
			reduxRegistrationApi.chooseTime(selectedWorkTime, receptionPoint);
			reduxRegistrationApi.goToSavedPhase(navigate);
		}
	};

	return (
		<Box sx={{ mb: 3 }}>
			<Typography sx={{ mb: 2 }} variant="h2">
				Pasirinkta paslauga
			</Typography>
			<Box sx={{ mb: 2 }}>
				<ReusableCard
					header={registration.info?.topic?.name}
					onHeaderClick={() => {
						reduxRegistrationApi.previousState();
						reduxRegistrationApi.goToSavedPhase(navigate);
					}}
					headerAction={
						<Box sx={{ display: "flex", ml: "auto", alignItems: "center" }}>
							<ArrowBackIcon height={20} width={20} sx={{ pr: 1 }} />
							Keisti
						</Box>
					}
					icon={getIconForTopic(registration.info?.topic?.name)}
				/>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				<Typography variant="h2" sx={{ mb: 3 }}>
					Pasirinkite paslaugos datą
				</Typography>
				{/* <Typography sx={{ mb: 3 }} variant="body">
					Pasirinkite paspaudė ant kalendoriaus arba iš pateiktų artimiausių
					datų
				</Typography> */}

				<Formik
					initialValues={{ date: moment() }}
					validationSchema={getValidationSchema()}
					onSubmit={(values: any) => handleDateSelect(values.date)}
				>
					{() => (
						<>
							<CustomDatePicker
								name={"date"}
								label={"Pasirinkite mėnesį"}
								pickMonth={true}
								disablePast
							/>
							<AutoSubmitToken allowDirty />
						</>
					)}
				</Formik>

				<Typography variant="subtitle" sx={{ my: 2 }}>
					Artimiausios laisvos dienos
				</Typography>

				{loading && <CustomSpinner size={"medium"} />}
				{!loading && times?.length === 0 && (
					<Typography variant={"h4"} fontWeight={600}>
						Šiame mėnesyje nėra laisvų laikų. Prašome pasirinkti kitą mėnesį.
					</Typography>
				)}
				{!loading && times?.length !== 0 && (
					<>
						<div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
							{times?.map((e: RegistrationFreeTimesResponse, i) => (
								<DaySelect
									key={i}
									comingDate={moment(e.day)}
									onClick={() => setSelectedDay(e.day)}
									selected={e.selected}
								/>
							))}
						</div>
						{times?.find((time) => time.selected) && (
							<>
								<Typography variant="h2" sx={{ mt: 2 }}>
									Pasirinkite paslaugos laiką
								</Typography>
								<Typography variant="subtitle" sx={{ mt: 1, mb: 2 }}>
									Artimiausi laisvi laikai
								</Typography>

								<Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
									{times
										?.find((time) => time.selected)
										?.receptions?.map(
											(freeDays: RegistrationReceptionResponse, i: number) => (
												<TimeSelect
													onClick={(id: number) => setSelectedTime(id)}
													key={i}
													address={freeDays.receptionPoint.address}
													times={freeDays.workTimes}
												/>
											)
										)}
									<CustomButton
										variant={"contained"}
										color={"primary"}
										onClick={() => handleNextButton()}
										disabled={!selectedWorkTime}
									>
										Tęsti ir registruotis <ArrowForwardIcon />
									</CustomButton>
								</Box>
							</>
						)}
					</>
				)}
			</Box>
		</Box>
	);
}

const getValidationSchema = () =>
	yup.object({
		date: yup
			.date()
			.typeError("Įveskite datą formatu: YYYY-MM-DD")
			.required("Būtina pasirinkti paslaugos datą."),
	});
