import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { SelectItem } from "_component/form/templates/FilterForm";

enum RegistrationStatus {
	CREATED = "CREATED", // default and first status after registration creation
	OCCURRED = "OCCURRED", // event happened successfully
	NOT_OCCURRED = "NOT_OCCURRED", // event was created, but client did not show up and did not inform specialist
	CANCELED = "CANCELED", // client canceled registration
}

interface StatusInfo {
	icon: JSX.Element;
	text: string;
}

export function translateRegistrationStatus(
	status?: RegistrationStatus | string
): StatusInfo {
	switch (status) {
		case RegistrationStatus.CREATED:
			return { icon: <HourglassEmptyIcon color="primary" />, text: "Sukurta" };
		case RegistrationStatus.OCCURRED:
			return { icon: <CheckCircleIcon color="success" />, text: "Įvyko" };
		case RegistrationStatus.NOT_OCCURRED:
			return { icon: <ErrorOutlineIcon color="warning" />, text: "Neįvyko" };
		case RegistrationStatus.CANCELED:
			return { icon: <CancelIcon color="error" />, text: "Atšaukta" };
		default:
			return { icon: <ErrorOutlineIcon color="action" />, text: "N/A" };
	}
}

export function getAllValues() {
	return Object.keys(RegistrationStatus).filter((k) => isNaN(Number(k)));
}

export const registrationStatusSelectValues = getAllValues().map(
	(value: string): SelectItem => ({
		value: value,
		label: translateRegistrationStatus(value)?.text,
	})
);

export function findRegistrationStatus(value: string): SelectItem | undefined {
	return registrationStatusSelectValues?.find(
		(status) => status?.value === value
	);
}

export default RegistrationStatus;
