import * as yup from "yup";
import { Formik } from "formik";
import { useContext } from "react";
import CustomTextField from "_component/form/components/CustomTextField";
import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import Department from "_model/department/Department";

interface Props {
	department?: Department;
	handleEdit: (department: Department) => void;
}

export default function DepartmentEditForm(props: Props) {
	const { department = getDefaultValues(), handleEdit } = props;
	const { formikFormRef } = useContext(FormContext);

	const handleFormikSubmit = (values: any) => {
		handleEdit(values as Department);
	};

	return (
		<Formik
			initialValues={department}
			validationSchema={validationSchema()}
			onSubmit={handleFormikSubmit}
			innerRef={formikFormRef} // used for submit outside Formik component
		>
			{() => (
				<>
					{/* Updates formContext, which is used in outside dialog */}
					<FormContextRefreshConduit debug />
					<CustomTextField title={"Pavadinimas"} name={"name"} required />
				</>
			)}
		</Formik>
	);
}

const getDefaultValues = (): Department => ({
	id: undefined,
	name: "",
});

const validationSchema = () =>
	yup.object({
		name: yup.string().required("Tema yra privaloma."),
	});
