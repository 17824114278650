import { Chip } from "@mui/material";

interface CustomChipProps {
	label?: string;
	color?:
		| "default"
		| "error"
		| "primary"
		| "secondary"
		| "info"
		| "success"
		| "warning"
		| undefined;
	fullWidth?: boolean;
}

export default function CustomChip(props: CustomChipProps) {
	return <Chip size={"medium"} label={props?.label} color={props?.color} />;
}
