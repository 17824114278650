import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import HelpIcon from "@mui/icons-material/Help";
import {
	Box,
	Button,
	InputAdornment,
	OutlinedTextFieldProps,
	TextField,
	Typography,
	TypographyProps,
} from "@mui/material";
import AppTheme from "AppTheme";
import { FieldHookConfig, useField } from "formik";
import "./FormComponents.css";
import React from "react";
import CustomTooltip from "../../common/CustomTooltip";

interface OtherProps extends OutlinedTextFieldProps {
	title?: string;
	titleButtonLabel?: string;
	titleButtonAction?: () => void;
	titleVariant?: "column" | "row";
	titleHelpText?: string;
	hideInput?: boolean;
	showInstead?: JSX.Element;
	description?: string;
	titleProps?: TypographyProps;
	descriptionProps?: TypographyProps;
	errorProps?: TypographyProps;
	tooltip?: string;
	startIcon?: JSX.Element;
	endIcon?: JSX.Element;
	enableEnterSubmit?: boolean;
	enterSubmitAction?: () => void;
	containerStyle?: React.CSSProperties;
}

export default function CustomTextField(
	props: OtherProps & FieldHookConfig<string>
) {
	const {
		startIcon,
		endIcon,
		title,
		titleButtonLabel,
		titleButtonAction,
		titleVariant = "column",
		titleHelpText,
		hideInput,
		showInstead,
		tooltip,
		description,
		enableEnterSubmit,
		enterSubmitAction,
		containerStyle,
		...other
	} = props;
	const [field, meta] = useField(props);
	const getLabel = (text?: string) => {
		return props.required && text ? `${text}*` : text;
	};
	return (
		<div className="textField-container">
			<div
				className={
					titleVariant === "row"
						? "textField-titleRow"
						: "textField-titleColumn"
				}
			>
				{title && (
					<div
						className="textField-title-container"
						style={{
							flex:
								titleButtonAction || titleVariant === "row"
									? "0 0 150px"
									: undefined,
							justifyContent: titleVariant === "row" ? "flex-end" : undefined,
						}}
					>
						<div
							style={{
								flexGrow: titleButtonAction ? 1 : undefined,
								justifyContent: titleVariant === "row" ? "flex-end" : undefined,
							}}
						>
							<Typography
								color={AppTheme.palette.text.primary}
								variant={"button"}
								fontWeight={500}
								{...other.titleProps}
							>
								{getLabel(title)}
							</Typography>
						</div>
						{!titleButtonAction && titleHelpText && (
							<div>
								<CustomTooltip title={titleHelpText}>
									<HelpIcon style={{ color: "#616161" }} fontSize={"small"} />
								</CustomTooltip>
							</div>
						)}
						{titleButtonAction && (
							<div>
								<Button
									onClick={titleButtonAction}
									variant={"contained"}
									style={{
										textTransform: "none",
									}}
								>
									<Typography variant={"h4"} color={"white"} fontWeight={300}>
										{titleButtonLabel}
									</Typography>
								</Button>
							</div>
						)}
					</div>
				)}
				{!hideInput && (
					<TextField
						fullWidth
						error={meta.touched && Boolean(meta.error)}
						InputProps={getInputProps(startIcon, endIcon)}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								if (enterSubmitAction && enableEnterSubmit) {
									enterSubmitAction();
								}
							}
						}}
						style={{ flex: titleVariant === "row" ? "1 1 200px" : undefined }}
						{...field}
						{...other}
					/>
				)}
				{hideInput && (
					<div className={"textField-showInstead"}>{showInstead}</div>
				)}
			</div>
			{meta.touched && Boolean(meta.error) && (
				<Box className="textField-error-container">
					<ErrorRoundedIcon className="textField-error-icon" />
					<Typography
						variant="subtitle"
						color={AppTheme.palette.error.main}
						align="left"
						{...other.errorProps}
					>
						{meta.error}
					</Typography>
				</Box>
			)}
			{description && (
				<Typography
					variant="subtitle"
					align="left"
					color={AppTheme.palette.grey[700]}
					{...other.descriptionProps}
				>
					{description}
				</Typography>
			)}
		</div>
	);
}

CustomTextField.defaultProps = {
	variant: "outlined",
};

const getInputAdornment = (position: "end" | "start", Icon?: JSX.Element) => {
	return Icon === undefined ? undefined : (
		<InputAdornment position={position}>{Icon}</InputAdornment>
	);
};

const getInputProps = (startIcon?: JSX.Element, endIcon?: JSX.Element) => {
	if (startIcon === undefined && endIcon === undefined) {
		return undefined;
	}
	const startAdornment = getInputAdornment("start", startIcon);
	const endAdornment = getInputAdornment("end", endIcon);
	if (startAdornment && endAdornment) {
		return {
			startAdornment: startAdornment,
			endAdornment: endAdornment,
		};
	}
	if (startAdornment) {
		return {
			startAdornment: startAdornment,
		};
	}
	if (endAdornment) {
		return {
			endAdornment: endAdornment,
		};
	}
};
