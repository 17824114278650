import moment from "moment";
import { Box, Divider, Typography } from "@mui/material";
import MailSent from "_asset/mail-sent.png";
import CancelIcon from "@mui/icons-material/Cancel";
import ReusableCard from "../components/ReusableCard";
import CustomGoogleMap from "_component/maps/CustomGoogleMap";
import { getRepresentedTime } from "./ContactInformationForm";
import { useAppSelector } from "_redux/hooks";
import CustomButton from "_component/buttons/CustomButton";
import DeleteConfirmationDialog from "_component/dialog/templates/DeleteConfirmationDialog";
import { useState } from "react";
import { RegistrationAPIPublic } from "_api/RegistrationAPI";
import "../Registration.css";
import RegistrationStatus from "_model/registration/RegistrationStatus";
import HouseIcon from "@mui/icons-material/House";
import EventIcon from "@mui/icons-material/Event";
import ComputerIcon from "@mui/icons-material/Computer";
import IconText from "../components/IconText";
import { getIconForTopic } from "./TopicChoice";
import {
	RegistrationPhase,
	translateRegistrationPhase,
} from "../RegistrationPhase";
import CustomSpinner from "_component/display/CustomSpinner";
import ReduxRegistrationApi from "_redux/registration/ReduxRegistrationApi";

export default function SuccessfulRegistration({ uuid }: { uuid?: string }) {
	const registration = useAppSelector((state) => state.registration.info);
	const [open, setOpen] = useState<boolean>(false);
	const [deleting, setDeleting] = useState<boolean>(false);
	const initialPathname =
		"/" + translateRegistrationPhase(RegistrationPhase.TOPIC_CHOICE);

	function getDate(): string {
		return getRepresentedTime(
			moment(registration?.workTime?.start),
			moment(registration?.workTime?.end)
		);
	}
	async function handleCancellation() {
		const maybeUUID = registration?.uuid ?? uuid;
		if (maybeUUID && !deleting) {
			setDeleting(true);
			await RegistrationAPIPublic.cancel(maybeUUID);
			const response = await RegistrationAPIPublic.getByUUID(maybeUUID);
			ReduxRegistrationApi.setRegistration(response);
			setDeleting(false);
		}
	}

	if (deleting) {
		return (
			<CustomSpinner
				size={"large"}
				label={"Atšaukiama registracija... Prašome palaukti..."}
			/>
		);
	}
	return (
		<Box sx={{ display: "flex", flexWrap: "wrap", mb: 3 }}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					margin: "0 auto",
					textAlign: "center",
				}}
			>
				<img
					src={MailSent}
					width={200}
					height={180}
					alt={"Mail sent"}
					style={{ marginBottom: "2rem" }}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}
				>
					{registration?.status !== RegistrationStatus.CANCELED && (
						<Typography variant="h1" sx={{ mb: 3 }}>
							Registracija sėkmingai patvirtinta
						</Typography>
					)}
					{registration?.status === RegistrationStatus.CANCELED && (
						<Typography variant="h1" sx={{ mb: 3 }}>
							Registracija atšaukta
						</Typography>
					)}
					{registration?.status !== RegistrationStatus.CANCELED && (
						<Typography variant="h6" sx={{ mb: 5, lineHeight: "26px" }}>
							Nurodytu el. paštu <b>{registration?.customer?.email}</b>{" "}
							išsiuntėme{" "}
							<i>
								{registration?.customer?.name}{" "}
								{registration?.customer?.lastName}
							</i>{" "}
							vardu registracijos patvirtinimą. Pasikeitus aplinkybėmis ir
							negalint dalyvauti, prašome atšaukti registraciją spaudžiant
							nuorodą atsiųsta el. paštu arba mygtuką esantį apačioje ne vėliau
							kaip 24 valandos iki suplanuoto vizito.
						</Typography>
					)}
					{registration?.status === RegistrationStatus.CANCELED && (
						<Box sx={{ display: "flex", flexDirection: "column", mb: 5 }}>
							<Typography variant="h6" sx={{ lineHeight: "26px" }}>
								Jūs atšaukėte rezervaciją. Jeigu norite užsiregistruoti dar
								kartą, grįžkite į pradinį puslapį ir užsiregistruokite iš naujo.
							</Typography>
							<a href={initialPathname}>Grįžti į pradinį puslapį</a>
						</Box>
					)}
				</div>
			</Box>
			<Box className={"successfulRegistrationView-infoContainer"}>
				<ReusableCard
					header={"Vizito informacija"}
					headerTextVariant={"h2"}
					isExpanded={true}
				>
					<Box
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "2rem",
							padding: "2rem",
							background: "white",
						}}
					>
						<IconText
							icon={getIconForTopic(registration?.topic?.name)}
							text={registration?.topic?.name}
							subtext={
								registration?.childTopic
									? registration?.childTopic?.name
									: undefined
							}
						/>
						<IconText icon={<EventIcon />} text={getDate()} />
						<IconText
							icon={<HouseIcon />}
							text={registration?.receptionPoint?.address}
						/>
						{registration?.remote && (
							<IconText
								icon={<ComputerIcon />}
								text={"Vyksta nuotoliu"}
								bolded
							/>
						)}

						<Divider />
						<Box
							style={{ display: "flex", flexDirection: "column", gap: "24px" }}
						>
							<div
								style={{ display: "flex", flexDirection: "column", gap: "8px" }}
							>
								<Typography variant={"h2"}>Vizito atšaukimas</Typography>
								<Typography variant={"body"} color={"#4F4F4F"}>
									{registration?.status !== RegistrationStatus.CANCELED
										? ""
										: "Vizitas buvo jau atšauktas"}
								</Typography>
							</div>
							<CustomButton
								color={"error"}
								onClick={() => setOpen(true)}
								disabled={
									deleting ||
									registration?.status === RegistrationStatus.CANCELED
								}
							>
								<CancelIcon style={{ paddingRight: "8px" }} /> Atšaukti vizitą
							</CustomButton>
						</Box>
						<DeleteConfirmationDialog
							open={open}
							handleClose={() => setOpen(false)}
							handleDelete={handleCancellation}
							labels={{
								title: "Vizito atšaukimas",
								question: `Ar tikrai norite atšaukti suplanuotą vizitą?`,
								dialogBoldedNameToDelete: `Vizitas (${getDate()})`,
								dialogText: " bus atšauktas ir jo atkurti galimybių nebus!",
								acceptButtonLabel: "Atšaukti vizitą",
								cancelButtonLabel: "Sugrįžti",
							}}
							preferences={{
								hideDeleteIcon: true,
							}}
						/>
					</Box>
				</ReusableCard>
				<CustomGoogleMap
					location={{
						lat: Number.parseFloat(
							registration?.receptionPoint?.latitude ?? "54.011655"
						),
						lng: Number.parseFloat(
							registration?.receptionPoint?.longitude ?? "23.979133"
						),
						address:
							registration?.receptionPoint?.address ??
							"Sausoji g. 1, 66165 Druskininkai",
					}}
					style={{ flex: "1 1 400px", height: "100%" }}
				/>
			</Box>
		</Box>
	);
}
