import SpecialistTimeForm from "./SpecialistTimeForm";
import moment from "moment";
import { getDate, getTime } from "_util/DateUtil";
import { WorkTime } from "_model/account/WorkTime";

import {
	DeleteDialogProps,
	EditDialogProps,
	CreateDialogProps,
} from "@recodin/fe-components";

export const workTimeEditDialogProps = (
	handleCreate: (workTime: any) => void,
	weekdays: string[]
): EditDialogProps => {
	return {
		headerText: "Naujų konsultacijos laikų sukūrimas",
		contentTextFor: "konsultacijos laiką",
		EditForm: (
			<SpecialistTimeForm onSubmit={handleCreate} weekdays={weekdays} />
		),
	};
};

export const workTimeCreateProps = (
	handleCreate: (workTime: any) => void,
	weekdays: string[]
): CreateDialogProps => ({
	...workTimeEditDialogProps(handleCreate, weekdays),
	createButtonLabel: "Nauji konsultacijos laikai",
	allowDirty: true,
});

export const workTimeDeleteDialogProps = (row: WorkTime): DeleteDialogProps => {
	const time = `${getDate(moment(row.start))} ${getTime(
		moment(row.start),
		true
	)} - ${getTime(moment(row.end), true)}`;
	return {
		labels: {
			title: "Konsultacijos laiko ištrynimas",
			question: `Ar tikrai norite ištrinti konsultacijos laiką ${time}?`,
			dialogBoldedNameToDelete: `Konsultacijos laikas ${time}`,
			dialogText: " bus visam laikui ištrintas. Jo atkurti galimybių nebus!",
		},
	};
};
