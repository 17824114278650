import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface Props {
	label?: string;
	name: string;
	disablePast?: boolean;
	minDate?: moment.Moment;
	maxDate?: moment.Moment;
	pickMonth?: boolean;
	onDateChange?: (value: moment.Moment) => void;
}

export default function CustomDatePicker(
	props: Props & FieldHookConfig<moment.Moment>
) {
	const [field, meta] = useField(props);
	const { setFieldValue } = useFormikContext<moment.Moment>();

	const handleChange = (value: any): void => {
		setFieldValue(props.name, moment(value), true);
		if (props.onDateChange) {
			props.onDateChange(moment(value));
		}
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DatePicker
				{...settings}
				value={field.value}
				onChange={handleChange}
				label={props.label && props.label}
				disablePast={props.disablePast}
				minDate={props?.minDate}
				maxDate={props?.maxDate}
				openTo={props.pickMonth ? "month" : "day"}
				format={props.pickMonth ? "YYYY, MMMM" : "YYYY-MM-DD"}
				views={props.pickMonth ? ["year", "month"] : undefined}
				slotProps={{
					textField: {
						sx: { width: "100%" },
						error: meta.touched && Boolean(meta.error),
						helperText: meta.touched && meta.error,
						name: field.name,
					},
				}}
			/>
		</LocalizationProvider>
	);
}

const settings = {
	minDate: moment("1990-01-01"),
	maxDate: moment("2099-12-31"),
};
