import * as yup from "yup";

const getOneOfListValidation = (
	names: string[],
	nameFor: string,
	nullable?: boolean
) => {
	const items = nullable ? [null, ...names] : names;
	const schema = yup.string().oneOf(items, `Pasirinkite vieną iš ${nameFor}.`);
	return nullable
		? schema.nullable()
		: schema.required(`Būtina pasirinkti ${nameFor}.`);
};

const getDepartmentValidation = (names: string[], nullable?: boolean) => {
	return getOneOfListValidation(names, "skyrių", nullable);
};

const getTopicValidation = (
	names: string[],
	nullable?: boolean,
	nameFor?: string
) => {
	return getOneOfListValidation(names, nameFor ?? "temų", nullable);
};

const getSubtopicValidation = (names: string[], nullable?: boolean) => {
	return getOneOfListValidation(names, "potemių", nullable);
};

const getReceptionPointValidation = (names: string[], nullable?: boolean) => {
	return getOneOfListValidation(names, "priėmimo punktų", nullable);
};

export {
	getOneOfListValidation,
	getDepartmentValidation,
	getTopicValidation,
	getSubtopicValidation,
	getReceptionPointValidation,
};
