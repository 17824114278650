import {
	Autocomplete,
	AutocompleteProps,
	AutocompleteRenderInputParams,
	Box,
	TextField,
	Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import FieldErrorMessage from "_component/common/FieldErrorMessage";
import "./AutoCompleteComponents.css";
import AppTheme from "AppTheme";

interface FormAutoCompleteProps
	extends AutocompleteProps<string, true | false, true, true> {
	name: string;
	onValueChange?: (value: string | string[]) => void;
	getNewValue?: (
		newValue?: string | string[],
		oldValue?: string | string[]
	) => string | string[] | undefined;
	label: string;
	title: string;
	renderFieldTitle: boolean;
	titleVariant?: "column" | "row";
	setSelectedClass?: (classValue: number) => void;
	translateOptionLabel?: (option: string) => string;
	renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
	renderOptionStyle?: (value: string) => React.CSSProperties;
	containerStyle?: React.CSSProperties;
	textFieldProps: {
		textFieldLabel?: string;
		required?: boolean;
		fullWidth?: boolean;
		margin?: "none" | "dense" | "normal";
		placeholder?: string;
	};
	required?: boolean;
}

const CustomAutoComplete = (props: FormAutoCompleteProps) => {
	const { name, options, textFieldProps } = props;
	const [field, meta] = useField(name);
	const formikContext = useFormikContext();
	const fieldRef = useRef<HTMLDivElement>(null);

	const [value, setValue] = useState<string | string[] | undefined>(
		field.value
	);

	const setAutoCompleteValue = (newValue?: string | string[]) => {
		const trueValue = props.getNewValue
			? props.getNewValue(newValue, value)
			: newValue;
		setValue(trueValue);
		formikContext.setFieldValue(name, trueValue);
		if (props.onValueChange && trueValue) {
			props.onValueChange(trueValue);
		}
	};

	useEffect(() => {
		const firstError = Object.keys(formikContext.errors)[0];
		if (formikContext.isSubmitting && firstError === name) {
			fieldRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	}, [meta.error, formikContext.isSubmitting, name, formikContext.errors]);

	const getFieldTitle = () => {
		return props.required && props.title ? `${props.title}*` : props.title;
	};

	const translateOptionLabel = (option: any) => {
		if (props.translateOptionLabel !== undefined) {
			return props.translateOptionLabel(option);
		}
		return option;
	};

	return (
		<Box
			className="auto-complete-container"
			style={{ ...props.containerStyle }}
		>
			<Box
				className={
					props.titleVariant === "column"
						? "auto-complete-column"
						: "auto-complete-row"
				}
			>
				{props.renderFieldTitle && (
					<Typography
						color={AppTheme.palette.text.primary}
						variant={"button"}
						fontWeight={500}
						style={{
							flex: props.titleVariant === "row" ? "0 1 150px" : undefined,
							textAlign: props.titleVariant === "row" ? "right" : undefined,
						}}
					>
						{getFieldTitle()}
					</Typography>
				)}
				<Autocomplete
					fullWidth
					disabled={props.disabled}
					key={field.name}
					value={value}
					options={["", ...options]}
					multiple={props.multiple}
					getOptionLabel={(option) => translateOptionLabel(option)}
					renderOption={(renderProps, option) =>
						option === "" ? undefined : (
							<Box component="li" {...renderProps}>
								<Typography
									variant="h4"
									fontWeight={400}
									style={
										props.renderOptionStyle && typeof option === "string"
											? props.renderOptionStyle(option)
											: undefined
									}
								>
									{translateOptionLabel(option)}
								</Typography>
							</Box>
						)
					}
					isOptionEqualToValue={(option: any, value: any) =>
						translateOptionLabel(option) === translateOptionLabel(value)
					}
					onChange={(event: any, newValue: any) => {
						setAutoCompleteValue(newValue);
					}}
					autoHighlight
					renderInput={(params) => (
						<TextField
							{...field}
							{...params}
							placeholder={textFieldProps.placeholder}
							{...textFieldProps}
							hiddenLabel={true}
							name={field.name}
							label={props.label}
							required={textFieldProps.required}
							error={meta.touched && Boolean(meta.error)}
							variant="outlined"
							inputProps={{
								...params.inputProps,
								autoComplete: "new-password",
							}}
						/>
					)}
					style={{
						minWidth: "90px",
						width: "100%",
						flex: props.titleVariant === "row" ? "1 1 200px" : undefined,
						...props.style,
					}}
					sx={{ ...props.sx }}
				/>
			</Box>
			{meta.touched && Boolean(meta.error) ? (
				<FieldErrorMessage error={meta.error} />
			) : null}
		</Box>
	);
};

export const renderInput = (params: AutocompleteRenderInputParams) => {
	const { InputProps } = params;
	return (
		<TextField
			{...params}
			InputProps={{
				...InputProps,
				inputProps: {
					autoComplete: "new-password",
				},
			}}
		/>
	);
};

CustomAutoComplete.defaultProps = {
	title: "",
	label: "",
	renderFieldTitle: true,
	renderInput: renderInput,
	textFieldProps: {
		placeholder: "- Pasirinkti -",
		required: true,
		fullWidth: true,
	},
};

export default CustomAutoComplete;
