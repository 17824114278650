import { SelectItem } from "_component/form/templates/FilterForm";
import { translateSelectItem } from "_util/UtilFunctions";

enum EmailType {
	EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
	SUCCESSFUL_REGISTRATION = "SUCCESSFUL_REGISTRATION",
	CANCELED_REGISTRATION = "CANCELED_REGISTRATION",
	REGISTRATION_REMINDER = "REGISTRATION_REMINDER",
}

const allEmailTypes: SelectItem[] = [
	{
		value: EmailType.EMAIL_VERIFICATION,
		label: "El. pašto patvirtinimas",
	},
	{
		value: EmailType.CANCELED_REGISTRATION,
		label: "Registracijos atšaukimas",
	},
	{
		value: EmailType.SUCCESSFUL_REGISTRATION,
		label: "Sėkminga registracija",
	},
	{
		value: EmailType.REGISTRATION_REMINDER,
		label: "Registracijos priminimas",
	},
];

function translateEmailType(value: string, nullable?: boolean): string {
	return translateSelectItem(allEmailTypes, value, nullable);
}

export default EmailType;
export { allEmailTypes, translateEmailType };
