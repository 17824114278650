import { useFormikContext } from "formik";
import { useEffect } from "react";

const AutoSubmitToken = ({
	debug,
	disabled,
	allowDirty,
}: {
	debug?: boolean;
	disabled?: boolean;
	allowDirty?: boolean;
}) => {
	const formikContext = useFormikContext();
	useEffect(() => {
		// If disableDirty == true, then ignores formikContext.dirty value
		if (
			formikContext.isValid &&
			(allowDirty || formikContext.dirty) &&
			Boolean(formikContext.touched) &&
			!disabled
		) {
			formikContext.submitForm();
			if (debug) {
				console.log("AutoSubmitToken: submitting");
			}
		} else if (debug) {
			console.log(
				`AutoSubmitToken: invalid (${
					formikContext.isValid
				}) && touched (${Boolean(formikContext.touched)}) && dirty (${
					formikContext.dirty
				})`,
				formikContext.errors
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formikContext.isValid, formikContext.submitForm, formikContext.values]);

	return null;
};

export default AutoSubmitToken;
