import { Box, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { ReactComponent as Logo } from "../../_asset/logo.svg";

export default function PublicLayout() {
	return (
		<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<Box
				style={{
					display: "flex",
					height: "48px",
					background: "#F7F8FC",
					padding: "1rem",
					alignItems: "center",
					borderBottom: "1px solid #c8d8e9",
				}}
			>
				<Logo
					style={{
						height: "48px",
						paddingRight: "1.5rem",
						borderRight: "1px solid #c8d8e9",
					}}
				/>
				<Box sx={{ marginLeft: 2 }}>
					<Typography variant="h4">
						Druskininkų savivaldybės registracijos portalas
					</Typography>
				</Box>
			</Box>
			<Box sx={{ height: "100%", display: "flex", overflowY: "auto" }}>
				<Outlet />
			</Box>
		</Box>
	);
}
