import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import React from "react";
import { commonConstants } from "_constant/commonConstants";

export interface CustomMapLocation {
	address?: string;
	lat: number;
	lng: number;
}

interface Props {
	location: CustomMapLocation;
	style?: React.CSSProperties;
	onDragEnd?: (e: google.maps.MapMouseEvent) => void;
}

function CustomGoogleMap({ location, style, onDragEnd }: Props) {
	return (
		<LoadScript
			googleMapsApiKey={commonConstants.googleMapsApiKey}
			language={"lt"}
			region={"lt"}
		>
			<GoogleMap
				mapContainerStyle={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					height: "400px",
					...style,
				}}
				center={location}
				zoom={16}
				options={{
					minZoom: 12,
					maxZoom: 18,
				}}
			>
				<Marker
					position={location}
					title={location.address}
					draggable={onDragEnd !== undefined}
					onDragEnd={onDragEnd}
				/>
			</GoogleMap>
		</LoadScript>
	);
}

export default React.memo(CustomGoogleMap);
