import "./Display.css";
import { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	useTheme,
} from "@mui/material";

interface SectionDisplayProps {
	title: string;
	subtitle?: string;
	titleVariant?: "h1" | "h3" | "h4";
	initiallyOpen?: boolean;
	permanent?: boolean;
	disableCollapse?: boolean;
	onClick?: () => void;
	children?: any;
}

export default function CollapsableSectionDisplay(props: SectionDisplayProps) {
	const {
		title,
		subtitle,
		titleVariant,
		permanent = false,
		initiallyOpen = permanent,
		disableCollapse,
		onClick,
		children,
	} = props;

	const theme = useTheme();

	const [expanded, setExpanded] = useState<boolean>(
		initiallyOpen || Boolean(disableCollapse)
	);
	const handleChange = () => {
		if (!disableCollapse) {
			setExpanded(!expanded);
		} else if (onClick) {
			onClick();
		}
	};
	return (
		<Accordion expanded={expanded} className={"display-section-container"}>
			<AccordionSummary
				onClick={handleChange}
				className={"display-section-summary"}
				style={{
					cursor: !disableCollapse || onClick ? "pointer" : "default",
					backgroundColor: theme.palette.primary.light,
				}}
				expandIcon={
					!disableCollapse &&
					!permanent && <ExpandMore onClick={handleChange} />
				}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography variant={titleVariant ?? "h3"} fontWeight="bold">
						{title}
					</Typography>
					{subtitle && (
						<Typography variant={"h4"} fontWeight={"medium"}>
							{subtitle}
						</Typography>
					)}
				</div>
			</AccordionSummary>
			{children && (
				<AccordionDetails className={"display-section-details"}>
					{children}
				</AccordionDetails>
			)}
		</Accordion>
	);
}
