import { deleteDialogProps } from "./RegistrationsDialogProps";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import { getRepresentedTime } from "../registration/phase/ContactInformationForm";
import moment from "moment";
import { translateRegistrationStatus } from "_model/registration/RegistrationStatus";
import { useNavigate } from "react-router-dom";
import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
	ActionsCell,
} from "@recodin/fe-components";

export function DepartmentTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: "Klientas",
					width: "25%",
					align: "left",
				},
				{
					label: "Informacija",
					width: "30%",
					align: "left",
				},
				{
					label: "Tema ir potemė",
					width: "25%",
					align: "left",
				},
				{
					label: "Statusas",
					width: "7.5%",
					align: "left",
				},
				{
					label: "Veiksmai",
					width: "7.5%",
				},
			]}
		/>
	);
}

interface DepartmentTableRowProps {
	row: RegistrationResponse;
	handleDelete: (row: RegistrationResponse) => void;
	disableDelete?: boolean;
}
export function RegistrationsTableRow(props: DepartmentTableRowProps) {
	const { row, handleDelete, disableDelete } = props;
	const navigate = useNavigate();
	return (
		<CustomTableRow onClick={() => navigate(`/registracijos/${row.id}`)}>
			<TextCell
				width={"25%"}
				align={"left"}
				label={`${row.customerName} ${row.customerLastName}`}
				subLabel={row.customerEmail}
			/>
			<TextCell
				width={"30%"}
				align={"left"}
				label={`${row?.specialist?.name} ${row?.specialist?.lastName}`}
				subLabel={[
					getRepresentedTime(
						moment(row.workTime.start),
						moment(row.workTime.end)
					),
					row.remote ? "Vyksta nuotoliu" : `${row.receptionPoint.address}`,
				]}
			/>
			<TextCell
				width={"25%"}
				align={"left"}
				label={`${row.topic.name}`}
				subLabel={row?.childTopic?.name}
			/>
			<TextCell
				width={"7.5%"}
				align={"left"}
				icon={translateRegistrationStatus(row.status).icon}
				label={translateRegistrationStatus(row.status).text}
			/>
			<ActionsCell
				width={"7.5%"}
				deleteDialogProps={deleteDialogProps(row)}
				handleDelete={() => handleDelete(row)}
				disableDelete={disableDelete}
			/>
		</CustomTableRow>
	);
}
