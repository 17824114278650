import "./Alert.css";
import React from "react";
import { ErrorValue } from "_redux/error/ErrorSlice";
import ReduxErrorApi from "_redux/error/ReduxErrorApi";
import CustomBanner from "./CustomBanner";
import CustomToast from "./CustomToast";

interface CustomAlertProps {
	alertValue: ErrorValue;
	bannerStyle?: React.CSSProperties;
	toastStyle?: React.CSSProperties;
	AlertMessageComponent?: JSX.Element;
}

export default function CustomAlert({
	alertValue,
	toastStyle,
	bannerStyle,
	AlertMessageComponent,
}: CustomAlertProps) {
	return (
		<>
			{alertValue.alertType === "banner" && (
				<CustomBanner
					key={alertValue.message + alertValue.alertSeverity}
					alertMessage={alertValue.message}
					AlertMessageComponent={AlertMessageComponent}
					severity={alertValue.alertSeverity}
					actionTitle={alertValue.actionTitle}
					alertAction={alertValue.alertAction}
					closeAlert={() => ReduxErrorApi.remove(alertValue)}
					hasCloseButton={alertValue.hasCloseButton}
					sx={bannerStyle}
				/>
			)}

			{alertValue.alertType !== "banner" && (
				<CustomToast
					closeAlert={() => ReduxErrorApi.remove(alertValue)}
					key={alertValue.message + alertValue.alertSeverity}
					alertMessage={alertValue.message}
					severity={alertValue.alertSeverity}
					sx={toastStyle}
				/>
			)}
		</>
	);
}
