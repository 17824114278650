import "./CustomSideMenu.css";
import EmailIcon from "@mui/icons-material/Email";
import {
	List,
	ListItem,
	ListItemButton,
	Typography,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PathGroup from "_model/route/PathGroup";
import PathItem from "_model/route/PathItem";
import { useAppSelector } from "_redux/hooks";
import ReduxCommonApi from "_redux/common/ReduxCommonApi";
import { RoleNames } from "_model/account/RoleResponse";
import { hasRole } from "_util/UtilFunctions";

interface Props {
	group: PathGroup;
}

export function SideMenuItems({ group }: Props) {
	const currentPathItem = useAppSelector((s) => s.common.currentPathItem);
	const navigate = useNavigate();
	const me = useAppSelector((state) => state.me);
	const theme = useTheme();

	const navigateToTarget = (item: PathItem) => {
		navigate(item.path);
		ReduxCommonApi.closeSideMenu();
	};

	return (
		<List>
			{group.paths
				.filter((item: PathItem) => item?.menuIcon && item?.menuLabel)
				.filter(
					(item: PathItem) =>
						(item.forAdmin && hasRole(RoleNames.ADMIN, me.info?.roles)) ||
						(item.forManager && hasRole(RoleNames.MANAGER, me.info?.roles)) ||
						(item.forObserver && hasRole(RoleNames.OBSERVER, me.info?.roles)) ||
						(item.forSpecialist &&
							hasRole(RoleNames.SPECIALIST, me.info?.roles))
				)
				.map((item) => (
					<ListItem
						key={item.menuLabel}
						className={"sideMenuItems-paths"}
						sx={
							currentPathItem === item
								? { backgroundColor: theme.palette.primary.light }
								: undefined
						}
					>
						<ListItemButton
							className={"sideMenuItems-path"}
							onClick={() => navigateToTarget(item)}
						>
							{item.menuIcon ? item.menuIcon : <EmailIcon />}
							<div className={"sideMenuItems-path-label"}>
								<Typography variant="h3">{item.menuLabel}</Typography>
							</div>
						</ListItemButton>
					</ListItem>
				))}
		</List>
	);
}
