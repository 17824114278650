import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import { useContext } from "react";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";
import CustomTextField from "_component/form/components/CustomTextField";
import CustomGoogleMap from "_component/maps/CustomGoogleMap";
import "./ReceptionPoint.css";

interface Props {
	receptionPoint?: ReceptionPoint;
	handleEdit: (receptionPoint: ReceptionPoint) => void;
}

export default function ReceptionPointEditForm({
	receptionPoint,
	handleEdit,
}: Props) {
	const { formikFormRef } = useContext(FormContext);

	function handleSubmit(formValues: any) {
		const values = formValues as ReceptionPointEditFormValues;
		handleEdit({
			id: receptionPoint?.id,
			...values,
		});
	}

	return (
		<Formik
			initialValues={getInitialValues(receptionPoint)}
			validationSchema={getValidationSchema()}
			onSubmit={handleSubmit}
			innerRef={formikFormRef}
		>
			{(formik: FormikProps<any>) => (
				<>
					<FormContextRefreshConduit />
					<CustomTextField title={"Pavadinimas"} name={"name"} required />
					<CustomTextField title={"Kodas"} name={"code"} required />
					<CustomTextField title={"Adresas"} name={"address"} required />
					<div className={"receptionPoint-inRow"}>
						<CustomTextField title={"Ilguma"} name={"longitude"} required />
						<CustomTextField title={"Platuma"} name={"latitude"} required />
					</div>
					<div
						style={{
							width: "100%",
							height: "400px",
						}}
					>
						<CustomGoogleMap
							location={{
								lat: Number.parseFloat(formik.values.latitude),
								lng: Number.parseFloat(formik.values.longitude),
								address: formik.values.address,
							}}
							onDragEnd={(e: google.maps.MapMouseEvent) => {
								if (e?.latLng) {
									formik.setFieldValue("latitude", e.latLng.lat());
									formik.setFieldValue("longitude", e.latLng.lng());
								}
							}}
						/>
					</div>
				</>
			)}
		</Formik>
	);
}

const getInitialValues = (values?: ReceptionPoint) => {
	return {
		name: values?.name ?? "",
		address: values?.address ?? "",
		code: values?.code ?? "",
		longitude: values?.longitude ?? "23.974293",
		latitude: values?.latitude ?? "54.020439",
	};
};

export type ReceptionPointEditFormValues = ReturnType<typeof getInitialValues>;

const getValidationSchema = () => {
	return yup.object({
		name: yup.string().required("Pavadinimo yra privalomas."),
		address: yup.string().required("Adresas yra privalomas."),
		code: yup.string().required("Kodas yra privalomas."),
		longitude: yup
			.string()
			.min(5, "Ilgumos ilgis turi būti nemažesnis negu 5 simboliai")
			.max(20, "Ilgumos ilgis turi būti nedidesnis negu 20 simboliai")
			.required("Ilguma yra privaloma."),
		latitude: yup
			.string()
			.min(5, "Platumos ilgis turi būti nemažesnis negu 5 simboliai")
			.max(20, "Platumos ilgis turi būti nedidesnis negu 20 simboliai")
			.required("Platuma yra privaloma."),
	});
};
