import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
} from "@recodin/fe-components";
import AccountResponse from "_model/account/AccountResponse";
import { RoleNames, translateRoleByName } from "_model/account/RoleResponse";
import { NavigateFunction } from "react-router-dom";
import { Theme } from "@mui/material";
import CustomIconButton from "_component/buttons/CustomIconButton";
import EditIcon from "@mui/icons-material/Edit";
import { hasRole } from "_util/UtilFunctions";
import CustomChip from "_component/chip/CustomChip";
import moment from "moment";

export function AccountTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: "Vartotojas",
					width: "26%",
					align: "left",
				},
				{
					label: "Informacija",
					width: "26%",
					align: "left",
				},
				{
					label: "Sistemos teisės (rolės)",
					width: "20%",
					align: "left",
				},
				{
					label: "Būsena",
					width: "20%",
					align: "left",
				},
				{
					label: "Veiksmai",
					width: "8%",
				},
			]}
		/>
	);
}

interface Props {
	row: AccountResponse;
	navigate: NavigateFunction;
	theme: Theme;
}
export function AccountTableRow({ row, navigate, theme }: Props) {
	function handleRowClick() {
		navigate(`/paskyros/${row.id}`);
	}
	return (
		<CustomTableRow onClick={handleRowClick}>
			<TextCell
				width={"26%"}
				align={"left"}
				label={`${row.name} ${row.lastName}`}
				subLabel={row.email}
				wordBreak
			/>

			<TextCell
				width={"26%"}
				align={"left"}
				label={`suvesta ${row?.workDays?.length} d.d. laisvų laikų`}
				subLabel={
					row?.lastLogin
						? moment(row.lastLogin).format("yyyy-MM-DD HH:mm:ss")
						: undefined
				}
			/>
			<TextCell
				width={"20%"}
				align={"left"}
				label={row.roles
					.map((role) => translateRoleByName(role.name))
					.join(", ")}
			/>
			<TextCell
				width={"20%"}
				align={"left"}
				label={
					<div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
						{row.active && <CustomChip label="Aktyvuota" color="primary" />}
						{!row.active && <CustomChip label="Neaktyvuota" color="error" />}
						{hasRole(RoleNames.SPECIALIST, row.roles) &&
							row.workDepartments.length === 0 && (
								<CustomChip label="Nepriskirta skyriui" color="error" />
							)}
					</div>
				}
			/>
			<TextCell
				width={"8%"}
				label={
					<CustomIconButton
						icon={<EditIcon style={{ color: theme.palette.primary.main }} />}
						onClick={() => handleRowClick()}
					/>
				}
			/>
		</CustomTableRow>
	);
}
