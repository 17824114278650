import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";

const adminUrl = baseEndpoints.receptionPointAdmin;
const ReceptionPointAPIAdmin = {
	getAll: (): Promise<ReceptionPoint[]> => axios.get(adminUrl),
	create: (data: ReceptionPoint): Promise<ReceptionPoint> =>
		axios.post(adminUrl, data),
	update: (data: ReceptionPoint): Promise<ReceptionPoint> =>
		axios.put(`${adminUrl}/${data.id}`, data),
	delete: (id: number) => axios.delete(`${adminUrl}/${id}`),
};

export { ReceptionPointAPIAdmin };
