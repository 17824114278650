import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { FieldHookConfig, useField, useFormikContext } from "formik";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

interface Props {
	label: string;
	name: string;
	minTime?: moment.Moment;
	maxTime?: moment.Moment;
}

export default function CustomTimePicker(
	props: Props & FieldHookConfig<moment.Moment>
) {
	const [field, meta] = useField(props);
	const { setFieldValue } = useFormikContext<moment.Moment>();

	const handleChange = (value: any): void => {
		setFieldValue(props.name, new Date(value), true);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<TimePicker
				{...settings}
				label={props.label}
				value={field.value}
				onChange={handleChange}
				minTime={props.minTime}
				maxTime={props.maxTime}
				slotProps={{
					textField: {
						sx: { width: "100%" },
						error: meta.touched && Boolean(meta.error),
						helperText: meta.touched && meta.error,
					},
				}}
			/>
		</LocalizationProvider>
	);
}

const settings = {
	format: "HH:mm",
};
