import { useAppSelector } from "_redux/hooks";
import SettingResponse, { translateSetting } from "_model/setting/Setting";
import CollapsableSectionDisplay from "_component/display/CollapsableSectionDisplay";
import CustomCheckBox from "_component/form/components/CustomCheckBox";
import { Formik, FormikProps } from "formik";
import CustomSpinner from "_component/display/CustomSpinner";
import { SettingAPIAdmin } from "_api/SettingAPI";
import ReduxCommonApi from "../../_redux/common/ReduxCommonApi";

export default function SettingsPage() {
	const settings = useAppSelector((state) => state.common.settings);

	async function update(setting: SettingResponse) {
		await SettingAPIAdmin.update(setting);
		// Update settings in redux state
		ReduxCommonApi.getAndSetSettings();
	}

	if (!settings) {
		return <CustomSpinner />;
	}
	return (
		<CollapsableSectionDisplay title={"Bendri nustatymai"} initiallyOpen>
			<Formik initialValues={getInitialValues(settings)} onSubmit={() => {}}>
				{(formik: FormikProps<any>) => (
					<>
						{formik.values.settings?.map(
							(setting: SettingResponse, index: number) => (
								<CustomCheckBox
									key={setting.name + index}
									label={translateSetting(setting.name)}
									name={`settings[${index}].value`}
									onChange={() => update({ ...setting, value: !setting.value })}
								/>
							)
						)}
					</>
				)}
			</Formik>
		</CollapsableSectionDisplay>
	);
}

function getInitialValues(settings?: SettingResponse[]) {
	return {
		settings: settings?.slice() ?? [],
	};
}
