import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
	ActionsCell,
} from "@recodin/fe-components";
import EmailTemplate from "_model/emailtemplate/EmailTemplate";
import { useState } from "react";
import { getTrimmedText } from "_util/UtilFunctions";
import { translateEmailType } from "_model/emailtemplate/EmailType";
import { deleteDialogProps, editDialogProps } from "./EmailTemplateDialogProps";
import DangerousHTMLViewTypography from "_component/display/DangerousHTMLViewTypography";

export function EmailTemplateTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: " ",
					width: "5%",
				},
				{
					label: "Tipas",
					width: "30%",
					align: "left",
				},
				{
					label: "Tema",
					width: "55%",
					align: "left",
				},
				{
					label: "Veiksmai",
					width: "10%",
				},
			]}
		/>
	);
}

interface EmailTemplateTableRowProps {
	row: EmailTemplate;
	handleDelete: (row: EmailTemplate) => void;
	handleCreateUpdate: (row: EmailTemplate) => void;
}
export function EmailTemplateTableRow(props: EmailTemplateTableRowProps) {
	const { row, handleDelete, handleCreateUpdate } = props;
	const [open, setOpen] = useState<boolean>(false);
	return (
		<CustomTableRow
			setOpen={setOpen}
			showButtonOnLeftSide
			collapsableItems={
				<DangerousHTMLViewTypography title={row.subject} label={row?.body} />
			}
		>
			<TextCell
				width={"30%"}
				align={"left"}
				label={translateEmailType(row?.type ?? "N/A")}
			/>
			<TextCell
				width={"60%"}
				align={"left"}
				label={row.subject}
				subLabel={open ? undefined : getTrimmedText(row.body, 50)}
			/>
			<ActionsCell
				width={"10%"}
				editDialogProps={editDialogProps(handleCreateUpdate, row)}
				deleteDialogProps={deleteDialogProps(row)}
				handleDelete={() => handleDelete(row)}
			/>
		</CustomTableRow>
	);
}
