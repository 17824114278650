import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";
import EmailTemplate from "_model/emailtemplate/EmailTemplate";

const adminUrl = baseEndpoints.emailTemplateAdmin;
const EmailTemplateAPIAdmin = {
	getAll: (): Promise<EmailTemplate[]> => axios.get(adminUrl),
	create: (data: EmailTemplate): Promise<EmailTemplate> =>
		axios.post(adminUrl, data),
	update: (data: EmailTemplate): Promise<EmailTemplate> =>
		axios.put(`${adminUrl}/${data.id}`, data),
	delete: (id: number) => axios.delete(`${adminUrl}/${id}`),
};

export { EmailTemplateAPIAdmin };
