import "./Registration.css";
import { useAppSelector } from "_redux/hooks";
import SuccessfulRegistration from "./phase/SuccessfulRegistration";
import ContactInformationForm from "./phase/ContactInformationForm";
import TopicChoice from "./phase/TopicChoice";
import TimeChoice from "./phase/TimeChoice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import CustomSpinner from "_component/display/CustomSpinner";
import {
	getPhaseFromTranslation,
	RegistrationPhase,
	translateRegistrationPhase,
} from "./RegistrationPhase";

export default function Registration(props: { uuid?: string }) {
	const { maybePhase } = useParams();
	const phase = getPhaseFromTranslation(maybePhase);

	const navigate = useNavigate();
	const location = useLocation();
	const registration = useAppSelector((state) => state.registration);

	useEffect(() => {
		if (location.pathname === "/" || !registration?.info?.topic) {
			navigate(
				"/" + translateRegistrationPhase(RegistrationPhase.TOPIC_CHOICE)
			);
		}
		// eslint-disable-next-line
	}, []);

	if (!registration?.info) {
		return (
			<CustomSpinner
				size={"large"}
				label={"Prašome palaukti, puslapis kraunasi..."}
			/>
		);
	}

	return (
		<Box
			style={{
				width: "100%",
				maxWidth: "1024px",
				margin: "0 auto",
				padding: "2rem 1rem",
			}}
		>
			{phase === RegistrationPhase.TOPIC_CHOICE && <TopicChoice />}
			{phase === RegistrationPhase.TIME_CHOICE && <TimeChoice />}
			{phase === RegistrationPhase.CONTACT_INFORMATION_INPUT && (
				<ContactInformationForm />
			)}
			{/* This is achieved only when calling reduxRegistrationApi::setRegistration */}
			{(phase === RegistrationPhase.FINISHED || props.uuid) && (
				<SuccessfulRegistration uuid={props.uuid} />
			)}
		</Box>
	);
}
