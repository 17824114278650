import { store } from "../store";
import React from "react";
import { clearAllErrors, ErrorValue } from "./ErrorSlice";
import { addAlert, hideAlert, removeAlert } from "./ErrorActions";

const ReduxErrorApi = {
	add: (error: ErrorValue): void => {
		store.dispatch(addAlert(error));
	},
	createAndAddError: (
		message: string,
		severity: "error" | "warning" | "info" | "success",
		actionTitle?: string,
		alertAction?: () => void | React.ReactNode,
		alertType: "banner" | "toast" = "banner"
	): void => {
		let newError = {
			isOpen: true,
			message: message,
			alertSeverity: severity,
			alertType: alertType,
			actionTitle: actionTitle,
			alertAction: alertAction,
		} as ErrorValue;
		store.dispatch(addAlert(newError));
	},
	remove: (alertToRemove: ErrorValue): void => {
		store.dispatch(removeAlert(alertToRemove));
	},
	hide: (alertToHide: ErrorValue): void => {
		store.dispatch(hideAlert(alertToHide));
	},
	clearAll: (): void => {
		store.dispatch(clearAllErrors());
	},
};

export default ReduxErrorApi;
