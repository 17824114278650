import { AxiosError } from "axios";

export interface ErrorVariable {
	key: string;
	value: string;
}

export interface IError {
	baseErrorMessage: string;
	errorVariables: ErrorVariable[];
}

export function mapAxiosErrorToIError(error: AxiosError): IError | undefined {
	let errorArray = error.response?.data?.toString().split(" ");
	if (errorArray) {
		const baseErrorMessage = errorArray[0];
		errorArray.shift();

		const errorVariables: ErrorVariable[] = errorArray.map((errorVariable) => {
			let variable = errorVariable.split("=");
			// Remove comma
			let value =
				variable[1]?.at(-1) === "," ? variable[1]?.slice(0, -1) : variable[1];
			return {
				key: variable[0],
				value: value,
			};
		});
		return {
			baseErrorMessage,
			errorVariables,
		};
	}
	return undefined;
}
