import { useEffect, useState } from "react";
import EmailTemplate from "_model/emailtemplate/EmailTemplate";
import { EmailTemplateAPIAdmin } from "_api/EmailTemplateAPI";
import { CustomTable } from "@recodin/fe-components";
import {
	EmailTemplateTableHeadRow,
	EmailTemplateTableRow,
} from "./EmailTemplateTableRow";
import { createProps } from "./EmailTemplateDialogProps";
import { useAppSelector } from "_redux/hooks";

export default function EmailTemplatePage() {
	const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const currentPathItem = useAppSelector((s) => s.common.currentPathItem);

	useEffect(() => {
		handleDataFetch();
	}, []);

	async function handleDataFetch() {
		setLoading(true);
		setEmailTemplates(await EmailTemplateAPIAdmin.getAll());
		setLoading(false);
	}
	async function handleCreateUpdate(data: EmailTemplate) {
		if (data?.id) {
			await EmailTemplateAPIAdmin.update(data);
		} else {
			await EmailTemplateAPIAdmin.create(data);
		}
		await handleDataFetch();
	}
	async function handleDelete(data: EmailTemplate) {
		if (data?.id) {
			await EmailTemplateAPIAdmin.delete(data?.id);
			await handleDataFetch();
		}
	}
	return (
		<CustomTable
			data={emailTemplates}
			title={currentPathItem?.title}
			isLoading={loading}
			headerRow={EmailTemplateTableHeadRow}
			contentRow={(props: { row: EmailTemplate }) => (
				<EmailTemplateTableRow
					row={props.row}
					handleDelete={handleDelete}
					handleCreateUpdate={handleCreateUpdate}
				/>
			)}
			createProps={createProps(handleCreateUpdate)}
			disableSearching
			disableFiltering
		/>
	);
}
