import { Typography } from "@mui/material";

export default function PrivacyAndDataHandlingPage() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				width: "100%",
				paddingTop: "100px",
				textAlign: "justify",
			}}
		>
			<div>
				<Typography variant={"h1"} align={"center"}>
					Asmens duomenų tvarkymas
				</Typography>
				<Typography variant={"h4"}>
					Šiame puslapyje bus pateikta informacija apie klientų duomenų
					panaudojimą ir saugojimą.
				</Typography>
			</div>
		</div>
	);
}
