import { FieldHookConfig, useField, useFormikContext } from "formik";
import { Checkbox, styled, Typography } from "@mui/material";

interface Props {
	label: string;
	link?: string;
	textWithLink?: string;
	name: string;
	onChange?: () => void;
}

export default function CustomCheckBox(props: Props & FieldHookConfig<string>) {
	const [field] = useField(props);
	const { handleChange } = useFormikContext<boolean>();

	return (
		<CheckBoxContainer>
			<Checkbox
				checked={Boolean(field.value)}
				name={props.name}
				onChange={(event: any) => {
					handleChange(event);
					props?.onChange && props.onChange();
				}}
			/>
			<TextContainer>
				<Typography>
					{props.label}
					{props.textWithLink && props.link && (
						<StyledLink href={props.link}>{props.textWithLink}</StyledLink>
					)}
				</Typography>
			</TextContainer>
		</CheckBoxContainer>
	);
}

const CheckBoxContainer = styled("div")({
	display: "flex",
	alignItems: "center",
	gap: "4px",
});

const TextContainer = styled("div")({
	display: "flex",
	alignItems: "center",
});

const StyledLink = styled("a")({
	color: "#4A5E96",
});
