import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PathItem from "_model/route/PathItem";
import { commonConstants } from "_constant/commonConstants";
import RoleResponse from "_model/account/RoleResponse";
import SettingResponse from "_model/setting/Setting";

export interface CommonState {
	allAccountRoles?: RoleResponse[];
	settings?: SettingResponse[];
	outerWidth?: number;
	innerWidth?: number;
	// do not show side menu for certain path names or user roles
	sideMenuIsHidden?: boolean;
	// open/close is for mobile
	sideMenuIsOpen?: boolean;
	// permanent is changed according to inner width
	sideMenuIsPermanent?: boolean;
	currentPathItem?: PathItem;
}

const initialState: CommonState = {
	allAccountRoles: undefined,
	outerWidth: window.outerWidth,
	innerWidth: window.innerWidth,
	sideMenuIsHidden: true,
	sideMenuIsOpen: true,
	sideMenuIsPermanent:
		window.innerWidth >= commonConstants.minPermanentSideMenuWidth,
	currentPathItem: undefined,
};

const commonSlice = createSlice({
	name: "commonInfo",
	initialState: initialState,
	reducers: {
		updateWidth: (state: CommonState, action: PayloadAction<CommonState>) => {
			const { outerWidth, innerWidth } = action.payload;
			state.outerWidth = outerWidth;
			state.innerWidth = innerWidth;

			state.sideMenuIsPermanent =
				innerWidth !== undefined &&
				innerWidth >= commonConstants.minPermanentSideMenuWidth;
		},
		updateSideMenuFlags: (
			state: CommonState,
			action: PayloadAction<CommonState>
		) => {
			if (state.sideMenuIsHidden !== action.payload.sideMenuIsHidden) {
				state.sideMenuIsHidden = action.payload.sideMenuIsHidden;
			}
			if (
				state.sideMenuIsOpen !== action.payload.sideMenuIsOpen &&
				!state.sideMenuIsPermanent
			) {
				state.sideMenuIsOpen = action.payload.sideMenuIsOpen;
			}
		},
		updateCurrentPathItem: (
			state: CommonState,
			action: PayloadAction<CommonState>
		) => {
			if (state.currentPathItem !== action.payload.currentPathItem) {
				state.currentPathItem = action.payload.currentPathItem;
			}
		},
		updateRoles: (state: CommonState, action: PayloadAction<CommonState>) => {
			state.allAccountRoles = action.payload.allAccountRoles;
		},
		updateSettings: (
			state: CommonState,
			action: PayloadAction<CommonState>
		) => {
			state.settings = action.payload.settings;
		},
	},
});

export const {
	updateWidth,
	updateSideMenuFlags,
	updateCurrentPathItem,
	updateRoles,
	updateSettings,
} = commonSlice.actions;
export default commonSlice.reducer;
