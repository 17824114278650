import "./Registrations.css";
import { useNavigate, useParams } from "react-router-dom";
import { customParseInt, hasRole } from "_util/UtilFunctions";
import { useEffect, useState } from "react";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import Topic from "_model/topic/Topic";
import RegistrationStatus, {
	getAllValues,
	translateRegistrationStatus,
} from "_model/registration/RegistrationStatus";
import { TopicAPIPublic } from "_api/TopicAPI";
import { Formik, FormikProps } from "formik";
import CustomTextField from "_component/form/components/CustomTextField";
import CommentEditSection from "_component/form/templates/CommentEditSection";
import * as yup from "yup";
import { getCustomerValidationSchema } from "../registration/phase/ContactInformationForm";
import {
	getOneOfListValidation,
	getSubtopicValidation,
	getTopicValidation,
} from "_component/form/Validation";
import CustomAutoComplete from "_component/form/components/CustomAutoComplete";
import CollapsableSectionDisplay from "_component/display/CollapsableSectionDisplay";
import { RegistrationAPIAdmin } from "_api/RegistrationAPI";
import RegistrationRequest from "_model/registration/RegistrationRequest";
import CustomSpinner from "_component/display/CustomSpinner";
import CustomFormActions from "_component/form/templates/CustomFormActions";
import CustomButton from "_component/buttons/CustomButton";
import { useAppSelector } from "_redux/hooks";
import { RoleNames } from "_model/account/RoleResponse";
import CustomCheckBox from "_component/form/components/CustomCheckBox";
import { Box } from "@mui/material";

export default function RegistrationEditPage() {
	const { maybeId } = useParams();
	const registrationId = customParseInt(maybeId);
	const navigate = useNavigate();
	const me = useAppSelector((state) => state.me.info);
	const disableEdit = hasRole(RoleNames.OBSERVER, me?.roles);

	const [registration, setRegistration] = useState<RegistrationResponse>();
	const [formValues, setFormValues] = useState<RegistrationFormValues>();
	const [topics, setTopics] = useState<Topic[]>([]);
	const [childTopics, setChildTopics] = useState<Topic[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const allTopicValues = topics.map((t) => `${t.id}`);
	const allChildTopicValues = childTopics.map((s: Topic) => `${s.id}`);
	const allStatusValues = getAllValues();

	useEffect(() => {
		setLoading(true);
		if (!registrationId) {
			navigate("/registracijos");
			navigate(0); // Reload page
		}
		getData();
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getSubtopics(registration?.topic?.id); // TODO: remove and fix in DR57
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [registration?.topic?.id]);

	async function getRegistration() {
		if (registrationId) {
			const data = await RegistrationAPIAdmin.getById(registrationId);
			setRegistration(data);
			setFormValues(getDefaultValues(data));
		} else {
			setFormValues(getDefaultValues());
		}
	}
	async function getData() {
		setTopics(await TopicAPIPublic.getAll());
		await getRegistration();
	}

	function getSubtopics(topicId?: string | number) {
		const newSubtopics =
			topics.find((t: Topic) => `${t.id}` === `${topicId}`)?.childTopics ?? [];
		setChildTopics(newSubtopics);
		return newSubtopics;
	}

	async function handleUpdate(data: RegistrationRequest) {
		if (data?.id) {
			await RegistrationAPIAdmin.update(data);
			navigate("/registracijos");
		}
	}

	function handleFormikSubmit(values: any) {
		if (disableEdit) return;

		const formValues = values as RegistrationFormValues;
		handleUpdate({
			id: formValues?.id,
			customerEmail: formValues.customer.email,
			customerLastName: formValues.customer.lastName,
			customerName: formValues.customer.name,
			customerPhoneNumber: formValues.customer.phoneNumber,
			status: formValues.status as RegistrationStatus,
			topicId:
				customParseInt(formValues.childTopic) ??
				customParseInt(formValues.topic) ??
				0, // TODO: remove in DR57
			workTimeId: customParseInt(formValues.workTime) || -1, // TODO: ar neina kazkaip iskart i formika gauti numberiu?
			remote: formValues.remote,
		});
	}

	if (loading || !formValues) {
		return <CustomSpinner size={"medium"} />;
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "20px",
				paddingBottom: "40px",
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<CustomButton
					fullWidth
					variant={"outlined"}
					style={{ flex: "0 1 300px" }}
					onClick={() => navigate("/registracijos")}
				>
					Atgal
				</CustomButton>
			</div>
			<CollapsableSectionDisplay title={"Bendri duomenys"} initiallyOpen>
				<Formik
					initialValues={formValues}
					validationSchema={validationSchema(
						allTopicValues,
						allChildTopicValues,
						allStatusValues
					)}
					onSubmit={handleFormikSubmit}
				>
					{(formik: FormikProps<any>) => (
						<>
							<RegistrationAutoComplete
								name={"status"}
								title={"Būsena"}
								items={allStatusValues}
								options={allStatusValues}
								translateOptionLabel={(value: string) =>
									translateRegistrationStatus(value)?.text
								}
								disabled={disableEdit}
							/>

							<div className={"registrations-form-inRow"}>
								<CustomTextField
									title={"Kliento vardas"}
									name={"customer.name"}
									disabled={disableEdit}
									required
								/>
								<CustomTextField
									title={"Kliento pavardė"}
									name={"customer.lastName"}
									disabled={disableEdit}
									required
								/>
							</div>
							<CustomTextField
								title={"Kliento tel. numeris"}
								name={"customer.phoneNumber"}
								disabled={disableEdit}
								required
							/>
							<CustomTextField
								title={"Kliento el. paštas"}
								name={"customer.email"}
								disabled={disableEdit}
								required
							/>

							<div className={"registrations-form-inRow"}>
								<RegistrationAutoComplete
									name={"topic"}
									title={"Tema"}
									items={topics}
									onValueChange={(id: any) => {
										const newSubtopics = getSubtopics(id);
										formik.setFieldValue(
											"childTopic",
											newSubtopics.length === 0 ? null : "- pasirinkti -",
											true
										);
									}}
									disabled={disableEdit}
								/>
								<RegistrationAutoComplete
									name={"childTopic"}
									title={"Potemė"}
									disabled={
										formik.errors.topic !== undefined ||
										childTopics.length === 0 ||
										disableEdit
									}
									items={childTopics}
									placeHolder={
										childTopics.length === 0
											? "- nėra potemių -"
											: "- pasirinkti -"
									}
								/>
							</div>
							<Box style={{ marginLeft: "-12px" }}>
								<CustomCheckBox
									label={"Konsultaciją vyksta nuotoliniu būdu."}
									name={"remote"}
									disabled={disableEdit}
								/>
							</Box>
							<CustomFormActions />
						</>
					)}
				</Formik>
			</CollapsableSectionDisplay>

			<CollapsableSectionDisplay
				title={`Komentarai (${
					(registration?.comments?.length ?? 0) +
					Number(Boolean(registration?.customerComment))
				})`}
				initiallyOpen
			>
				<CommentEditSection
					registration={registration}
					refresh={getRegistration}
					disabled={disableEdit}
				/>
			</CollapsableSectionDisplay>
		</div>
	);
}

const getDefaultValues = (registration?: RegistrationResponse) => ({
	id: registration?.id,
	creationTime: "",
	creationIpAddress: "",
	customer: {
		name: registration?.customerName ?? "",
		lastName: registration?.customerLastName ?? "",
		phoneNumber: registration?.customerPhoneNumber ?? "",
		email: registration?.customerEmail ?? "",
	},
	status:
		registration?.status?.toString() ?? RegistrationStatus.CREATED.toString(),
	topic: `${registration?.topic?.id}`,
	childTopic: registration?.childTopic?.id
		? `${registration?.childTopic?.id}`
		: null,
	workTime: `${registration?.workTime?.id}`,
	remote: registration?.remote ?? false,
});
type RegistrationFormValues = ReturnType<typeof getDefaultValues>;

const validationSchema = (
	topicNames?: string[],
	childTopicNames?: string[],
	allStatusValues?: string[]
) =>
	yup.object({
		customer: getCustomerValidationSchema(),
		topic: getTopicValidation(topicNames ?? []),
		childTopic: getSubtopicValidation(childTopicNames ?? [], true),
		status: getOneOfListValidation(allStatusValues ?? [], "būsenų"),
	});

const translate = (id: string, values: any[]): string => {
	return values?.find((v) => v.id.toString() === id)?.name ?? "";
};

interface RegistrationAutoCompleteProps {
	name: string;
	title: string;
	items: any[];
	onValueChange?: (s: string | string[]) => void;
	disabled?: boolean;
	placeHolder?: string;
	options?: any[];
	translateOptionLabel?: (val: string) => string;
}
function RegistrationAutoComplete(props: RegistrationAutoCompleteProps) {
	return (
		<CustomAutoComplete
			{...props}
			options={props?.options ?? props.items.map((i) => `${i.id}`)}
			translateOptionLabel={
				props?.translateOptionLabel ??
				((id: string) => translate(id, props.items))
			}
			titleVariant={"column"}
			textFieldProps={{
				placeholder: props?.placeHolder ?? "",
			}}
		/>
	);
}
