import { deleteDialogProps, editDialogProps } from "./TopicDialogProps";
import { Typography } from "@mui/material";
import Topic from "_model/topic/Topic";
import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
	ActionsCell,
} from "@recodin/fe-components";

export function TopicTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: " ",
					width: "5%",
				},
				{
					label: "Tema",
					width: "60%",
					align: "left",
				},
				{
					label: "Potemės",
					width: "15%",
				},
				{
					label: "Veiksmai",
					width: "20%",
				},
			]}
		/>
	);
}

interface TopicTableRowProps {
	row: Topic;
	handleDelete: (row: Topic) => void;
	handleCreateUpdate: (row: Topic) => void;
}
export function TopicTableRow(props: TopicTableRowProps) {
	const { row, handleDelete, handleCreateUpdate } = props;
	return (
		<CustomTableRow
			showButtonOnLeftSide
			collapsableItems={
				row?.childTopics?.length === 0 ? undefined : (
					<>
						{row?.childTopics?.map((subtopic, index) => (
							<Typography variant={"h4"} fontWeight={400} key={subtopic?.id}>
								{index + 1}. {subtopic.name}
							</Typography>
						))}
					</>
				)
			}
		>
			{row?.childTopics?.length === 0 && (
				<TextCell width={"5%"} align={"left"} label={" "} />
			)}
			<TextCell width={"60%"} align={"left"} label={row.name} />
			<TextCell width={"15%"} label={row?.childTopics?.length} />
			<ActionsCell
				width={"20%"}
				editDialogProps={editDialogProps(handleCreateUpdate, row)}
				deleteDialogProps={deleteDialogProps(row)}
				handleDelete={() => handleDelete(row)}
				disableDelete={!row?.deletable}
			/>
		</CustomTableRow>
	);
}
