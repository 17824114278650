import {
	DeleteDialogProps,
	EditDialogProps,
	CreateDialogProps,
} from "@recodin/fe-components";
import DepartmentEditForm from "./DepartmentEditForm";
import Department from "_model/department/Department";

export const editDialogProps = (
	handleCreateUpdate: (department: Department) => void,
	row?: Department
): EditDialogProps => {
	return {
		headerText: row ? `"${row.name}" redagavimas` : "Naujo skyriaus sukūrimas",
		contentTextFor: "skyrių",
		EditForm: (
			<DepartmentEditForm department={row} handleEdit={handleCreateUpdate} />
		),
	};
};

export const createProps = (
	handleCreateUpdate: (emailTemplate: Department) => void,
	row?: Department
): CreateDialogProps => ({
	...editDialogProps(handleCreateUpdate, row),
	createButtonLabel: "Naujas skyrius",
});

export const deleteDialogProps = (row: Department): DeleteDialogProps => ({
	labels: {
		title: "Skyriaus ištrynimas",
		question: `Ar tikrai norite ištrinti skyrių "${row?.name}"?`,
		dialogBoldedNameToDelete: `Skyrius "${row?.name}"`,
		dialogText: " bus visam laikui ištrintas. Jį atkurti galimybių nebus!",
	},
});
