import { useEffect, useState } from "react";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";
import { ReceptionPointAPIAdmin } from "_api/ReceptionPointAPI";
import { CustomTable } from "@recodin/fe-components";
import {
	ReceptionPointTableHeadRow,
	ReceptionPointTableRow,
} from "./ReceptionPointTableRow";
import { createProps } from "./ReceptionPointDialogProps";

export default function ReceptionPointPage() {
	const [receptionPoints, setReceptionPoints] = useState<ReceptionPoint[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function init() {
		setLoading(true);
		setReceptionPoints(await ReceptionPointAPIAdmin.getAll());
		setLoading(false);
	}
	async function handleCreateUpdate(data: ReceptionPoint) {
		if (data?.id) {
			await ReceptionPointAPIAdmin.update(data);
		} else {
			await ReceptionPointAPIAdmin.create(data);
		}
		await init();
	}
	async function handleDelete(data: ReceptionPoint) {
		if (data?.id) {
			await ReceptionPointAPIAdmin.delete(data?.id);
			await init();
		}
	}

	return (
		<div>
			<CustomTable
				data={receptionPoints}
				isLoading={loading}
				headerRow={ReceptionPointTableHeadRow}
				contentRow={(props: { row: ReceptionPoint }) => (
					<ReceptionPointTableRow
						row={props.row}
						handleDelete={handleDelete}
						handleCreateUpdate={handleCreateUpdate}
					/>
				)}
				createProps={createProps(handleCreateUpdate)}
				disableSearching
				disableFiltering
			/>
		</div>
	);
}
