import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MeResponse from "_model/account/MeResponse";

export interface UserState {
	info?: MeResponse;
	isLoggedIn?: boolean;
	loading?: boolean;
}

const initialState: UserState = {
	info: undefined,
};

const userSlice = createSlice({
	name: "userInfo",
	initialState: initialState,
	reducers: {
		setUserInfo: (state: UserState, action: PayloadAction<UserState>) => {
			const { info } = action.payload;

			state.info = info;
		},
	},
});

export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;
