import "./Account.css";
import { useEffect, useState } from "react";
import AccountResponse from "_model/account/AccountResponse";
import { AccountAPIAdmin } from "_api/AccountAPI";
import { useNavigate, useParams } from "react-router-dom";
import { customParseInt, hasRole } from "_util/UtilFunctions";
import Topic from "_model/topic/Topic";
import Department from "_model/department/Department";
import { TopicAPIAdmin } from "_api/TopicAPI";
import { DepartmentAPIAdmin } from "_api/DepartmentAPI";
import CustomButton from "_component/buttons/CustomButton";
import GeneralInfoSection from "./sections/GeneralInfoSection";
import WorkTimeInfoSection from "./sections/WorkTimeInfoSection";
import ReceptionPoint from "_model/receptionpoint/ReceptionPoint";
import { ReceptionPointAPIAdmin } from "_api/ReceptionPointAPI";
import { useAppSelector } from "_redux/hooks";
import { RoleNames } from "_model/account/RoleResponse";
import { commonConstants } from "_constant/commonConstants";
import CustomSpinner from "_component/display/CustomSpinner";
import CustomBanner from "_component/alert/CustomBanner";

export default function AccountPage() {
	const { maybeId } = useParams();

	const navigate = useNavigate();
	const [account, setAccount] = useState<AccountResponse>();
	const [topics, setTopics] = useState<Topic[]>([]);
	const [receptionPoints, setReceptionPoints] = useState<ReceptionPoint[]>([]);
	const [departments, setDepartments] = useState<Department[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const me = useAppSelector((state) => state.me);
	const id =
		maybeId === commonConstants.myProfilePathNameEnd
			? me?.info?.id
			: customParseInt(maybeId);

	useEffect(() => {
		handleInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleInit() {
		setLoading(true);
		if (maybeId !== "naujas" && id) {
			setAccount(await AccountAPIAdmin.getById(id));
		}
		setTopics(await TopicAPIAdmin.getAll());
		setDepartments(await DepartmentAPIAdmin.getAll());
		setReceptionPoints(await ReceptionPointAPIAdmin.getAll());
		setLoading(false);
	}

	async function refresh() {
		setLoading(true);
		if (
			maybeId === commonConstants.myProfilePathNameEnd &&
			hasRole(RoleNames.ADMIN, me?.info?.roles)
		) {
			// If modifying self as admin, then reload, as there could be role change
			navigate(0);
		} else if (id) {
			setAccount(await AccountAPIAdmin.getById(id));
		}
		setLoading(false);
	}

	function handleReturn() {
		if (
			hasRole(RoleNames.ADMIN, me.info?.roles) ||
			hasRole(RoleNames.MANAGER, me.info?.roles)
		)
			navigate("/paskyros");
		else navigate("/registracijos");
	}

	if (loading) {
		return <CustomSpinner />;
	}

	return (
		<div className={"accountPage-mainContainer"}>
			<CustomButton
				fullWidth
				variant={"outlined"}
				style={{ maxWidth: "300px", marginLeft: "auto" }}
				onClick={handleReturn}
			>
				Grįžti
			</CustomButton>
			<div className={"accountPage-centeredContainer"}>
				<div className={"accountPage-sectionsContainer"}>
					{hasRole(RoleNames.SPECIALIST, account?.roles) &&
						account?.workDepartments?.length === 0 && (
							<div style={{ width: "100%" }}>
								<CustomBanner
									severity={"error"}
									alertMessage={
										"Paskyrai privalo būti priskirta bent vienas skyrius"
									}
									hasCloseButton={false}
								/>
							</div>
						)}
					<GeneralInfoSection
						maybeId={maybeId}
						account={account}
						departments={departments}
						topics={topics}
						receptionPoints={receptionPoints}
						refresh={maybeId === "naujas" ? handleReturn : refresh}
					/>
					<WorkTimeInfoSection account={account} />
				</div>
			</div>
		</div>
	);
}
