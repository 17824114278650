import AppTheme from "AppTheme";
import { CircularProgress, Typography } from "@mui/material";
import React from "react";
import "./Display.css";

interface Props {
	size?: "large" | "medium" | "small";
	label?: string;
	withoutOuterDiv?: boolean;
}

export default function CustomSpinner(props: Props) {
	const {
		size = "medium",
		label = "Gauname Jūsų duomenis... Prašome palaukti...",
		withoutOuterDiv = false,
	} = props;

	const Spinner = (
		<CircularProgress
			size={getSpinnerSize(size)}
			style={{
				color: AppTheme.palette.primary.main,
			}}
		/>
	);

	return withoutOuterDiv ? (
		Spinner
	) : (
		<div
			className={"display-loading-icon-style"}
			style={{ paddingTop: `${getSpinnerSize(size)}px` }}
		>
			{label && (
				<Typography variant={"h4"} fontWeight={600}>
					{label}
				</Typography>
			)}
			{Spinner}
		</div>
	);
}

function getSpinnerSize(size: "large" | "medium" | "small"): number {
	switch (size) {
		case "large":
			return 75;
		default:
		case "medium":
			return 50;
		case "small":
			return 25;
	}
}
