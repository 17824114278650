interface SettingResponse {
	id: number;
	name: SettingName;
	value: boolean;
}

enum SettingName {
	EMAIL_VALIDATION = "EMAIL_VALIDATION",
}

function translateSetting(name: string): string {
	switch (name) {
		case SettingName.EMAIL_VALIDATION:
			return "Ar būtina patvirtinti paštą registracijos kūrime?";
		default:
			return "N/A";
	}
}

export default SettingResponse;
export { SettingName, translateSetting };
