import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
	ActionsCell,
} from "@recodin/fe-components";
import { deleteDialogProps, editDialogProps } from "./DepartmentDialogProps";
import Department from "_model/department/Department";

export function DepartmentTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: "Pavadinimas",
					width: "90%",
					align: "left",
				},
				{
					label: "Veiksmai",
					width: "10%",
				},
			]}
		/>
	);
}

interface DepartmentTableRowProps {
	row: Department;
	handleDelete: (row: Department) => void;
	handleCreateUpdate: (row: Department) => void;
}
export function DepartmentTableRow(props: DepartmentTableRowProps) {
	const { row, handleDelete, handleCreateUpdate } = props;
	return (
		<CustomTableRow>
			<TextCell
				width={"90%"}
				align={"left"}
				label={`${row?.name}`}
				subLabel={`Vadovų: ${row?.managers?.length}, darbuotojų: ${row.workerCount}`}
			/>
			<ActionsCell
				width={"10%"}
				editDialogProps={editDialogProps(handleCreateUpdate, row)}
				deleteDialogProps={deleteDialogProps(row)}
				handleDelete={() => handleDelete(row)}
				disableDelete={!row?.deletable}
			/>
		</CustomTableRow>
	);
}
