import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { RegistrationAPIPublic } from "_api/RegistrationAPI";
import reduxRegistrationApi from "_redux/registration/ReduxRegistrationApi";
import Registration from "./Registration";
import CustomSpinner from "_component/display/CustomSpinner";
import { useAppSelector } from "_redux/hooks";

export default function RegistrationSuccessful() {
	const { registrationUUID } = useParams();
	const registration = useAppSelector((state) => state.registration);

	useEffect(() => {
		getAndSetRegistration();
		// eslint-disable-next-line
	}, []);

	async function getAndSetRegistration() {
		if (registrationUUID) {
			const response = await RegistrationAPIPublic.getByUUID(registrationUUID);
			reduxRegistrationApi.setRegistration(response);
		}
	}

	if (!registration?.info?.uuid) {
		return <CustomSpinner label={"Prašome palaukti, puslapis kraunasi..."} />;
	}
	return <Registration uuid={registrationUUID} />;
}
