import TopicEditForm from "./TopicEditForm";
import Topic from "_model/topic/Topic";
import {
	DeleteDialogProps,
	EditDialogProps,
	CreateDialogProps,
} from "@recodin/fe-components";

export const editDialogProps = (
	handleCreateUpdate: (value: Topic) => void,
	row?: Topic
): EditDialogProps => {
	return {
		headerText: row
			? `Temos "${row.name}" redagavimas`
			: "Naujos temos sukūrimas",
		contentTextFor: "temą",
		EditForm: <TopicEditForm topic={row} handleEdit={handleCreateUpdate} />,
	};
};

export const createProps = (
	handleCreateUpdate: (value: Topic) => void,
	row?: Topic
): CreateDialogProps => ({
	...editDialogProps(handleCreateUpdate, row),
	createButtonLabel: "Nauja tema",
});

export const deleteDialogProps = (row: Topic): DeleteDialogProps => ({
	labels: {
		title: "Temos ištrynimas",
		question: `Ar tikrai norite ištrinti temą "${row?.name}"?`,
		dialogBoldedNameToDelete: `Tema "${row?.name}"`,
		dialogText: " bus visam laikui ištrinta. Jos atkurti galimybių nebus!",
	},
});
