export enum RegistrationPhase {
	TOPIC_CHOICE,
	TIME_CHOICE,
	CONTACT_INFORMATION_INPUT,
	FINISHED,
}

export function translateRegistrationPhase(phase: RegistrationPhase): string {
	switch (phase) {
		case RegistrationPhase.TOPIC_CHOICE:
			return "tema";
		case RegistrationPhase.TIME_CHOICE:
			return "laikas";
		case RegistrationPhase.CONTACT_INFORMATION_INPUT:
			return "duomenys";
		case RegistrationPhase.FINISHED:
			return "registracija";
		default:
			return "";
	}
}

export function getPhaseFromTranslation(
	translation?: string
): RegistrationPhase | undefined {
	const allPhases = Object.values(RegistrationPhase);
	for (let phase of allPhases) {
		if (
			typeof phase !== "string" &&
			translateRegistrationPhase(phase) === translation
		) {
			return phase;
		}
	}
}
