import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ErrorSlice from "./error/ErrorSlice";
import CommonSlice from "./common/CommonSlice";
import RegistrationSlice from "./registration/RegistrationSlice";
import UserSlice from "./user/UserSlice";

export const rootReducer = combineReducers({
	error: ErrorSlice,
	common: CommonSlice,
	registration: RegistrationSlice,
	me: UserSlice,
});

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof rootReducer>;
