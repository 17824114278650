import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";

const publicUrl = baseEndpoints.validationPublic;
const ValidationAPIPublic = {
	isEmailValid: (email: string, code: string): Promise<boolean> =>
		axios.get(`${publicUrl}/email?email=${email}&code=${code}`),
	sendValidationCodeToEmail: (email: string): Promise<boolean> =>
		axios.post(`${publicUrl}/email?email=${email}`),
};

export { ValidationAPIPublic };
