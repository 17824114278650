import { SelectItem } from "_component/form/templates/FilterForm";

interface RoleResponse {
	id: number;
	name: string;
}

enum RoleNames {
	ADMIN = "ADMIN",
	MANAGER = "MANAGER",
	SPECIALIST = "SPECIALIST",
	OBSERVER = "OBSERVER",
}

function translateRoleByName(roleName: string): string {
	switch (roleName) {
		case RoleNames.ADMIN:
			return "Administratorius";
		case RoleNames.MANAGER:
			return "Skyriaus vadovas";
		case RoleNames.SPECIALIST:
			return "Specialistas";
		case RoleNames.OBSERVER:
			return "Stebėtojas";
		default:
			return roleName;
	}
}

function translateRoleById(roleId: string, allRoles: RoleResponse[]): string {
	const foundRole = allRoles.find((role) => `${role.id}` === roleId)?.name;
	return translateRoleByName(foundRole ?? "");
}

function rolesToSelectItems(roles?: RoleResponse[]): SelectItem[] {
	return (
		roles?.map((role) => ({
			value: `${role?.id}`,
			label: translateRoleByName(role.name),
		})) ?? []
	);
}

export default RoleResponse;
export {
	RoleNames,
	translateRoleByName,
	translateRoleById,
	rolesToSelectItems,
};
