export const baseEndpoints = {
	accountAdmin: "/api/admin/v1/account",
	accountPublic: "/api/public/v1/account",
	departmentAdmin: "/api/admin/v1/department",
	emailTemplateAdmin: "/api/admin/v1/email-template",
	emailAdmin: "/api/admin/v1/email",
	topicAdmin: "/api/admin/v1/topic",
	topicPublic: "/api/public/v1/topic",
	receptionPointAdmin: "/api/admin/v1/reception-point",
	registrationAdmin: "/api/admin/v1/registration",
	registrationPublic: "/api/public/v1/registration",
	validationPublic: "/api/public/v1/validation",
	settingAdmin: "/api/admin/v1/settings",
	settingPublic: "/api/public/v1/settings",
	reportAdmin: "/api/admin/v1/report",
};
