import styled from "@mui/styled-engine-sc";
import moment from "moment";
import { useState } from "react";

interface Props {
	comingDate: moment.Moment;
	disabled?: boolean;
	selected?: boolean;
	onClick?: Function;
}

export default function DaySelect({
	comingDate,
	selected = false,
	disabled = false,
	onClick,
}: Props) {
	const [date] = useState<moment.Moment>(comingDate);

	return (
		<DaySelectContainer
			disabled={disabled}
			selected={selected}
			onClick={!disabled ? onClick : undefined}
		>
			<WeekDay>{date?.format("MMM")}</WeekDay>
			<Day>{date?.format("D")}</Day>
			{!disabled && <Bubble></Bubble>}
		</DaySelectContainer>
	);
}

const WeekDay = styled("div")({});

const DaySelectContainer = styled("div")<any>((props: any) => ({
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	background: props.disabled
		? "#F2F2F2"
		: props.selected
		? "#4A5E96"
		: "#F7F8FC",
	color: props.selected ? "white" : "",
	border: "1px solid #EEEEEE",
	borderRadius: "5px",
	padding: "0.5rem 1.25rem",
	opacity: props.disabled ? "0.2" : 1,
	cursor: props.disabled ? "not-allowed" : "pointer",
	flex: "0 1 30px",
	[WeekDay]: {
		color: props.selected ? "white" : "",
	},
	[Day]: {
		color: props.selected ? "white" : "",
	},
	[Bubble]: {
		background: props.selected ? "white" : "",
	},
}));

const Day = styled("div")({
	fontWeight: 700,
	fontSize: "16px",
	color: "#000000",
	marginBottom: "0.75rem",
});

const Bubble = styled("div")({
	width: "8px",
	height: "8px",
	background: "#183568",
	borderRadius: "4px",
});
