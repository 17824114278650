import {
	CustomTableHeadRow,
	CustomTableRow,
	TextCell,
} from "@recodin/fe-components";
import RegistrationReport from "_model/report/RegistrationReport";
import RegistrationCount from "_model/report/RegistrationCount";
import { translateRegistrationStatus } from "_model/registration/RegistrationStatus";

export function RegistrationReportTableHeadRow() {
	return (
		<CustomTableHeadRow
			cells={[
				{
					label: "Tema",
					width: "70%",
					align: "left",
				},
				{
					label: "Registracijų sk.",
					width: "30%",
					align: "left",
				},
			]}
		/>
	);
}

interface RegistrationReportTableRowProps {
	row: RegistrationReport;
}
export function RegistrationsTableRow(props: RegistrationReportTableRowProps) {
	const { row } = props;
	const total = row.counts
		.map((count: RegistrationCount) => count.count)
		.reduce((sum: number, current: number) => sum + current);
	const counts = row.counts.map(
		(count: RegistrationCount) =>
			`${translateRegistrationStatus(count.status).text}: ${count.count}`
	);
	return (
		<CustomTableRow>
			<TextCell width={"70%"} align={"left"} label={`${row.topic.name}`} />
			<TextCell
				width={"30%"}
				align={"left"}
				label={`Iš viso ${total}`}
				subLabel={counts}
			/>
		</CustomTableRow>
	);
}
