import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import { FeComponentsTheme } from "@recodin/fe-components";
import { createTheme } from "@mui/material";
import React from "react";

const AppTheme = createTheme({
	components: {
		MuiCollapse: {
			styleOverrides: {
				root: {
					width: "100%",
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					color: "#F7F8FC !important",
					borderRadius: "0px",
				},
				icon: {
					color: "#F7F8FC !important",
				},
				message: {
					width: "100%",
				},
			},
		},
	},

	typography: {
		...FeComponentsTheme.typography,
		fontFamily: "Inter",
		fontWeightRegular: "400",
		fontWeightMedium: "500",
		fontWeightBold: "600",
		h1: {
			fontSize: "36px",
			lineHeight: "50px",
			fontWeight: "700",
			color: "#183568",
		},
		h2: {
			fontSize: "24px",
			lineHeight: "39px",
			fontWeight: "700",
			color: "#171440",
		},
		h3: {
			fontSize: "20px",
			lineHeight: "25px",
			fontWeight: "600",
			color: "#171440",
		},
		h4: {
			fontSize: "18px",
			lineHeight: "25px",
			fontWeight: "500",
			color: "#171440",
		},
		h5: {
			fontSize: "16px",
			lineHeight: "22px",
			fontWeight: "700",
			color: "#171440",
		},
		h6: {
			fontSize: "16px",
			lineHeight: "22px",
			fontWeight: "400",
			color: "#171440",
		},
		subtitle: {
			fontSize: 14,
			fontWeight: 400,
			color: "rgba(23, 20, 64, 0.6)",
		},
		subtitle2: {
			fontSize: 12,
			fontWeight: 400,
			color: "rgba(23, 20, 64, 0.6)",
		},
		button: {
			fontSize: "15px",
			lineHeight: "21px",
			fontWeight: "400",
			color: "#171440",
			textTransform: "none",
		},
	},
	palette: {
		...FeComponentsTheme.palette,
		primary: {
			main: "rgb(24, 53, 104)",
			light: "#F7F8FC",
		},
		secondary: {
			main: "#FF3B30",
		},
		background: {
			default: "#FFFFFF",
		},
		error: {
			main: "#E53935",
			light: "#9d9291",
		},
		warning: {
			main: "#FFC107",
		},
	},
});

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		hyperlink: true;
		overline: false;
		body: true;
		body1: false;
		subtitle: true;
		subtitle1: false;
	}
}

declare module "@mui/material/styles" {
	interface TypographyVariants {
		hyperlink: React.CSSProperties;
		body: React.CSSProperties;
		subtitle: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		hyperlink?: React.CSSProperties;
		body?: React.CSSProperties;
		subtitle?: React.CSSProperties;
	}
}

export default AppTheme;
