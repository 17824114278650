import { useEffect, useState } from "react";
import { CustomTable } from "@recodin/fe-components";
import { AccountTableHeadRow, AccountTableRow } from "./table/AccountTableRow";
import { SearchRequest } from "_component/form/templates/SearchForm";
import {
	FilterRequest,
	SelectItem,
} from "_component/form/templates/FilterForm";
import AccountResponse from "_model/account/AccountResponse";
import { AccountAPIAdmin } from "_api/AccountAPI";
import {
	translateRoleById,
	rolesToSelectItems,
} from "_model/account/RoleResponse";
import Topic from "_model/topic/Topic";
import Department from "_model/department/Department";
import { TopicAPIAdmin } from "_api/TopicAPI";
import { DepartmentAPIAdmin } from "_api/DepartmentAPI";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "_redux/hooks";
import { translateSelectItem } from "_util/UtilFunctions";
import useTheme from "@mui/material/styles/useTheme";

export default function AccountListPage() {
	const navigate = useNavigate();
	const theme = useTheme();

	const [accounts, setAccounts] = useState<AccountResponse[]>([]);
	const [topics, setTopics] = useState<Topic[]>([]);
	const [departments, setDepartments] = useState<Department[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const roles = useAppSelector((s) => s.common?.allAccountRoles) ?? [];

	const topicSelectItems = topics.map(
		(item): SelectItem => ({ value: `${item.id}`, label: item.name })
	);
	const departmentSelectItems = departments.map(
		(item): SelectItem => ({ value: `${item.id}`, label: item.name })
	);

	useEffect(() => {
		handleInit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function handleInit() {
		setLoading(true);
		setAccounts(await AccountAPIAdmin.getAllFiltered());
		setTopics(await TopicAPIAdmin.getAll());
		setDepartments(await DepartmentAPIAdmin.getAll());
		setLoading(false);
	}

	async function handleDataFetch(
		search?: SearchRequest,
		filter?: FilterRequest
	) {
		setLoading(true);
		setAccounts(await AccountAPIAdmin.getAllFiltered(filter));
		setLoading(false);
	}

	function handleCreateButtonClick() {
		navigate("/paskyros/naujas");
	}

	return (
		<div>
			<CustomTable
				data={accounts}
				isLoading={loading}
				headerRow={AccountTableHeadRow}
				contentRow={({ row }: { row: AccountResponse }) => (
					<AccountTableRow row={row} navigate={navigate} theme={theme} />
				)}
				handleSearchAndFilter={handleDataFetch}
				filterFormProps={{
					selectItems: {
						roles: rolesToSelectItems(roles),
						topics: topicSelectItems,
						departments: departmentSelectItems,
					},
					selectItemsTranslation: {
						role: (id: string) => translateRoleById(id, roles),
						topic: (val: string) =>
							translateSelectItem(topicSelectItems, val, true),
						department: (val: string) =>
							translateSelectItem(departmentSelectItems, val, true),
					},
				}}
				createProps={{
					createButtonLabel: "Naujas vartotojas",
					onClick: handleCreateButtonClick,
				}}
			/>
		</div>
	);
}
