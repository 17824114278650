import { styled } from "@mui/material";
import { WorkTime } from "_model/account/WorkTime";
import { getTime } from "_util/DateUtil";

interface Props {
	address: string;
	times: WorkTime[];
	onClick: Function;
}

export default function TimeSelect({ address, times, onClick }: Props) {
	return (
		<TimeSelectContainer>
			<Address>{address}</Address>
			<FreeSlotContainer>
				{times.map((time: WorkTime, i) => (
					<FreeSlot
						key={i}
						selected={time.selected}
						onClick={() => onClick(time.id)}
					>
						{getTime(time.start, true)}
					</FreeSlot>
				))}
			</FreeSlotContainer>
		</TimeSelectContainer>
	);
}

const TimeSelectContainer = styled("div")<any>((props: any) => ({
	display: "flex",
	flexDirection: "column",
	padding: "1.5rem",
	border: "1px solid #EEEEEE",
	borderRadius: "5px",
}));

const FreeSlotContainer = styled("div")<any>((props: any) => ({
	display: "flex",
	flexWrap: "wrap",
	gap: "1rem",
}));

const FreeSlot = styled("div")<any>((props: any) => ({
	background: props.selected ? "#4A5E96" : "#F7F8FC",
	border: "1px solid #EEEEEE",
	borderRadius: "5px",
	padding: "0.5rem 1rem",
	fontWeight: "400",
	fontSize: 16,
	color: props.selected ? "white" : "#171440",
	cursor: "pointer",
	width: "45px",
}));

const Address = styled("div")({
	fontSize: "18px",
	fontWeight: 500,
	marginBottom: "1rem",
});
