import { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
	addError,
	clearAllErrors,
	ErrorValue,
	hideError,
	removeError,
} from "./ErrorSlice";

export const addAlert =
	(newAlert: ErrorValue): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		return dispatch(addError(newAlert));
	};

export const removeAlert =
	(
		alertToRemove: ErrorValue
	): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(removeError(alertToRemove));
	};

export const hideAlert =
	(alertToHide: ErrorValue): ThunkAction<void, RootState, unknown, AnyAction> =>
	async (dispatch) => {
		dispatch(hideError(alertToHide));
	};

export const clearAllAlerts =
	(): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
		dispatch(clearAllErrors());
	};
