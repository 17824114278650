import { DeleteDialogProps } from "@recodin/fe-components";
import RegistrationResponse from "_model/registration/RegistrationResponse";
import { getRepresentedTime } from "../registration/phase/ContactInformationForm";
import moment from "moment";

export const deleteDialogProps = (
	row: RegistrationResponse
): DeleteDialogProps => ({
	labels: {
		title: "Registracijos ištrynimas",
		question: `Ar tikrai norite ištrinti registraciją ${getRepresentedTime(
			moment(row?.workTime.start),
			moment(row?.workTime.end)
		)}?`,
		dialogBoldedNameToDelete: `${row?.customerName} ${row?.customerLastName} (${
			row?.customerEmail
		}) registracija, ${getRepresentedTime(
			moment(row?.workTime.start),
			moment(row?.workTime.end)
		)},`,
		dialogText: " bus visam laikui ištrinta. Jos atkurti galimybių nebus!",
	},
});
