import { defaultErrorMessage } from "_constant/errorBaseConstants";
import { ErrorVariable, IError } from "_services/axiosInterceptorsUtil";
import { ErrorValue } from "./ErrorSlice";
import ErrorTranslations from "./ErrorTranslations";

/**
 * This function creates a new alert object with the given parameters.
 * @param {boolean} isOpen - boolean - This is the state of the alert. If it's true, the alert will be
 * visible. If it's false, the alert will be hidden.
 * @param {string} message - The message you want to display in the alert
 * @param {"success" | "info" | "warning" | "error"} alertSeverity - "success" | "info" | "warning" |
 * "error"
 * @param {"banner" | "toast-bottom-left" | "toast-bottom-right"} [alertType=banner] - "banner" |
 * "toast-bottom-left" | "toast-bottom-right" = "banner"
 * @param {string} [actionTitle] - The title of the action button.
 * @param [alertAction] - React.ReactNode - This is the action that will be displayed in the alert.
 * @returns An object with the following properties:
 * 	isOpen: boolean,
 * 	message: string,
 * 	alertSeverity: "success" | "info" | "warning" | "error",
 * 	alertType: "banner" | "toast-bottom-left" | "toast-bottom-right" = "banner",
 * 	actionTitle?: string
 */
export function createNewError(
	isOpen: boolean,
	message: string,
	alertSeverity: "success" | "info" | "warning" | "error",
	alertType: "banner" | "toast" = "banner",
	actionTitle?: string,
	alertAction?: () => void
): ErrorValue {
	return {
		isOpen: isOpen,
		message: message,
		alertSeverity: alertSeverity,
		alertType: alertType,
		actionTitle: actionTitle,
		alertAction: alertAction,
	};
}

export function mapErrorToAlert(
	error?: IError,
	actionTitle?: string,
	alertAction?: () => void
) {
	if (error) {
		return createNewError(
			true,
			getErrorTranslation(error),
			"error",
			"banner",
			actionTitle,
			alertAction
		);
	} else {
		return createNewError(
			true,
			defaultErrorMessage,
			"error",
			"banner",
			actionTitle,
			alertAction
		);
	}
}

export function replaceErrorMessageVariables(
	errorMessage: string,
	errorVariables: ErrorVariable[]
) {
	errorVariables.forEach((errorVariable) => {
		if (!errorMessage) return;
		errorMessage = errorMessage.replace(
			`{{${errorVariable.key}}}`,
			errorVariable.value
		);
	});
	return errorMessage;
}

export function getErrorTranslation(error?: IError) {
	if (error) {
		let wannabeTranslation = ErrorTranslations[error.baseErrorMessage];
		if (!wannabeTranslation) return defaultErrorMessage;

		let errorMessage = replaceErrorMessageVariables(
			wannabeTranslation,
			error.errorVariables
		);
		return errorMessage || error?.baseErrorMessage;
	}
	return defaultErrorMessage;
}
