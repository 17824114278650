import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";
import AccountResponse from "_model/account/AccountResponse";
import AccountEditRequest from "_model/account/AccountEditRequest";
import { FilterRequest } from "_component/form/templates/FilterForm";
import WorkTimeCreateRequest from "_model/account/WorkTimeCreateRequest";
import WorkDay from "_model/account/WorkDay";
import MeResponse from "_model/account/MeResponse";
import RoleResponse from "_model/account/RoleResponse";

const adminUrl = baseEndpoints.accountAdmin;
const AccountAPIAdmin = {
	getAllWeekdays: (): Promise<string[]> => axios.get(`${adminUrl}/weekdays`),
	getById: (id: number): Promise<AccountResponse> =>
		axios.get(`${adminUrl}/${id}`),
	getAllFiltered: (filter?: FilterRequest): Promise<AccountResponse[]> =>
		axios.get(`${adminUrl}/filtered`, {
			params: {
				roleId: filter?.roleId ?? null,
				topicId: filter?.topicId ?? null,
				departmentId: filter?.departmentId ?? null,
			},
		}),
	create: (data: AccountEditRequest): Promise<AccountResponse> =>
		axios.post(adminUrl, data),
	update: (data: AccountEditRequest): Promise<AccountResponse> =>
		axios.put(`${adminUrl}/${data.id}`, data),
	delete: (id: number) => axios.delete(`${adminUrl}/${id}`),

	getAllFilteredWorkTimes: (id?: number): Promise<WorkDay[]> =>
		axios.get(`${adminUrl}/${id}/work-time`, {
			params: {
				accountId: id ?? null,
			},
		}),
	createWorkTimes: (
		data: WorkTimeCreateRequest,
		id?: number
	): Promise<AccountResponse> =>
		axios.post(`${adminUrl}/${id}/work-time`, data),
	deleteWorkTime: (accountId?: number, id?: number) =>
		axios.delete(`${adminUrl}/${accountId}/work-time/${id}`),
	getMe: (): Promise<MeResponse> => axios.get(`${adminUrl}/me`),
	getAllRoles: (): Promise<RoleResponse[]> => axios.get(`${adminUrl}/roles`),
};

export { AccountAPIAdmin };
