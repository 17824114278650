import "./CustomHeader.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { Menu } from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../buttons/CustomButton";
import HeaderMenuItem from "./HeaderMenuItem";
import AuthAPI from "_api/AuthAPI";
import { useNavigate } from "react-router-dom";

interface Props {
	children: any;
}

export default function HeaderMenu(props: Props) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const navigate = useNavigate();

	const handleOpen = (event: any) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);
	const handleLogoff = async () => {
		handleClose();
		await AuthAPI.logoff();
		navigate("/tema");
	};
	return (
		<>
			<CustomButton variant={"text"} onClick={handleOpen} disableRipple>
				{props.children}
				<ArrowDropDownIcon
					style={{
						color: "#323232",
					}}
				/>
			</CustomButton>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<div className={"account-menu-main-container"}>
					<HeaderMenuItem
						LeftIcon={
							<LogoutIcon
								style={{
									height: "20px",
									width: "20px",
								}}
							/>
						}
						text={"Atsijungti"}
						style={{ color: "red" }}
						onClick={handleLogoff}
					/>
				</div>
			</Menu>
		</>
	);
}
