import { useFormikContext } from "formik";
import CustomButton from "../../buttons/CustomButton";

interface Props {
	onCancel?: () => void;
	disabled?: boolean;
	cancelLabel?: string;
	submitLabel?: string;
	debug?: boolean;
}

export default function CustomFormActions(props: Props) {
	const formik = useFormikContext();

	const {
		onCancel = formik.handleReset,
		disabled = false,
		cancelLabel = "Atšaukti",
		submitLabel = "Patvirtinti",
	} = props;

	if (props.debug) {
		console.log(formik.dirty, formik.isValid, formik.values, formik.errors);
	}
	return (
		<div className={"customForm-actionContainer"}>
			<CustomButton
				variant={"outlined"}
				style={{ flex: 1 }}
				onClick={onCancel}
				disabled={!formik.dirty}
			>
				{cancelLabel}
			</CustomButton>
			<CustomButton
				variant={"contained"}
				style={{ flex: 1 }}
				type={"submit"}
				onClick={formik.submitForm}
				disabled={!(formik.isValid && formik.dirty) || disabled}
			>
				{submitLabel}
			</CustomButton>
		</div>
	);
}
