import axios from "axios";
import { baseEndpoints } from "_constant/baseEndpoints";
import Department from "_model/department/Department";

const adminUrl = baseEndpoints.departmentAdmin;
const DepartmentAPIAdmin = {
	getAll: (): Promise<Department[]> => axios.get(adminUrl),
	create: (data: Department): Promise<Department> => axios.post(adminUrl, data),
	update: (data: Department): Promise<Department> =>
		axios.put(`${adminUrl}/${data.id}`, data),
	delete: (id: number) => axios.delete(`${adminUrl}/${id}`),
};

export { DepartmentAPIAdmin };
