import { FormContext, FormContextRefreshConduit } from "@recodin/fe-components";
import { Alert, Avatar, Typography, useTheme } from "@mui/material";
import CustomDatePicker from "_component/form/components/CustomDatePicker";
import CustomTimePicker from "_component/form/components/CustomTimePicker";
import CustomTextField from "_component/form/components/CustomTextField";
import { FieldArray, Formik, FormikProps } from "formik";
import * as yup from "yup";
import WorkTimeCreateRequest from "_model/account/WorkTimeCreateRequest";
import moment from "moment";
import { getDate, getTime } from "_util/DateUtil";
import { useContext } from "react";
import Weekday from "_model/account/Weekday";

interface Props {
	onSubmit: (values: WorkTimeCreateRequest) => void;
	weekdays: string[];
}

export default function SpecialistTimeForm({ onSubmit, weekdays }: Props) {
	const { formikFormRef } = useContext(FormContext);
	const theme = useTheme();

	function handleSubmit(formValues: any) {
		const values = formValues as SpecialistTimeFormValues;
		if (!(values.dateStart && values.dateEnd)) {
			console.log("Invalid time");
			return;
		}
		onSubmit({
			...values,
			startDay: getDate(values.dateStart),
			endDay: getDate(values.dateEnd),
			startWorkTime: getTime(values.workStart),
			endWorkTime: getTime(values.workEnd),
		});
	}

	return (
		<Formik
			initialValues={getInitialValues(weekdays)}
			validationSchema={getValidationSchema()}
			onSubmit={handleSubmit}
			innerRef={formikFormRef}
		>
			{(formik: FormikProps<any>) => (
				<>
					{/* Updates formContext, which is used in outside dialog */}
					<FormContextRefreshConduit />

					<Typography variant={"h3"}>Dienos *</Typography>
					<div className={"in-row"}>
						<CustomDatePicker
							label={"Nuo"}
							name={"dateStart"}
							maxDate={moment(formik.values.dateEnd).subtract(1, "day")}
							disablePast
						/>
						<CustomDatePicker
							label={"Iki (neįskaičiuota)"}
							name={"dateEnd"}
							minDate={moment(formik.values.dateStart).add(1, "day")}
							disablePast
						/>
					</div>
					<Typography variant={"h3"}>Darbo laikas *</Typography>

					<div className={"in-row"}>
						<CustomTimePicker
							label={"Nuo"}
							name={"workStart"}
							maxTime={getTodayWorkTime(
								moment(formik.values.workEnd).subtract(1, "minute")
							)}
						/>
						<CustomTimePicker
							label={"Iki"}
							name={"workEnd"}
							minTime={getTodayWorkTime(
								moment(formik.values.workStart).add(1, "minute")
							)}
						/>
					</div>
					<Typography variant={"h3"}>Nustatymai</Typography>
					<FieldArray
						name={`includedDays`}
						render={() => (
							<div className={"in-row"}>
								<Typography variant={"h6"}>Įtrauktos dienos: </Typography>
								{formik.values.includedDays.map((day: any, index: number) => (
									<Avatar
										key={index}
										onClick={() => {
											formik.setFieldValue(
												`includedDays.${index}.include`,
												!day?.include
											);
										}}
										sx={{
											bgcolor: day?.include
												? theme.palette.primary.main
												: theme.palette.primary.light,
											color: day?.include
												? theme.palette.primary.light
												: theme.palette.primary.main,
											cursor: "pointer",
										}}
									>
										{translateWeekdayName(day?.name)}
									</Avatar>
								))}
							</div>
						)}
					/>
					<CustomTextField
						label={"Vienos konsultacijos laikas (min)"}
						name={"duration"}
					/>
					<CustomTextField
						label={"Pertrauka tarp konsultacijų (min)"}
						name={"breakTime"}
					/>

					{formik.isValid && (
						<Alert variant={"outlined"}>
							<Typography variant={"h3"}>
								{`Bus sukurti konsultacijos laikai kiekvieną dieną nuo ${getDate(
									formik.values.dateStart
								)} iki ${getDate(formik.values.dateEnd)}` +
									`, nuo ${getTime(
										formik.values.workStart,
										true
									)} iki ${getTime(formik.values.workEnd, true)}.`}
							</Typography>
						</Alert>
					)}
				</>
			)}
		</Formik>
	);
}

const getInitialValues = (weekdays: string[]) => {
	return {
		dateStart: moment(),
		dateEnd: null,
		workStart: moment(getDate(moment()) + " 08:00"),
		workEnd: moment(getDate(moment()) + " 18:00"),
		duration: 30,
		breakTime: 0,
		includedDays: weekdays.map(
			(day: string): Weekday => ({
				name: day,
				include: day !== "SUNDAY" && day !== "SATURDAY",
			})
		),
	};
};
export type SpecialistTimeFormValues = ReturnType<typeof getInitialValues>;

const minDate = moment(getDate(moment()) + " 00:00"); // important
const getValidationSchema = () => {
	return yup.object({
		dateStart: yup
			.date()
			.typeError("Įveskite pradinę datą formatu: YYYY-MM-DD")
			.min(minDate, "Pradinė data negali būti ankstesnė negu šiandien.")
			.required("Būtina įvesti pradinę datą."),
		dateEnd: yup
			.date()
			.typeError("Įveskite galutinę datą formatu: YYYY-MM-DD")
			.notOneOf(
				[yup.ref("dateStart")],
				"Galutinė data negali sutapti su pradine data."
			)
			.min(
				yup.ref("dateStart"),
				"Galutinė data negali būti ankstesnė negu šiandien."
			)
			.required("Būtina įvesti galutinę datą."),
		workStart: yup
			.date()
			.typeError("Įveskite darbo pradžią formatu: HH:MM")
			.required("Būtina įvesti darbo pradžią."),
		workEnd: yup
			.date()
			.typeError("Įveskite darbo pabaigą formatu: HH:MM")
			.notOneOf(
				[yup.ref("workStart")],
				"Darbo pabaiga negali sutapti su darbo pradžia."
			)
			.min(
				yup.ref("workStart"),
				"Darbo pabaiga negali būti ankstesnė negu darbo pradžia."
			)
			.required("Būtina įvesti darbo pabaigą."),
		duration: yup
			.number()
			.typeError("Įrašykite sveiką skaičių.")
			.integer("Įrašykite sveiką skaičių.")
			.min(5, "Vienos konsultacijos trukmė turi būti nemažesnė negu 5 min.")
			.max(180, "Vienos konsultacijos trukmė turi būti nedidesnė negu 180 min.")
			.required("Vienos konsultacijos trukmę būtina įrašyti."),
		breakTime: yup
			.number()
			.typeError("Įrašykite sveiką skaičių.")
			.integer("Įrašykite sveiką skaičių.")
			.min(0, "Poilsio po konsultacijos trukmė negali būti neigiama.")
			.max(
				180,
				"Poilsio po konsultacijos trukmė turi būti nedidesnė negu 180 min."
			)
			.required("Poilsio po konsultacijos trukmę būtina įrašyti."),
		includedDays: yup
			.array(
				yup.object({
					name: yup.string(),
					include: yup.boolean(),
				})
			)
			.length(7, "Privalo būti septynių dienų informacija."),
	});
};

function translateWeekdayName(weekday?: string): string {
	switch (weekday) {
		case "MONDAY":
			return "P";
		case "TUESDAY":
			return "A";
		case "WEDNESDAY":
			return "T";
		case "THURSDAY":
			return "K";
		case "FRIDAY":
			return "P";
		case "SATURDAY":
			return "Š";
		case "SUNDAY":
			return "S";
		default:
			return "N/A";
	}
}

function getTodayWorkTime(time: moment.Moment): moment.Moment {
	return moment(getDate(moment()) + " " + getTime(time));
}
